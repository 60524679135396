/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CustomerModel } from "../../Utils/Models";
import { handleChangeAutocomplete } from "../../Utils/Utils";
import { Add } from "@mui/icons-material";
import CustomerMainPage from "../Customers/CustomerMainPage";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { CheckInOrderProps } from "./CheckInMainPage";

export type CheckInPagesProps = {
  checkInOrder: CheckInOrderProps;
  handleChangeCheckInOrder: (checkInOrder: CheckInOrderProps) => void;
};
export default function CheckInCustomer(props: CheckInPagesProps) {
  const { checkInOrder, handleChangeCheckInOrder } = props;
  const [customers, setCustomers] = useState([]);
  const [open, setOpen] = useState(false);

  /**
   *
   */
  useEffect(() => {
    getCustomers();
  }, []);

  /**
   *
   */
  function getCustomers() {
    getData(urlEnum.customers).then((res: any) => {
      if (res.data.status === 200) setCustomers(res.data.result);
    });
  }

  /**
   *
   * @param step
   */
  function addOrUpdateCustomer(customer: CustomerModel | null) {
    if (customer?._id) {
      updateData(`${urlEnum.customers}/${customer._id}`, customer)
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.customers, customer)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  return (
    <div style={{ margin: "10px 0px" }}>
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 400,
          marginBottom: 15,
          textAlign: "center",
        }}
      >
        {Vocabulary.chooseOrAddCustomer}
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Autocomplete
          size="small"
          id="customer"
          freeSolo={false}
          disablePortal
          getOptionLabel={(option: any) =>
            `${option.firstName} ${option.lastName}`
          }
          isOptionEqualToValue={(option: any, value: any) =>
            option._id === value._id
          }
          options={customers}
          value={checkInOrder.customer}
          onChange={(event: any, value: any) => {
            handleChangeCheckInOrder(
              handleChangeAutocomplete(event, value, checkInOrder, "customer")
            );
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              name="customer"
              label={Vocabulary.customer}
              variant="outlined"
            />
          )}
        />
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            setOpen(true);
          }}
          style={{ marginLeft: 15 }}
        >
          {Vocabulary.add}
        </Button>
      </div>
      {open ? (
        <CustomerMainPage
          editCustomer={null}
          open={open}
          addOrUpdateCustomer={(customer) => addOrUpdateCustomer(customer)}
          onClose={() => setOpen(false)}
          fromOrder={false}
        />
      ) : null}
    </div>
  );
}
