/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../../Styles/dashboard.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { urlEnum } from "../../Utils/UrlEnum";
import MainTable from "../../Components/GenericComponents/MainTable";
import { useContext, useEffect, useState } from "react";
import {
  CategoryModel,
  FiltersModel,
  ImportExcelDescription,
  InventoryModel,
  ProductModel,
} from "../../Utils/Models";
import { postData } from "../../Services/postData";
import ProductMainPage from "../../Components/Products/ProductMainPage";
import PublishIcon from "@mui/icons-material/Publish";
import { Button, Chip, useMediaQuery } from "@mui/material";
import { WebsocketContext } from "../../Contexts/WebsocketContext";
import { updateData } from "../../Services/updateData";
import { Delete } from "@mui/icons-material";
import theme from "../../Themes/Theme";
import GenericConfirmModal from "../../Components/GenericComponents/GenericConfirmModal";
import { HttpVerbs } from "../../Utils/Constants";
import { dayPilotFormat, euFormatForDateTime } from "../../Utils/Config";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Filters from "../../Components/Reports/Filters";
import ImportExcelButton from "../../Components/GenericComponents/ImportExcelButton";
import ImportExcelModal from "../../Components/GenericComponents/ImportExcelModal";

type OpenModal = {
  action: string | null;
  value: boolean;
};

export default function Products() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const websocketContext = useContext(WebsocketContext);
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [openModal, setOpenModal] = useState<OpenModal>({
    action: null,
    value: false,
  });
  const [editItem, setEditItem] = useState<ProductModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [filters, setFilters] = useState<FiltersModel>({
    startDate: moment().startOf("year"),
    endDate: moment().date(25),
    type: null,
    category: null,
    inventory: null,
  });

  /**
   *
   */
  const description: Array<ImportExcelDescription> = [
    {
      name: "Name",
      description: Vocabulary.itemName,
      example: "TerraE INR18650 35E3 3.6V 3350mAh 11.70Wh LiMnO2",
    },
    {
      name: "Description",
      description: Vocabulary.itemDescription,
      example: "High-capacity rechargeable lithium-ion battery.",
    },
    {
      name: "Code",
      description: Vocabulary.itemCode,
      example: "41030012",
    },
    {
      name: "Part number",
      description: Vocabulary.itemPartNumber,
      example: "TEST",
    },
    {
      name: "Manufacturer",
      description: Vocabulary.itemManufacturer,
      example: "Bmz",
    },
    {
      name: "Type string",
      description: Vocabulary.itemType,
      example: "Product",
    },
    {
      name: "Category string",
      description: Vocabulary.itemCategory,
      example: "Zellen Neu",
    },
    {
      name: "Stock price",
      description: Vocabulary.itemSellingPrice,
      example: "4.81",
    },
    {
      name: "VAT",
      description: Vocabulary.itemSellingVAT,
      example: "8.10",
    },
    {
      name: "Purchase stock price",
      description: Vocabulary.itemPurchaseStockPrice,
      example: "2.59",
    },
    {
      name: "Tags",
      description: Vocabulary.itemTags,
      example: "Battery, Lithium-ion, Rechargeable",
    },
  ];

  /**
   *
   */
  const productsHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>{value.length > 15 ? `${value.substring(0, 15)}...` : value}</>
          );
        },
      },
    },
    {
      label: Vocabulary.code,
      name: "code",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>{value.length > 15 ? `${value.substring(0, 15)}...` : value}</>
          );
        },
      },
    },
    {
      label: Vocabulary.partNumber,
      name: "partNumber",
      options: {
        sort: false,
        display: "false",
        customBodyRender: (value: any) => {
          return (
            <>{value.length > 15 ? `${value.substring(0, 15)}...` : value}</>
          );
        },
      },
    },
    {
      label: Vocabulary.typeString,
      name: "typeString",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.categoryString,
      name: "categoryString",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.manufacturer,
      name: "manufacturer",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      label: `${Vocabulary.inventories}`,
      name: "inventories",
      options: {
        sort: false,
        customBodyRender: (values: any) => {
          return (
            <>
              {values
                ? values.map((value: any) => {
                    if (value.inventory?.name)
                      return (
                        <Chip
                          key={value._id}
                          label={value.inventory?.name}
                          style={{ marginRight: 5, marginBottom: 5 }}
                        />
                      );
                    return null;
                  })
                : null}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.quantity,
      name: "inventories",
      options: {
        sort: false,
        display: "false",
        customBodyRender: (values: any) => {
          return (
            <>
              {values
                ? values.reduce((acc: number, value: any) => {
                    return acc + +value.quantity;
                  }, 0)
                : null}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.vat,
      name: "vat",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.stockPrice,
      name: "stockPrice",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.tags,
      name: "tags",
      options: {
        sort: true,
        display: "false",
        customBodyRender: (values: any) => {
          return (
            <>
              {values &&
                values.map((value: string, index: number) => (
                  <>
                    {index < 3 ? (
                      <Chip
                        key={value}
                        label={value}
                        style={{ marginRight: 5, marginBottom: 5 }}
                      />
                    ) : index === 3 ? (
                      <Chip
                        key={value}
                        label={"..."}
                        style={{ marginRight: 5, marginBottom: 5 }}
                      />
                    ) : null}
                  </>
                ))}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.createdAt,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return <>{value ? moment(value).format(euFormatForDateTime) : ""}</>;
        },
      },
    },
  ];

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split(/[?&]+/);
    const newFilters: any = {
      startDate: moment().startOf("year"),
      endDate: moment().date(25),
      type: null,
      category: null,
      inventory: null,
    };
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  /**
   *
   * @param item
   */
  function handleEdit(item: ProductModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateProduct(product: ProductModel) {
    product.inventories.forEach((inv: any) => {
      delete inv.isNew;
    });
    //form data
    const formData: any = new FormData();
    if (product.files && product.files.length !== 0)
      product.files.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", []);
    product.images = product.images.filter(
      (img: any) => typeof img === "string"
    );
    product.files = [];
    formData.append("product", JSON.stringify(product));
    //end form data

    if (product?._id) {
      postData(`${urlEnum.products}/${product._id}`, formData)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
            setShouldUpdate(true);
          }
        })
        .catch((err) => {
          setOpen(false);
          setShouldUpdate(true);
        });
    } else {
      postData(urlEnum.products, formData)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
            setShouldUpdate(true);
          }
        })
        .catch((err) => {
          setOpen(false);
          setShouldUpdate(true);
        });
    }
  }

  /**
   *
   */
  function postOnShopify(product: ProductModel) {
    updateData(`${urlEnum.products}/shopify/${product._id}`).then((res) => {
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      setOpen(false);
      setShouldUpdate(true);
    });
  }

  /**
   *
   */
  function multiplePostOnShopify(url: string) {
    setShouldUpdate(false);
    updateData(`${urlEnum.products}/${url}`, { ids: selectedIds }).then(
      (res) => {
        setOpen(false);
        setOpenModal({ action: null, value: false });
        setSelectedIds([]);
        setShouldUpdate(true);
      }
    );
  }

  /**
   *
   * @param rowsSelected
   * @param allRows
   */
  function onRowsSelect(newSelectedIds: Array<string>) {
    setSelectedIds(newSelectedIds);
  }

  /**
   *
   * @returns
   */
  function customToolbarSelect() {
    return (
      <div>
        <Button
          variant="contained"
          style={{
            marginRight: 10,
            backgroundColor: theme.palette.primary?.main,
          }}
          color="primary"
          onClick={() => setOpenModal({ action: HttpVerbs.patch, value: true })}
        >
          <PublishIcon style={{ marginRight: 5 }} />
          {Vocabulary.publishOnShopify}
        </Button>
        <Button
          variant="contained"
          style={{
            marginRight: 10,
            backgroundColor: theme.palette.errorColor?.main,
          }}
          onClick={() =>
            setOpenModal({ action: HttpVerbs.delete, value: true })
          }
        >
          <Delete style={{ marginRight: 5 }} />
          {Vocabulary.deleteFromShopify}
        </Button>
      </div>
    );
  }

  /**
   *
   * @param items
   */
  async function saveToDatabase(items: any) {
    const res = await postData(`${urlEnum.products}/insertMany`, items);
    if (res && res.data && res.data.result) {
      setOpenImportExcel(false);
      setShouldUpdate(true);
    }
  }
  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <Filters
        filters={filters}
        recalculateReportsButton={false}
        type={{
          show: true,
          multiple: false,
          disableClearable: false,
        }}
        category={{
          show: true,
          multiple: false,
          disableClearable: false,
        }}
        inventory={{
          show: true,
          multiple: false,
          disableClearable: false,
        }}
      />
      <MainTable
        urlEnumApi={urlEnum.products}
        titleDelete={Vocabulary.deleteProduct}
        textDelete={Vocabulary.deleteConfirmationProduct}
        header={productsHeader}
        tableTitle={Vocabulary.productsList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        selectableRows={"multiple"}
        onRowsSelect={(selectedIds: Array<string>) => onRowsSelect(selectedIds)}
        customToolbarSelect={customToolbarSelect}
        headerButtons={
          <ImportExcelButton
            onClickImportButton={() => {
              setOpenImportExcel(true);
              setShouldUpdate(false);
            }}
          />
        }
      />
      {open ? (
        <ProductMainPage
          editProduct={editItem}
          open={open}
          addOrUpdateProduct={(product) => addOrUpdateProduct(product)}
          postOnShopify={(product) => postOnShopify(product)}
          onClose={() => handleEdit(null)}
          deleteProductOnShopify={(id: string | null) => {
            setOpenModal({ action: HttpVerbs.delete, value: true });
            if (id) setSelectedIds([id]);
          }}
        />
      ) : null}
      {openModal.value ? (
        <GenericConfirmModal
          title={
            openModal.action === HttpVerbs.patch
              ? Vocabulary.publishOnShopify
              : Vocabulary.deleteFromShopify
          }
          text={
            openModal.action === HttpVerbs.patch
              ? Vocabulary.publishOnShopifyMessage
              : Vocabulary.deleteFromShopifyMessage
          }
          openDeleteModal={openModal.value}
          onClose={() => setOpenModal({ action: null, value: false })}
          deleteItem={() => {
            if (openModal.action === HttpVerbs.patch)
              multiplePostOnShopify("shopify");
            else multiplePostOnShopify("shopify/delete");
          }}
        />
      ) : null}
      {openImportExcel ? (
        <ImportExcelModal
          open={openImportExcel}
          onClose={() => setOpenImportExcel(false)}
          saveItems={(items: any) => saveToDatabase(items)}
          description={description}
        />
      ) : null}
    </div>
  );
}
