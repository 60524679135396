/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add } from "@mui/icons-material";
import { Button, ToggleButton, Tooltip, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { deleteData } from "../../Services/deleteData";
import { getData } from "../../Services/getData";
import styles from "../../Styles/dashboard.module.css";
import Config, { dayPilotFormat } from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTableCustomStyle from "../MUIDataTableCustomStyle";
import GenericConfirmModal from "./GenericConfirmModal";
import { WebsocketContext } from "../../Contexts/WebsocketContext";
import EditDeleteButtons from "./EditDeleteButtons";
import useQueryStringParser from "./useQueryStringParser";
import moment, { Moment } from "moment";
import { StockTransferStatuses } from "../../Utils/AutocompleteUtils";
import { getRequestUrlForMainTable } from "../../Utils/Utils";

type AnotherButtonsOptions = {
  title: string;
  icon: any;
  action: (item: any) => void;
};

type MainTableProps = {
  urlEnumApi: string;
  header: any;
  tableTitle: string;
  titleDelete: string;
  textDelete: string;
  openDeleteModalProp?: boolean;
  deletedIdProp?: string | null;
  shouldUpdate?: boolean;
  showSearch?: any;
  expandableRows?: boolean;
  selectableRows?: string;
  enableSort?: boolean;
  headerButtons?: any;
  hideOptionButtons?: boolean;
  hideAddButton?: boolean;
  hideEditButton?: boolean;
  hideDeleteButton?: boolean;
  showItem?: boolean;
  style?: any;
  anotherButtonsOptions?: AnotherButtonsOptions;
  handleEdit?: (item: any, showItem?: any) => void;
  handleRestore?: (id: number) => void;
  renderExpandableRow?: (rowData: any, rowMeta: any, data: any) => void;
  onRowsSelect?: (selectedIds: Array<string>) => void;
  customToolbarSelect?: () => void;
};

type StateType = {
  page: number;
  perPage: number;
  search: string | null;
  sort: string;
  items: any;
  count: number;
  startDate: Moment;
  endDate: Moment;
  order: string | null;
  orderStatus: string | null;
  user: string | null;
  customer: string | null;
  vendor: string | null;
  device: string | null;
  workflow: string | null;
  workflowStatus: string | null;
  step: string | null;
  product: string | null;
  inventory: string | null;
  stockStatus: string | null;
  type: string | null;
  category: string | null;
};

export default function MainTable(props: MainTableProps) {
  const {
    urlEnumApi,
    header,
    tableTitle,
    titleDelete,
    textDelete,
    openDeleteModalProp,
    deletedIdProp,
    shouldUpdate,
    showSearch,
    expandableRows,
    selectableRows,
    enableSort,
    headerButtons,
    hideOptionButtons,
    hideAddButton,
    hideEditButton,
    hideDeleteButton,
    showItem,
    style,
    anotherButtonsOptions,
    handleEdit,
    handleRestore,
    renderExpandableRow,
    onRowsSelect,
    customToolbarSelect,
  } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const websocketContext = useContext(WebsocketContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(
    openDeleteModalProp ? openDeleteModalProp : false
  );
  const [deletedId, setDeletedId] = useState<string | null>(
    deletedIdProp ? deletedIdProp : null
  );
  const [state, setState] = useState<StateType>({
    page: 0,
    perPage: 20,
    search: "",
    sort: "fixablyId,desc",
    items: [],
    count: 0,
    startDate: moment().startOf("year"),
    endDate: moment().date(25),
    order: null,
    orderStatus: null,
    user: null,
    customer: null,
    vendor: null,
    device: null,
    workflow: null,
    workflowStatus: null,
    step: null,
    product: null,
    inventory: null,
    stockStatus: null,
    type: null,
    category: null,
  });

  useEffect(() => {
    if (shouldUpdate) {
      getItems(
        state.page,
        state.perPage,
        state.sort,
        state.search,
        state.startDate,
        state.endDate,
        state.order,
        state.orderStatus,
        state.user,
        state.customer,
        state.vendor,
        state.device,
        state.workflow,
        state.workflowStatus,
        state.step,
        state.product,
        state.inventory,
        state.stockStatus,
        state.type,
        state.category
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  /**
   *
   */
  useQueryStringParser((data: any) => {
    getItems(
      data.page ? parseInt(data.page) : state.page,
      data.perPage ? parseInt(data.perPage) : state.perPage,
      data.sort ? data.sort : state.sort,
      data.search,
      data.startDate ? moment(data.startDate, dayPilotFormat) : state.startDate,
      data.endDate ? moment(data.endDate, dayPilotFormat) : state.endDate,
      data.order ? data.order : null,
      data.orderStatus ? data.orderStatus : null,
      data.user ? data.user : null,
      data.customer ? data.customer : null,
      data.vendor ? data.vendor : null,
      data.device ? data.device : null,
      data.workflow ? data.workflow : null,
      data.workflowStatus ? data.workflowStatus : null,
      data.step ? data.step : null,
      data.product ? data.product : null,
      data.inventory ? data.inventory : null,
      data.stockStatus ? data.stockStatus : null,
      data.type ? data.type : null,
      data.category ? data.category : null
    );
  });

  /**
   *
   * @param filter
   */
  const getItems = (
    page: number,
    perPage: number,
    sort: string,
    search: string | null,
    startDate: Moment,
    endDate: Moment,
    order: string | null,
    orderStatus: string | null,
    user: string | null,
    customer: string | null,
    vendor: string | null,
    device: string | null,
    workflow: string | null,
    workflowStatus: string | null,
    step: string | null,
    product: string | null,
    inventory: string | null,
    stockStatus: string | null,
    type: string | null,
    category: string | null
  ) => {
    const url = getRequestUrlForMainTable(
      urlEnumApi,
      page,
      perPage,
      sort,
      search,
      startDate,
      endDate,
      order,
      orderStatus,
      user,
      customer,
      vendor,
      device,
      workflow,
      workflowStatus,
      step,
      product,
      inventory,
      stockStatus,
      type,
      category
    );

    getData(url).then((res: any) => {
      if (res) {
        setState({
          ...state,
          items: res.data.result,
          count: res.data.count,
          search: search,
          page: page,
          perPage: perPage,
          sort: sort,
          startDate: startDate,
          endDate: endDate,
          order: order,
          orderStatus: orderStatus,
          user: user,
          customer: customer,
          vendor: vendor,
          device: device,
          workflow: workflow,
          workflowStatus: workflowStatus,
          step: step,
          product: product,
          inventory: inventory,
          stockStatus: stockStatus,
          category: category,
          type: type,
        });
      }
    });
  };

  /**
   *
   * @param name
   * @param value
   */
  const addParamToQueryString = (name: any, value: any) => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };

  /**
   *
   * @param name
   * @param value
   */
  const removeParamFromQueryString = (name: any) => {
    name.map((param: any) => searchParams.delete(param));
    setSearchParams(searchParams);
  };

  /**
   *
   */
  function handleDeleteModal() {
    setOpenDeleteModal(!openDeleteModal);
  }
  /**
   *
   * @param id
   */
  const deleteItem = (id: string) => {
    deleteData(`${urlEnumApi}/${id}`).then((res: any) => {
      if (res) {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        getItems(
          state.page,
          state.perPage,
          state.sort,
          state.search,
          state.startDate,
          state.endDate,
          state.order,
          state.orderStatus,
          state.user,
          state.customer,
          state.vendor,
          state.device,
          state.workflow,
          state.workflowStatus,
          state.step,
          state.product,
          state.inventory,
          state.stockStatus,
          state.type,
          state.category
        );
      }
    });
  };

  const tableHeader = [
    {
      label: Vocabulary.crtNo,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return state.page * state.perPage + meta.rowIndex + 1;
        },
      },
    },
    ...header,
    ...(hideOptionButtons
      ? []
      : [
          {
            label: Vocabulary.options,
            name: Vocabulary.options,
            options: {
              filter: false,
              setCellHeaderProps: () => ({
                align: "center",
              }),
              setCellProps: () => ({
                align: "center",
              }),
              sort: false,
              empty: true,
              customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                return (
                  <>
                    {state.items[rowIndex].status ===
                    StockTransferStatuses.received ? null : (
                      <EditDeleteButtons
                        onEdit={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (handleEdit) handleEdit(state.items[rowIndex]);
                          if (handleRestore)
                            handleRestore(state.items[rowIndex]?._id);
                        }}
                        hideEditButton={hideEditButton}
                        onDelete={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setDeletedId(state.items[rowIndex]?._id);
                          handleDeleteModal();
                        }}
                        hideDeleteButton={hideDeleteButton}
                        showRestore={handleRestore ? true : false}
                        anotherButtons={
                          anotherButtonsOptions &&
                          ((state.items[rowIndex].status &&
                            state.items[rowIndex].status !==
                              StockTransferStatuses.outtake) ||
                            (!state.items[rowIndex].orderId &&
                              !state.items[rowIndex].stepId)) ? (
                            <Tooltip title={anotherButtonsOptions.title}>
                              <ToggleButton
                                value="center"
                                aria-label="centered"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  anotherButtonsOptions.action(
                                    state.items[rowIndex]
                                  );
                                }}
                              >
                                {anotherButtonsOptions.icon}
                              </ToggleButton>
                            </Tooltip>
                          ) : null
                        }
                      />
                    )}
                  </>
                );
              },
            },
          },
        ]),
  ];
  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: selectableRows ? selectableRows : ("none" as any),
      viewColumns: true,
      responsive: responsive,
      rowsPerPageOptions: Config.rowsPerPage,
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      expandableRows: expandableRows ? expandableRows : false,
      expandableRowsHeader: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: {
          next: Vocabulary.nextPage,
          previous: Vocabulary.previousPage,
          rowsPerPage: Vocabulary.rowsPerPage,
          displayRows: Vocabulary.of,
        },
        toolbar: {
          search: Vocabulary.search,
          downloadCsv: Vocabulary.downloadCSV,
          print: Vocabulary.print,
        },
        selectedRows: {
          text: Vocabulary.rowsSelected,
          delete: Vocabulary.delete,
        },
      },
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      search:
        showSearch !== "undefined" ||
        showSearch !== undefined ||
        showSearch !== "null" ||
        showSearch !== null
          ? showSearch
          : true,
      serverSide: true,
      customToolbar: () => {
        return (
          <>
            {hideAddButton ? null : (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  if (handleEdit) handleEdit(null);
                }}
                className={styles.addButtonTableHeader}
              >
                {Vocabulary.add}
              </Button>
            )}
            {headerButtons ? headerButtons : null}
          </>
        );
      },
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        return {
          style: handleEdit
            ? {
                cursor: "pointer",
              }
            : {},
        };
      },
      onRowClick: (rowData: any, rowState: any) => {
        if (handleEdit) handleEdit(state.items[rowState.rowIndex], showItem);
      },
      renderExpandableRow: (rowData: any, rowMeta: any) => {
        const data = state.items[rowMeta.dataIndex].children;
        if (data.length === 0) return null;
        if (renderExpandableRow)
          return renderExpandableRow(rowData, rowMeta, data);
        return null;
      },
      onSearchChange: async (search: string | null) => {
        setState({ ...state, search: search });
        addParamToQueryString("search", search);
      },
      onSearchClose: async () => {
        removeParamFromQueryString(["search"]);
      },
      onChangePage: (page: any) => {
        addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onColumnSortChange: (changedColumn: any, direction: any) => {
        if (enableSort)
          switch (changedColumn) {
            case "device":
            case "workflow":
            case "status":
              addParamToQueryString(
                "sort",
                `${changedColumn}.name,${direction}`
              );
              break;
            default:
              addParamToQueryString("sort", `${changedColumn},${direction}`);
              break;
          }
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        addParamToQueryString("page", 0);
        addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      onRowsSelect: (currentRowsSelected: any, allRowsSelected: any) => {
        if (onRowsSelect) {
          const selectedIds = allRowsSelected.map(
            (row: any) => state.items[row.dataIndex]._id
          );
          onRowsSelect(selectedIds);
        }
      },
      customToolbarSelect: () => {
        if (customToolbarSelect) return customToolbarSelect();
      },
    };
  };

  return (
    <>
      <div
        className={
          phoneStyle ? styles.tableContainerMobile : styles.tableContainer
        }
      >
        <MUIDataTableCustomStyle
          title={tableTitle}
          data={state.items}
          columns={tableHeader}
          options={getTableOptions()}
          style={style}
        />
      </div>
      {openDeleteModal ? (
        <GenericConfirmModal
          title={titleDelete}
          text={textDelete}
          openDeleteModal={openDeleteModal}
          onClose={() => handleDeleteModal()}
          deleteItem={() => {
            deleteItem(deletedId || "");
            handleDeleteModal();
          }}
        ></GenericConfirmModal>
      ) : null}
    </>
  );
}
