/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import moment from "moment";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CheckInPagesProps } from "./CheckInCustomer";
import { booleanTypesArray, stepTypes } from "../../Utils/AutocompleteUtils";

export default function CheckInAdditionalInformation(props: CheckInPagesProps) {
  const { checkInOrder, handleChangeCheckInOrder } = props;
  const userId = localStorage.getItem("userId");

  /**
   *
   */
  useEffect(() => {
    if (checkInOrder.additionalInformation.length === 0) {
      getSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  async function getSteps() {
    const response = await getData(`${urlEnum.steps}/stepsForCheckIn`);
    if (response && response.data && response.data.result) {
      const newCurrentOrderSteps = [];
      for (let i = 0; i < response.data.result.length; i++) {
        const customValue =
          response.data.result[i].type === stepTypes.text
            ? response.data.result[i].defaultValues.length !== 0
              ? response.data.result[i].defaultValues[0].text
              : ""
            : null;
        const currentStep = {
          stepId: response.data.result[i],
          description: null,
          images: [],
          timeTracking: [],
          date: moment(),
          customStepValue: customValue,
          createdBy: userId,
        };
        newCurrentOrderSteps.push(currentStep);
      }
      handleChangeCheckInOrder({
        ...checkInOrder,
        additionalInformation: newCurrentOrderSteps,
      });
    }
  }

  /**
   *
   */
  function showFieldForEachStep(step: any, index: number) {
    switch (step.stepId.type) {
      case stepTypes.text:
        return (
          <TextField
            size="small"
            key={step.stepId}
            name={step.stepId.name}
            label={step.stepId.name}
            value={step.customStepValue}
            onChange={(e: any) => {
              const newCurrentOrderSteps = [
                ...checkInOrder.additionalInformation,
              ];
              newCurrentOrderSteps[index].customStepValue = e.target.value;
              handleChangeCheckInOrder({
                ...checkInOrder,
                additionalInformation: newCurrentOrderSteps,
              });
            }}
            fullWidth
            style={{ marginBottom: 20 }}
          />
        );
      case stepTypes.boolean:
        return (
          <Autocomplete
            size="small"
            key={step.stepId}
            value={step.customStepValue}
            options={booleanTypesArray}
            getOptionLabel={(option: any) => option.text}
            renderInput={(params) => (
              <TextField {...params} label={step.stepId.name} />
            )}
            onChange={(e: any, value: any) => {
              const newCurrentOrderSteps = [
                ...checkInOrder.additionalInformation,
              ];
              newCurrentOrderSteps[index].customStepValue = value
                ? value
                : null;
              handleChangeCheckInOrder({
                ...checkInOrder,
                additionalInformation: newCurrentOrderSteps,
              });
            }}
            style={{ marginBottom: 20 }}
          />
        );
      case stepTypes.multiselect:
        return (
          <Autocomplete
            size="small"
            key={step.stepId}
            value={step.customStepValue}
            options={step.stepId.defaultValues}
            getOptionLabel={(option: any) => option.value}
            renderInput={(params) => (
              <TextField {...params} label={step.stepId.name} />
            )}
            onChange={(e: any, value: any) => {
              const newCurrentOrderSteps = [
                ...checkInOrder.additionalInformation,
              ];
              newCurrentOrderSteps[index].customStepValue = value
                ? value
                : null;
              handleChangeCheckInOrder({
                ...checkInOrder,
                additionalInformation: newCurrentOrderSteps,
              });
            }}
            style={{ marginBottom: 20 }}
          />
        );
      default:
        return null;
    }
  }
  return (
    <div style={{ margin: "10px 0px" }}>
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 400,
          marginBottom: 15,
          textAlign: "center",
        }}
      >
        {Vocabulary.additionalInformation}
      </Typography>
      {checkInOrder.additionalInformation.map((step: any, index: number) => (
        <>{showFieldForEachStep(step, index)}</>
      ))}
    </div>
  );
}
