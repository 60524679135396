/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Tab, Tabs } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CustomerModel } from "../../Utils/Models";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import Customer from "./Customer";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import Address from "./Address";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type CustomerMainPageProps = {
  editCustomer: CustomerModel | null;
  open: boolean;
  addOrUpdateCustomer: (customer: CustomerModel | null) => void;
  onClose: () => void;
  fromOrder: boolean;
};
export default function CustomerMainPage(props: CustomerMainPageProps) {
  const { editCustomer, open, fromOrder, addOrUpdateCustomer, onClose } = props;
  const ref: any = useRef();
  const cache = useCustomMemo();
  const taxRates = cache.taxRates || [];
  const [customer, setCustomer] = useState<CustomerModel>(new CustomerModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [value, setValue] = useState(0);
  const [arraysOfString, setArraysOfString] = useState<any>({
    tags: [],
  });
  const buttons = fromOrder ? (
    <Button
      onClick={resetCustomer}
      variant="contained"
      style={{ marginBottom: 10, marginTop: 10, color: "white" }}
      fullWidth
      color="secondary"
    >
      {Vocabulary.reset}
    </Button>
  ) : null;

  /**
   *
   */
  useEffect(() => {
    if (editCustomer) {
      setCustomer(editCustomer); // aici trebuie pus ceva cu custom fields
    } else {
      const newCustomer = new CustomerModel();
      const taxRate = taxRates && taxRates.find((x: any) => x.defaultValue);
      newCustomer.vatNumber = taxRate ? taxRate.value : "0";
      setCustomer(newCustomer);
    }
    getArraysOfString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCustomer]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function getArraysOfString() {
    getData(`${urlEnum.customers}/arraysOfString`).then((res: any) => {
      if (res.data.status === 200) setArraysOfString(res.data.result);
    });
  }
  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  /**
   *
   * @param value
   */
  function handleChangeCustomer(value: CustomerModel) {
    setCustomer(value);
  }

  /**
   *
   * @param value
   */
  function handleChangeArraysOfString(arraysOfString: any) {
    setArraysOfString(arraysOfString);
  }
  /**
   *
   */
  function showInformation() {
    switch (value) {
      case 0:
        return (
          <Customer
            customer={customer}
            handleChangeCustomer={handleChangeCustomer}
            arraysOfString={arraysOfString}
            handleChangeArraysOfString={handleChangeArraysOfString}
          />
        );
      case 1:
        return (
          <Address
            editAddress={customer.shippingAddress}
            handleChangeAddressToCustomer={(address: any) =>
              handleChangeCustomer({ ...customer, shippingAddress: address })
            }
          />
        );
      case 2:
        return (
          <Address
            editAddress={customer.billingAddress}
            handleChangeAddressToCustomer={(address: any) =>
              handleChangeCustomer({ ...customer, billingAddress: address })
            }
          />
        );
      default:
        break;
    }
  }

  /**
   *
   */
  function resetCustomer() {
    if (editCustomer) {
      setCustomer(editCustomer);
    } else {
      setCustomer(new CustomerModel());
    }
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateCustomer(customer)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.customer}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
        buttons={buttons ? buttons : null}
      >
        <Tabs value={value} onChange={handleChangeValue} textColor="inherit">
          <Tab label={Vocabulary.customer} />
          <Tab label={Vocabulary.shippingAddress} />
          <Tab label={Vocabulary.billingAddress} />
        </Tabs>
        {showInformation()}
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
