/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericModal from "../GenericComponents/GenericModal";
import CheckInMainPage from "./CheckInMainPage";

type CheckInModalProps = {
  open: boolean;
  onClose: () => void;
};

export default function CheckInModal(props: CheckInModalProps) {
  const { open, onClose } = props;

  return (
    <GenericModal
      open={open}
      onClose={onClose}
      title={Vocabulary.checkIn}
      styleDialogPaper={{
        width: "100%",
        maxWidth: "900px",
        height: "60vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CheckInMainPage onClose={onClose} />
    </GenericModal>
  );
}
