/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete, Edit } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import { Vocabulary } from "../../Utils/Vocabulary";
type EditDeleteButtonsProps = {
  onEdit: (e?: any) => void;
  onDelete: (e?: any) => void;
  hideEditButton?: boolean;
  hideDeleteButton?: boolean;
  anotherButtons?: any;
  showRestore?: boolean;
  style?: any;
};
export default function EditDeleteButtons(props: EditDeleteButtonsProps) {
  const {
    onEdit,
    hideEditButton,
    hideDeleteButton,
    onDelete,
    showRestore,
    style,
    anotherButtons,
  } = props;

  return (
    <ToggleButtonGroup
      exclusive
      aria-label="text alignment"
      size="small"
      style={style ? style : {}}
    >
      {anotherButtons ? anotherButtons : null}
      {hideEditButton ? null : (
        <Tooltip title={showRestore ? Vocabulary.restore : Vocabulary.edit}>
          <ToggleButton value="left" aria-label="left aligned" onClick={onEdit}>
            {showRestore ? <RestoreIcon /> : <Edit />}
          </ToggleButton>
        </Tooltip>
      )}
      {hideDeleteButton ? null : (
        <Tooltip title={Vocabulary.delete}>
          <ToggleButton value="center" aria-label="centered" onClick={onDelete}>
            <Delete />
          </ToggleButton>
        </Tooltip>
      )}
    </ToggleButtonGroup>
  );
}
