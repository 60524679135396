/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Chip, Collapse, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AttributeModel } from "../../Utils/Models";
import AutocompleteAttribute from "../ProductSettings/Attributes/AutocompleteAttribute";

type OneOptionProps = {
  option: AttributeModel;
  index: number;
  handleRemoveOption: (index: number, recalculateVariants: boolean) => void;
  handleChangeOption: (
    index: number,
    option: AttributeModel,
    recalculateVariants: boolean
  ) => void;
};

export default function OneOption(props: OneOptionProps) {
  const { option, index, handleRemoveOption, handleChangeOption } = props;

  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [showMore, setShowMore] = useState(false);
  const [currentOption, setCurrentOption] = useState<AttributeModel>(option);

  /**
   *
   */
  useEffect(() => {
    if (
      !showMore &&
      option.values.length === 1 &&
      option.values[0] === "" &&
      option.name === ""
    ) {
      setShowMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  /**
   *
   */
  useEffect(() => {
    if (
      showMore &&
      currentOption.values.length > 0 &&
      currentOption.values[currentOption.values.length - 1] !== ""
    ) {
      const newOption = Object.assign({}, currentOption);
      newOption.values.push("");
      setCurrentOption(newOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore]);

  /**
   *
   * @returns
   */
  function saveCurrentOption(recalculateVariants: boolean) {
    const newCurrentOption = Object.assign({}, currentOption);
    newCurrentOption.values = newCurrentOption.values.filter(
      (value: string) => value !== ""
    );
    if (recalculateVariants) {
      if (currentOption.name === "") return;
      if (currentOption.values.length === 1 && currentOption.values[0] === "")
        return;
      handleChangeOption(index, newCurrentOption, recalculateVariants);
    } else {
      if (
        currentOption.name === "" &&
        currentOption.values.length === 1 &&
        currentOption.values[0] === ""
      ) {
        handleRemoveOption(index, recalculateVariants);
      }
    }
    setCurrentOption(newCurrentOption);
    setShowMore(!showMore);
  }

  /**
   *
   * @param newAttribute
   */
  function handleChangeAttribute(newAttribute: AttributeModel) {
    setCurrentOption(newAttribute);
  }
  return (
    <div style={{ padding: "5px 0px" }}>
      {showMore ? null : (
        <div
          onClick={() => {
            setCurrentOption(option);
            setShowMore(!showMore);
          }}
          style={{ cursor: "pointer" }}
        >
          <p style={{ marginBottom: 7, marginTop: 5 }}>{option.name}</p>
          {option.values.map((value) =>
            value === "" ? null : (
              <Chip
                key={value}
                label={value}
                style={{
                  fontWeight: 200,
                  marginRight: 10,
                  borderRadius: 4,
                  padding: 6,
                  marginBottom: 7,
                  height: "auto",
                }}
              />
            )
          )}
        </div>
      )}
      <Collapse in={showMore}>
        <AutocompleteAttribute
          attribute={currentOption}
          handleChangeAttribute={handleChangeAttribute}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => handleRemoveOption(index, true)}
            style={{ marginRight: 10 }}
          >
            <DeleteForeverIcon color="error" style={{ marginRight: 10 }} />
            {Vocabulary.removeOption}
          </Button>

          <div style={phoneStyle ? { width: "100%" } : {}}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => saveCurrentOption(false)}
              style={{ marginRight: 10 }}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => saveCurrentOption(true)}
            >
              {Vocabulary.done}
            </Button>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
