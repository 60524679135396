/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { Grid, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Filters from "../../Components/Reports/Filters";
import moment from "moment";
import { useLocation } from "react-router-dom";
import useCustomMemo from "../../Components/GenericComponents/useCustomMemo";
import ReportsPerUser from "../../Components/Reports/ReportsPerUser";
import styles from "../../Styles/dashboard.module.css";
import { urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import { dayPilotFormat } from "../../Utils/Config";
import { FiltersModel } from "../../Utils/Models";

export default function Reports() {
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width:800px)");

  const cache = useCustomMemo();
  const users = cache.users || [];
  const [indexedUsers, setIndexedUsers] = useState<any>({
    evenIndexedUsers: [],
    oddIndexedUsers: [],
  });

  const user = useMemo(() => {
    const localStorageCache = localStorage.getItem("cache");
    const userId = localStorage.getItem("userId");
    if (!localStorageCache) return null;
    const cache = JSON.parse(localStorageCache);
    if (cache) {
      return cache.users.find((user: any) => user._id === userId);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filters, setFilters] = useState<FiltersModel>({
    startDate: moment().subtract(1, "months"),
    endDate: moment(),
    user: [],
    order: null,
  });
  /**
   *
   */
  useEffect(() => {
    let search: any = decodeURIComponent(location.search);
    search = search.split(/[?&]+/);
    const newFilters: any = {
      startDate: moment().subtract(1, "months"),
      endDate: moment(),
      user: [],
      order: null,
    };
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[0] === "user")
          newFilters[values[0]] =
            values[1] === "null" ? [] : values[1].split(",");
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    if (
      filters.user.length === 0 &&
      newFilters.user.length === 0 &&
      location.search === ""
    ) {
      newFilters.user = [user?._id] || [];
    }
    if (newFilters.user.length === 0) {
      setIndexedUsers({
        evenIndexedUsers: users.filter((_: any, index: any) => index % 2 === 0),
        oddIndexedUsers: users.filter((_: any, index: any) => index % 2 !== 0),
      });
    } else {
      setIndexedUsers({
        evenIndexedUsers: newFilters.user.filter(
          (_: any, index: any) => index % 2 === 0
        ),
        oddIndexedUsers: newFilters.user.filter(
          (_: any, index: any) => index % 2 !== 0
        ),
      });
    }
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   */
  async function recalculateReports() {
    const response = await getData(urlEnum.reports);
    if (
      response &&
      response.data &&
      (response.status === 200 || response.status === 201)
    ) {
      window.location.reload();
    }
  }

  return (
    <div
      className={
        phoneStyle
          ? styles.mainContainerReportsMobile
          : styles.mainContainerReports
      }
    >
      <Filters
        filters={filters}
        recalculateReportsButton={true}
        recalculateReportsFunction={recalculateReports}
        user={{
          show: true,
          multiple: true,
          disableClearable: false,
        }}
        order={{
          show: true,
          multiple: false,
          disableClearable: true,
        }}
      />
      <Grid
        container
        spacing={4}
        style={phoneStyle ? { marginBottom: 80 } : {}}
      >
        {filters.user.length !== 0 ? (
          <>
            <Grid item xs={12} md={12} lg={filters.user.length === 1 ? 12 : 6}>
              {indexedUsers.evenIndexedUsers.map((user: any) => (
                <div key={user} style={{ marginBottom: 20 }}>
                  <ReportsPerUser userId={user} filters={filters} />
                </div>
              ))}
            </Grid>
            <Grid item xs={12} md={12} lg={filters.user.length === 1 ? 12 : 6}>
              {indexedUsers.oddIndexedUsers.map((user: any) => (
                <div key={user} style={{ marginBottom: 20 }}>
                  <ReportsPerUser userId={user} filters={filters} />
                </div>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={filters.user.length === 1 ? 12 : 6}>
              {indexedUsers.evenIndexedUsers.map((user: any) => (
                <div key={user._id} style={{ marginBottom: 20 }}>
                  <ReportsPerUser userId={user._id} filters={filters} />
                </div>
              ))}
            </Grid>
            <Grid item xs={12} md={12} lg={filters.user.length === 1 ? 12 : 6}>
              {indexedUsers.oddIndexedUsers.map((user: any) => (
                <div key={user._id} style={{ marginBottom: 20 }}>
                  <ReportsPerUser userId={user._id} filters={filters} />
                </div>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
