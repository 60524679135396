import { Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";

export default function CheckInSaveOrder() {
  return (
    <div
      style={{
        margin: "50px 0px",
      }}
    >
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        {Vocabulary.checkInCreateOrderMessage}
      </Typography>
    </div>
  );
}
