/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import {
  AddressModel,
  LineModel,
  NotesModel,
  ShipmentModel,
  Step,
} from "../../Utils/Models";
import { EditNoteType } from "./Order";
import OrderLines from "./OrderLines";
import GeneralInformation from "./GeneralInformation";
import NotesIcon from "@mui/icons-material/Notes";
import OrderNotes from "./OrderNotes";
import OrderDevice from "./OrderDevice";
import {
  locationName,
  notesTypeArray,
  shipmentStatuses,
} from "../../Utils/AutocompleteUtils";
import OrderWorkflow from "./OrderWorkflow";
import OrderCustomer from "./OrderCustomer";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EmailIcon from "@mui/icons-material/Email";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import { Vocabulary } from "../../Utils/Vocabulary";
import CardComponent from "../GenericComponents/CardComponent";
import TvIcon from "@mui/icons-material/Tv";
import InboxIcon from "@mui/icons-material/Inbox";
import InfoIcon from "@mui/icons-material/Info";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CreateAddOrUpdateAssociatedDataInOrder from "./TimeTracking/CreateAddOrUpdateAssociatedDataInOrder";
import OrderInformation from "./OrderInformation";
import { OrderContext } from "../../Contexts/OrderContext";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import { updateData } from "../../Services/updateData";
import SendEmailModal from "./Emails/SendEmailModal";
import OrderShipment from "./OrderShipment";
import { deleteData } from "../../Services/deleteData";

export type OpenAddProps = {
  note: boolean;
  product: boolean;
  device: boolean;
  workflow: boolean;
  customer: boolean;
  sendEmail: boolean;
};

export type OpenEditProps = {
  device: boolean;
  workflow: boolean;
  customer: boolean;
  step: boolean;
};

type OrderDataComponentsProps = {
  notesBackup: NotesModel[];
};

export default function OrderDataComponents(props: OrderDataComponentsProps) {
  const { notesBackup } = props;
  const cache = useCustomMemo();
  const taxRates = cache.taxRates || [];
  const locations = cache.locations || [];
  const emailSettings = cache.emailSettings || null;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const orderContext = useContext(OrderContext);
  const [modifyStep, setModifyStep] = useState<any>({
    step: null,
    index: 0,
  });
  const [editNote, setEditNote] = useState<EditNoteType>({
    index: 0,
    note: null,
    filter: [],
  });
  const [openAdd, setOpenAdd] = useState<OpenAddProps>({
    note: false,
    product: false,
    device: false,
    workflow: false,
    customer: false,
    sendEmail: false,
  });

  const [openEdit, setOpenEdit] = useState<OpenEditProps>({
    device: false,
    workflow: false,
    customer: false,
    step: false,
  });
  const [shipment, setShipment] = useState<ShipmentModel | null>(null);
  const [openSendEmail, setOpenSendEmail] = useState<any>({
    open: false,
    template: null,
  });

  /**
   *
   */
  useEffect(() => {
    if (orderContext.order) {
      const foundShipment = orderContext.order.shipments.find(
        (sp) => sp.status === shipmentStatuses.pending
      );
      if (foundShipment) {
        setShipment(foundShipment);
      } else {
        setShipment(null);
      }
    }
  }, [orderContext.order]);

  /////////////////////////// ADD OR UPDATE ASSOCIATED DATA ///////////////////////////
  /**
   *
   * @param openAdd
   */
  function handleChangeOpenAdd(openAdd: OpenAddProps) {
    setOpenAdd(openAdd);
  }

  /**
   *
   * @param openEdit
   */
  function handleChangeOpenEdit(openEdit: OpenEditProps) {
    setOpenEdit(openEdit);
  }
  /////////////////////////// END ADD OR UPDATE ASSOCIATED DATA ///////////////////////////

  /////////////////////////// END ORDER NOTES ///////////////////////////
  /**
   *
   * @param event
   */
  function filterNotes(event: any, values: any) {
    if (values.length > 0) {
      setEditNote({ ...editNote, filter: values });
      const newNotes = notesBackup.filter((note) => values.includes(note.type));
      orderContext.setOrder({ ...orderContext.order, notes: newNotes });
    } else {
      setEditNote({ ...editNote, filter: [] });
      orderContext.setOrder({ ...orderContext.order, notes: notesBackup });
    }
  }
  /////////////////////////// END ORDER NOTES ////////////////////////

  /////////////////////////// MODIFY STEP ////////////////////////

  /**
   *
   * @param step
   * @param index
   */
  function handleChangeModifyStep(step: Step | null, index: number) {
    setModifyStep({ step: step, index: index });
  }

  /////////////////////////// END MODIFY STEP ////////////////////////

  /////////////////////////// ORDER SHIPMENTS ////////////////////////
  /**
   *
   */
  async function addNewShipment() {
    // Create new shipment
    const shipment = new ShipmentModel();
    shipment.recipientAddress =
      orderContext.order.customer && orderContext.order.customer.shippingAddress
        ? orderContext.order.customer.shippingAddress
        : new AddressModel();
    shipment.recipientAddress.name =
      `${orderContext.order.customer?.firstName} ${orderContext.order.customer?.lastName}` ||
      "";
    shipment.recipient = orderContext.order.customer || null;
    const existsProductLines = orderContext.order.lines.find((l) => l.product);
    let location = locations.find((x: any) => x.name === locationName);
    const locationRes = await getData(`${urlEnum.locations}/${location._id}`);
    if (locationRes) location = locationRes.data.result;
    if (!shipment.senderAddress) {
      shipment.senderAddress = new AddressModel();
    }
    shipment.senderLocation = location._id;
    shipment.senderAddress.name = location.name || "";
    shipment.senderAddress.address1 = location.address || "";
    shipment.senderAddress.zip = location.zip || "";
    shipment.senderAddress.city = location.city || "";
    shipment.senderAddress.country = location.country || "";
    shipment.packages[0].orders = [orderContext.order._id || ""];
    shipment.packages[0].status =
      orderContext.order.device &&
      orderContext.order.workflow &&
      existsProductLines
        ? shipmentStatuses.outbound
        : shipmentStatuses.inbound;
    shipment.status = shipmentStatuses.pending;
    // Verify if there is a shipment pending for this customer and location
    const existsShipmentResult = await getData(
      `${urlEnum.shipments}/existsShipment/${location._id}/${orderContext.order.customer?._id}`
    );
    if (existsShipmentResult && existsShipmentResult.data.result) {
      // Add package to existing shipment
      const existsShipment = existsShipmentResult.data.result;
      existsShipment.packages.push(shipment.packages[0]);
      const res = await updateData(
        `${urlEnum.shipments}/${existsShipment._id}/${orderContext.order._id}`,
        existsShipment
      );
      if (res) {
        orderContext.setOrder(res.data.result);
      }
    } else {
      // Save shipment
      const res = await postData(
        `${urlEnum.shipments}/${orderContext.order._id}`,
        shipment
      );
      if (res) {
        orderContext.setOrder(res.data.result);
      }
    }
  }

  /**
   *
   */
  async function cancelShipment() {
    const res = await deleteData(
      `${urlEnum.shipments}/${shipment?._id}/${orderContext.order._id}`
    );
    if (res && res.data && res.data.result) {
      orderContext.setOrder(res.data.result);
    }
  }

  /////////////////////////// END ORDER SHIPMENTS ////////////////////////

  /**
   *
   * @param newValue
   */
  function handleChangeOpenSendEmailValue(newValue: any) {
    setOpenSendEmail(newValue);
  }
  return (
    <div
      style={
        phoneStyle ? { width: "100%", marginBottom: 120 } : { width: "100%" }
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <OrderInformation />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* ORDERS LINES - PRODUCTS*/}
          <CardComponent
            icon={<ShoppingCartIcon />}
            title={Vocabulary.productsAndServices}
            headerAction={
              <>
                <Button
                  onClick={() => {
                    const taxRate =
                      taxRates && taxRates.find((x: any) => x.defaultValue);
                    const newLine = new LineModel();
                    newLine.vat = taxRate ? taxRate.value : "0";
                    orderContext.setOrder({
                      ...orderContext.order,
                      lines: [...orderContext.order.lines, newLine],
                    });
                  }}
                  style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                >
                  <AddIcon style={{ marginRight: 5 }} /> {Vocabulary.add}
                </Button>
                <Button
                  onClick={() =>
                    handleChangeOpenAdd({ ...openAdd, product: true })
                  }
                  style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                >
                  <CreateIcon fontSize="small" style={{ marginRight: 5 }} />
                  {Vocabulary.create}
                </Button>
              </>
            }
          >
            <OrderLines />
          </CardComponent>
          {/* ORDERS NOTES */}
          <CardComponent
            icon={<NotesIcon />}
            style={{ marginTop: 20 }}
            title={Vocabulary.notes}
            headerAction={
              <div style={phoneStyle ? {} : { display: "flex" }}>
                <Autocomplete
                  size="small"
                  id="filterTimeline"
                  multiple
                  freeSolo={false}
                  options={notesTypeArray}
                  value={editNote.filter}
                  getOptionLabel={(option: any) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionDisabled={(option) =>
                    !!editNote.filter.find((element: any) => element === option)
                  }
                  onChange={(event: any, value: any) =>
                    filterNotes(event, value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="filterTimeline"
                      placeholder={Vocabulary.filterTimeline}
                      variant="outlined"
                    />
                  )}
                  style={
                    phoneStyle
                      ? {
                          backgroundColor: "white",
                          color: "black",
                          margin: "0px 5px",
                          borderRadius: "50px",
                          minWidth: "200px",
                        }
                      : {
                          backgroundColor: "white",
                          color: "black",
                          margin: "5px",
                          borderRadius: "50px",
                          minWidth: "200px",
                        }
                  }
                />
                <Button
                  onClick={() =>
                    handleChangeOpenAdd({ ...openAdd, note: true })
                  }
                  style={
                    phoneStyle
                      ? { margin: 5, float: "right" }
                      : { margin: "0px 5px" }
                  }
                >
                  <AddIcon fontSize="small" style={{ marginRight: 5 }} />
                  {Vocabulary.add}
                </Button>
              </div>
            }
          >
            <OrderNotes
              handleEdit={(note, index) => {
                handleChangeOpenAdd({ ...openAdd, note: true });
                setEditNote({ ...editNote, note: note, index: index });
              }}
              handleChangeOpenSendEmailValue={handleChangeOpenSendEmailValue}
            />
          </CardComponent>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* GENERAL INFORMATION */}
          <CardComponent
            icon={<InfoIcon />}
            title={Vocabulary.generalInformation}
            headerAction={null}
          >
            <GeneralInformation />
          </CardComponent>
          {/* ORDER DEVICE */}
          <CardComponent
            icon={<TvIcon />}
            style={{ marginTop: 20 }}
            title={Vocabulary.device}
            headerAction={
              <>
                <Button
                  onClick={() =>
                    handleChangeOpenEdit({ ...openEdit, device: true })
                  }
                  style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                >
                  <AddIcon style={{ marginRight: 5 }} /> {Vocabulary.add}
                </Button>
                <Button
                  onClick={() =>
                    handleChangeOpenAdd({ ...openAdd, device: true })
                  }
                  style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                  disabled={orderContext.order.device ? true : false}
                >
                  <CreateIcon fontSize="small" style={{ marginRight: 5 }} />
                  {Vocabulary.create}
                </Button>
              </>
            }
          >
            <OrderDevice
              onEdit={() => {
                handleChangeOpenAdd({ ...openAdd, device: true });
              }}
            />
          </CardComponent>
          {/* ORDER WORKFLOW */}
          {orderContext.order?._id ? (
            <CardComponent
              icon={<InboxIcon />}
              style={{ marginTop: 20 }}
              title={Vocabulary.workflow}
              headerAction={
                <>
                  <Button
                    onClick={() =>
                      handleChangeOpenEdit({ ...openEdit, workflow: true })
                    }
                    style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                  >
                    <AddIcon style={{ marginRight: 5 }} /> {Vocabulary.add}
                  </Button>
                  <Button
                    onClick={() =>
                      handleChangeOpenAdd({ ...openAdd, workflow: true })
                    }
                    style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                    disabled={orderContext.order.workflow ? true : false}
                  >
                    <CreateIcon fontSize="small" style={{ marginRight: 5 }} />
                    {Vocabulary.create}
                  </Button>
                </>
              }
            >
              <OrderWorkflow
                steps={orderContext.order.workflowSteps}
                handleOpenOrderStep={() =>
                  handleChangeOpenEdit({ ...openEdit, step: true })
                }
                handleChangeModifyStep={(step, index) =>
                  handleChangeModifyStep(step, index)
                }
                onEdit={() =>
                  handleChangeOpenAdd({ ...openAdd, workflow: true })
                }
              />
            </CardComponent>
          ) : null}
          {/* ORDER CUSTOMER */}
          <CardComponent
            icon={<SupportAgentIcon />}
            style={{ marginTop: 20 }}
            title={Vocabulary.customer}
            headerAction={
              <>
                <Button
                  onClick={() =>
                    handleChangeOpenAdd({ ...openAdd, sendEmail: true })
                  }
                  style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                  disabled={
                    orderContext.order.customer && orderContext.order.status
                      ? false
                      : true
                  }
                >
                  <EmailIcon style={{ marginRight: 5 }} />{" "}
                  {Vocabulary.sendEmail}
                </Button>
                <Button
                  onClick={() =>
                    handleChangeOpenEdit({ ...openEdit, customer: true })
                  }
                  style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                >
                  <AddIcon style={{ marginRight: 5 }} /> {Vocabulary.add}
                </Button>
                <Button
                  onClick={() =>
                    handleChangeOpenAdd({ ...openAdd, customer: true })
                  }
                  style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
                  disabled={orderContext.order.customer ? true : false}
                >
                  <CreateIcon fontSize="small" style={{ marginRight: 5 }} />
                  {Vocabulary.create}
                </Button>
              </>
            }
          >
            <OrderCustomer
              onEdit={() => handleChangeOpenAdd({ ...openAdd, customer: true })}
            />
          </CardComponent>
          {/* ORDER SHIPMENTS */}
          <CardComponent
            icon={<LocalShippingIcon />}
            style={{ marginTop: 20 }}
            title={Vocabulary.shipments}
            headerAction={
              <Button
                onClick={
                  shipment ? () => cancelShipment() : () => addNewShipment()
                }
                style={phoneStyle ? { margin: 5 } : { margin: "0px 5px" }}
              >
                <AddIcon style={{ marginRight: 5 }} />{" "}
                {shipment
                  ? Vocabulary.cancelShipment
                  : Vocabulary.requestShipment}
              </Button>
            }
          >
            <OrderShipment
              shipment={shipment}
              shipments={
                orderContext.order.shipments.filter(
                  (sp) => sp.status !== shipmentStatuses.pending
                ) || []
              }
            />
          </CardComponent>
        </Grid>
      </Grid>
      {/* ASSOCIATED DATA */}
      <CreateAddOrUpdateAssociatedDataInOrder
        modifyStep={modifyStep}
        editNote={editNote}
        openAdd={openAdd}
        openEdit={openEdit}
        handleChangeOpenAdd={handleChangeOpenAdd}
        handleChangeOpenEdit={handleChangeOpenEdit}
        handleChangeEditNote={(editNote) => setEditNote(editNote)}
        handleChangeModifyStep={(step: Step | null, index: number) =>
          handleChangeModifyStep(step, index)
        }
      />
      {openSendEmail.open ? (
        <SendEmailModal
          open={openSendEmail.open}
          to={openSendEmail.to}
          from={emailSettings ? emailSettings.orders : ""}
          noteId={openSendEmail.noteId}
          databaseTemplate={openSendEmail.template}
          title={Vocabulary.sendEmail}
          onClose={() =>
            setOpenSendEmail({
              open: false,
              template: null,
              to: "",
              from: "",
              noteId: null,
            })
          }
        />
      ) : null}
    </div>
  );
}
