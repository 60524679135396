/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useContext, useEffect, useState } from "react";
import { ImportExcelDescription, StatusModel } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { postData } from "../Services/postData";
import Status from "../Components/Statuses/Status";
import { WebsocketContext } from "../Contexts/WebsocketContext";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import StatusesFilters from "../Components/Statuses/StatusesFilters";
import ImportExcelModal from "../Components/GenericComponents/ImportExcelModal";
import ImportExcelButton from "../Components/GenericComponents/ImportExcelButton";

export default function Statuses() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const websocketContext = useContext(WebsocketContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [editItem, setEditItem] = useState<StatusModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [filters, setFilters] = useState<any>({
    workflowId: null,
  });

  /**
   *
   */
  const description: Array<ImportExcelDescription> = [
    {
      name: "Name",
      description: Vocabulary.itemName,
      example: "Picked up",
    },
    {
      name: "Description",
      description: Vocabulary.itemDescription,
      example: "The item has been picked up.",
    },
    {
      name: "Type",
      description: Vocabulary.itemType,
      example: "PICKED_UP",
    },
    {
      name: "Liable",
      description: Vocabulary.itemLiable,
      example: "NOT_APPLICABLE",
    },
  ];
  /**
   *
   */
  const statusHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: { sort: false },
    },
    {
      label: Vocabulary.type,
      name: "type",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.workflow,
      name: "workflowId",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value && value.name ? value.name : null}</>;
        },
      },
    },
    {
      label: Vocabulary.liable,
      name: "liable",
      options: {
        sort: false,
      },
    },
  ];

  useEffect(() => {
    const search = location.search.split(/[?&]+/);
    const newFilters: any = Object.assign({}, filters);
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  /**
   *
   * @param item
   */
  function handleEdit(item: StatusModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param status
   */
  function addOrUpdateStatus(status: StatusModel | null) {
    setShouldUpdate(true);
    if (status?._id) {
      updateData(`${urlEnum.statuses}/${status._id}`, status)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.statuses, status)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  /**
   *
   * @param items
   */
  async function saveToDatabase(items: any) {
    const res = await postData(`${urlEnum.statuses}/insertMany`, items);
    if (res && res.data && res.data.result) {
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      setOpenImportExcel(false);
      setShouldUpdate(true);
    }
  }
  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <StatusesFilters filters={filters} />
      <MainTable
        urlEnumApi={urlEnum.statuses}
        titleDelete={Vocabulary.deleteStatus}
        textDelete={Vocabulary.deleteConfirmationStatus}
        header={statusHeader}
        tableTitle={Vocabulary.statusesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        headerButtons={
          <ImportExcelButton
            onClickImportButton={() => {
              setOpenImportExcel(true);
              setShouldUpdate(false);
            }}
          />
        }
      />
      {open ? (
        <Status
          editStatus={editItem}
          open={open}
          addOrUpdateStatus={(status) => addOrUpdateStatus(status)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {openImportExcel ? (
        <ImportExcelModal
          open={openImportExcel}
          onClose={() => setOpenImportExcel(false)}
          saveItems={(items: any) => saveToDatabase(items)}
          description={description}
        />
      ) : null}
    </div>
  );
}
