/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext, useEffect, useState } from "react";
import { handleChangeAutocomplete } from "../../Utils/Utils";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { EmailTemplateModel } from "../../Utils/Models";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import { postData } from "../../Services/postData";
import { WebsocketContext } from "../../Contexts/WebsocketContext";

export default function EmailSettings() {
  const websocketContext = useContext(WebsocketContext);
  const [templates, setTemplates] = useState<Array<EmailTemplateModel>>([]);
  const [emailSettings, setEmailSettings] = useState({
    name: "",
    email: "",
    defaultSubject: "",
    defaultTemplate: null,
    logistics: "",
    managers: "",
    invoicing: "",
    orders: "",
  });

  /**
   *
   */
  useEffect(() => {
    getEmailSettings();
    getTemplates();
  }, []);

  /**
   *
   */
  function getEmailSettings() {
    getData(urlEnum.emailSettings).then((response) => {
      if (response && response.data && response.data.result)
        setEmailSettings(response.data.result);
    });
  }
  /**
   *
   */
  function getTemplates() {
    getData(urlEnum.emailTemplates).then((response) => {
      if (response && response.data && response.data.result)
        setTemplates(response.data.result);
    });
  }

  /**
   *
   */
  function addOrUpdateEmailSettings() {
    postData(urlEnum.emailSettings, emailSettings).then((response) => {
      if (response && response.data && response.data.result) {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        setEmailSettings(response.data.result);
      }
    });
  }

  return (
    <Grid container spacing={4} style={{ marginTop: 10 }}>
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          size="small"
          name="name"
          label={Vocabulary.name}
          variant="standard"
          value={emailSettings.name}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, name: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          size="small"
          name="email"
          label={Vocabulary.email}
          variant="standard"
          value={emailSettings.email}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, email: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          size="small"
          name="defaultSubject"
          label={Vocabulary.defaultSubject}
          variant="standard"
          value={emailSettings.defaultSubject}
          onChange={(e) =>
            setEmailSettings({
              ...emailSettings,
              defaultSubject: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Autocomplete
          size="small"
          id="defaultTemplate"
          freeSolo={false}
          disablePortal
          getOptionLabel={(option: any) => option.name}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          options={templates}
          value={emailSettings.defaultTemplate}
          onChange={(event: any, value: any) => {
            setEmailSettings(
              handleChangeAutocomplete(
                event,
                value,
                emailSettings,
                "defaultTemplate"
              )
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="defaultTemplate"
              label={Vocabulary.defaultTemplate}
              variant="standard"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DividerWithLabel
          label={Vocabulary.emailLists}
          styles={{ marginTop: 10, marginBottom: -20, width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <TextField
          size="small"
          name="logistics"
          label={Vocabulary.logistics}
          variant="standard"
          value={emailSettings.logistics}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, logistics: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <TextField
          size="small"
          name="managers"
          label={Vocabulary.managers}
          variant="standard"
          value={emailSettings.managers}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, managers: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <TextField
          size="small"
          name="invoicing"
          label={Vocabulary.invoicing}
          variant="standard"
          value={emailSettings.invoicing}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, invoicing: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <TextField
          size="small"
          name="orders"
          label={Vocabulary.orders}
          variant="standard"
          value={emailSettings.orders}
          onChange={(e) =>
            setEmailSettings({ ...emailSettings, orders: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={addOrUpdateEmailSettings}
        >
          {Vocabulary.saveSettings}
        </Button>
      </Grid>
    </Grid>
  );
}
