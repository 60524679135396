/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useState } from "react";
import { DeviceModel, ImportExcelDescription } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { postData } from "../Services/postData";
import Device from "../Components/Devices/Device";
import moment from "moment";
import Config from "../Utils/Config";
import { useMediaQuery } from "@mui/material";
import ImportExcelModal from "../Components/GenericComponents/ImportExcelModal";
import ImportExcelButton from "../Components/GenericComponents/ImportExcelButton";

export default function Devices() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [open, setOpen] = useState(false);
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [editItem, setEditItem] = useState<DeviceModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  /**
   *
   */
  const description: Array<ImportExcelDescription> = [
    {
      name: "Name",
      description: Vocabulary.itemName,
      example: "iPhone 13 Pro Max",
    },
    {
      name: "Serial number",
      description: Vocabulary.itemSerialNumber,
      example: "SN1234567890",
    },
    {
      name: "IMEI number 1",
      description: Vocabulary.itemImeiNumber1,
      example: "358240051111110",
    },
    {
      name: "Configuration",
      description: Vocabulary.itemConfiguration,
      example: "128GB, 6GB RAM",
    },
    {
      name: "Code",
      description: Vocabulary.itemCode,
      example: "DEV00123",
    },
    {
      name: "Family",
      description: Vocabulary.itemDeviceFamily,
      example: "Smartphone",
    },
    {
      name: "Brand",
      description: Vocabulary.itemBrand,
      example: "Apple",
    },
    {
      name: "Model",
      description: Vocabulary.itemModel,
      example: "iPhone 13 Pro Max",
    },
    {
      name: "Distributor",
      description: Vocabulary.itemDistributor,
      example: "ABC Electronics",
    },
    {
      name: "Purchase country",
      description: Vocabulary.itemPurchaseCountry,
      example: "United States",
    },
    {
      name: "Tags",
      description: Vocabulary.itemTags,
      example: "Smartphone, Apple, iOS",
    },
  ];

  /**
   *
   */
  const deviceHeader = [
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.serialNumber,
      name: "serialNumber",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.imeiNumber1,
      name: "imeiNumber1",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.code,
      name: "code",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.deviceFamily,
      name: "family",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.brand,
      name: "brand",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.model,
      name: "model",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.distributor,
      name: "distributor",
      options: {
        sort: false,
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: DeviceModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateDevice(device: DeviceModel) {
    device.purchaseDate = moment(device.purchaseDate).format(
      Config.momentUSDateFormat
    );
    setShouldUpdate(true);
    if (device?._id) {
      updateData(`${urlEnum.devices}/${device._id}`, device)
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.devices, device)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  /**
   *
   * @param items
   */
  async function saveToDatabase(items: any) {
    const res = await postData(`${urlEnum.devices}/insertMany`, items);
    if (res && res.data && res.data.result) {
      setOpenImportExcel(false);
      setShouldUpdate(true);
    }
  }
  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.devices}
        titleDelete={Vocabulary.deleteDevice}
        textDelete={Vocabulary.deleteConfirmationDevice}
        header={deviceHeader}
        tableTitle={Vocabulary.devicesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        headerButtons={
          <ImportExcelButton
            onClickImportButton={() => {
              setOpenImportExcel(true);
              setShouldUpdate(false);
            }}
          />
        }
      />
      {open ? (
        <Device
          editDevice={editItem}
          open={open}
          addOrUpdateDevice={(device) => addOrUpdateDevice(device)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {openImportExcel ? (
        <ImportExcelModal
          open={openImportExcel}
          onClose={() => setOpenImportExcel(false)}
          saveItems={(items: any) => saveToDatabase(items)}
          description={description}
        />
      ) : null}
    </div>
  );
}
