/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Grid, TextField } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { LocationModel } from "../../Utils/Models";
import { handleChangeAutocomplete, onChangeTextField } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";

type LocationProps = {
  location: LocationModel;
  deliveries: any;
  handleChangeLocation: (product: LocationModel) => void;
};
export default function Location(props: LocationProps) {
  const { location, deliveries, handleChangeLocation } = props;
  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="name"
          id="name"
          label={Vocabulary.name}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.name}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="email"
          id="email"
          label={Vocabulary.email}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.email}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="phone"
          id="phone"
          label={Vocabulary.phone}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.phone}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="country"
          id="country"
          label={Vocabulary.country}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.country}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="address"
          id="address"
          label={Vocabulary.address}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.address}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="zip"
          id="zip"
          label={Vocabulary.zip}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.zip}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="state"
          id="state"
          label={Vocabulary.state}
          fullWidth
          value={location?.state}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="city"
          id="city"
          label={Vocabulary.city}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.city}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="tat"
          id="tat"
          label={Vocabulary.tat}
          fullWidth
          type="number"
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.tat}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="longitude"
          id="longitude"
          label={Vocabulary.longitude}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.longitude}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          variant="outlined"
          name="latitude"
          id="latitude"
          label={Vocabulary.latitude}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={location?.latitude}
          onChange={(event) =>
            handleChangeLocation(onChangeTextField(event, location))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Autocomplete
          size="small"
          id={"delivers"}
          multiple
          freeSolo={false}
          disablePortal
          options={deliveries || []}
          value={location?.deliveries || []}
          getOptionLabel={(option: any) => option.name}
          isOptionEqualToValue={(option: any, value: any) =>
            option.name === value.name
          }
          getOptionDisabled={(option: any) =>
            !!(
              location?.deliveries &&
              location?.deliveries.find(
                (element: any) => element?.name === option?.name
              )
            )
          }
          onChange={(event: any, value: any) =>
            handleChangeLocation(
              handleChangeAutocomplete(event, value, location, "deliveries")
            )
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label={Vocabulary.deliveries}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
