/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { handleChangeAutocomplete } from "../../Utils/Utils";
import { CheckInPagesProps } from "./CheckInCustomer";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";

export default function CheckInWorkflow(props: CheckInPagesProps) {
  const { checkInOrder, handleChangeCheckInOrder } = props;
  const [workflows, setWorkflows] = useState([]);

  /**
   *
   */
  useEffect(() => {
    getWorkflows();
  }, []);

  /**
   *
   */
  function getWorkflows() {
    getData(urlEnum.workflows).then((res: any) => {
      if (res.data.status === 200) setWorkflows(res.data.result);
    });
  }

  return (
    <div style={{ margin: "10px 0px" }}>
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 400,
          marginBottom: 15,
          textAlign: "center",
        }}
      >
        {Vocabulary.chooseWorkflow}
      </Typography>
      <Autocomplete
        size="small"
        id="workflow"
        freeSolo={false}
        disablePortal
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option: any, value: any) =>
          option._id === value._id
        }
        options={workflows}
        value={checkInOrder.workflow}
        onChange={(event: any, value: any) => {
          handleChangeCheckInOrder(
            handleChangeAutocomplete(event, value, checkInOrder, "workflow")
          );
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            name="workflow"
            label={Vocabulary.workflow}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
