/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip, Tab, Tabs, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../Styles/dashboard.module.css";
import { localUrlEnum } from "../../Utils/UrlEnum";
import { otherReports } from "../../Utils/AutocompleteUtils";
import theme from "../../Themes/Theme";
import { OtherReport } from "./OtherReport";
import Filters from "../../Components/Reports/Filters";
import { FiltersModel } from "../../Utils/Models";
import moment from "moment";
import { dayPilotFormat } from "../../Utils/Config";

export default function OtherReportsMainPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [value, setValue] = useState(0);
  const [filters, setFilters] = useState<FiltersModel>({
    type: otherReports.technicianEfficiency,
    startDate: moment().startOf("year"),
    endDate: moment().date(25),
  });

  /**
   *
   */
  const TechnicianEfficiencyHeader = [
    {
      label: Vocabulary.firstName,
      name: "firstName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.lastName,
      name: "lastName",
      options: {
        sort: false,
      },
    },

    {
      label: Vocabulary.openOrdersCount,
      name: "openOrders",
      options: {
        sort: false,
        customBodyRender: (openOrders: any) => {
          return <>{openOrders ? openOrders.orderCount : ""}</>;
        },
      },
    },
    {
      label: Vocabulary.openOrders,
      name: "openOrders",
      options: {
        sort: false,
        customBodyRender: (openOrders: any) => {
          return (
            openOrders &&
            openOrders.orders.map((order: any, index: number) => {
              if (index <= 10)
                return (
                  <a
                    href={`${localUrlEnum.orders}/${order.orderId}`}
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <Chip
                      key={index}
                      label={order.orderNumber}
                      style={{ marginRight: "7px", marginBottom: "7px" }}
                    />
                  </a>
                );
              return null;
            })
          );
        },
      },
    },
    {
      label: Vocabulary.closedOrdersCount,
      name: "closedOrders",
      options: {
        sort: false,
        customBodyRender: (closedOrders: any) => {
          return <>{closedOrders ? closedOrders.orderCount : ""}</>;
        },
      },
    },
    {
      label: Vocabulary.closedOrders,
      name: "closedOrders",
      options: {
        sort: false,
        customBodyRender: (closedOrders: any) => {
          return (
            closedOrders &&
            closedOrders.orders.map((order: any, index: number) => {
              if (index <= 10)
                return (
                  <a
                    href={`${localUrlEnum.orders}/${order.orderId}`}
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <Chip
                      key={index}
                      label={order.orderNumber}
                      style={{ marginRight: "7px", marginBottom: "7px" }}
                    />
                  </a>
                );
              return null;
            })
          );
        },
      },
    },
  ];

  /**
   *
   */
  const DeviceReportHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.orderCount,
      name: "orderCount",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.orders,
      name: "orders",
      options: {
        sort: false,
        customBodyRender: (orders: any) => {
          return (
            orders &&
            orders.map((order: any, index: number) => {
              if (index <= 10)
                return (
                  <a
                    href={`${localUrlEnum.orders}/${order.orderId}`}
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <Chip
                      key={index}
                      label={order.orderNumber}
                      style={{ marginRight: "7px", marginBottom: "7px" }}
                    />
                  </a>
                );
              return null;
            })
          );
        },
      },
    },
  ];

  /**
   *
   */
  const ProductReportHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.code,
      name: "code",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.orderCount,
      name: "orderCount",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.orders,
      name: "orders",
      options: {
        sort: false,
        customBodyRender: (orders: any) => {
          return (
            orders &&
            orders.map((order: any, index: number) => {
              if (index <= 10)
                return (
                  <a
                    href={`${localUrlEnum.orders}/${order.orderId}`}
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <Chip
                      key={index}
                      label={order.orderNumber}
                      style={{ marginRight: "7px", marginBottom: "7px" }}
                    />
                  </a>
                );
              return null;
            })
          );
        },
      },
    },
  ];

  /**
   *
   */
  useEffect(() => {
    let search: any = decodeURIComponent(location.search);
    search = search.split(/[?&]+/);
    const newFilters: any = {
      type: otherReports.technicianEfficiency,
      startDate: moment().startOf("year"),
      endDate: moment().date(25),
    };
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    setValueFromLocation(newFilters.type);
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
  }, [location]);

  /**
   *
   * @param newValue
   */
  function setValueFromLocation(newValue: string) {
    switch (newValue) {
      case otherReports.technicianEfficiency:
        setValue(0);
        break;
      case otherReports.devicesReports:
        setValue(1);
        break;
      case otherReports.productsReports:
        setValue(2);
        break;
      default:
        setValue(0);
        break;
    }
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    switch (newValue) {
      case 0:
        navigate(
          `${localUrlEnum.otherReports}?type=${otherReports.technicianEfficiency}`
        );
        break;
      case 1:
        navigate(
          `${localUrlEnum.otherReports}?type=${otherReports.devicesReports}`
        );
        break;
      case 2:
        navigate(
          `${localUrlEnum.otherReports}?type=${otherReports.productsReports}`
        );
        break;
      default:
        navigate(
          `${localUrlEnum.otherReports}?type=${otherReports.technicianEfficiency}`
        );
        break;
    }
  }

  /**
   *
   */
  function showInformationForTabs() {
    switch (value) {
      case 0:
        return <OtherReport header={TechnicianEfficiencyHeader} />;
      case 1:
        return <OtherReport header={DeviceReportHeader} />;
      case 2:
        return <OtherReport header={ProductReportHeader} />;
      default:
        return <OtherReport header={TechnicianEfficiencyHeader} />;
    }
  }

  /**
   *
   * @param tabValue
   * @returns
   */
  function getTabStyle(tabValue: number) {
    return {
      backgroundColor:
        value === tabValue
          ? theme.palette.secondary.main
          : theme.palette.cancel?.main,
      color: value === tabValue ? "white" : "#9c9b9b",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      marginRight: "1px",
    };
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <Filters filters={filters} recalculateReportsButton={false} />
      <Tabs
        value={value}
        onChange={handleChangeValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <Tab label={Vocabulary.technicianEfficiency} style={getTabStyle(0)} />
        <Tab label={Vocabulary.devicesReports} style={getTabStyle(1)} />
        <Tab label={Vocabulary.productsReports} style={getTabStyle(2)} />
      </Tabs>
      <> {showInformationForTabs()}</>
    </div>
  );
}
