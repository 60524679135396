import { Button, Chip, Collapse, Grid } from "@mui/material";
import { notesType } from "../../Utils/AutocompleteUtils";
import { useContext, useMemo, useState } from "react";
import ShowImportantNotesForInformation from "./ShowImportantNotesForInformation";
import { Vocabulary } from "../../Utils/Vocabulary";
import moment from "moment";
import { OrderContext } from "../../Contexts/OrderContext";
import ExpendButtons from "../GenericComponents/ExpendButtons";
import { euFormat } from "../../Utils/Config";

export default function OrderInformation() {
  const orderContext = useContext(OrderContext);
  const [showMore, setShowMore] = useState(false);
  const issueNotes = useMemo(() => {
    return orderContext.order.notes.filter(
      (note) => note.type === notesType.issue
    )[0];
  }, [orderContext.order.notes]);
  const diagnosisNotes = useMemo(() => {
    return orderContext.order.notes.filter(
      (note) => note.type === notesType.diagnosis
    )[0];
  }, [orderContext.order.notes]);
  const resolutionNotes = useMemo(() => {
    return orderContext.order.notes.filter(
      (note) => note.type === notesType.resolution
    )[0];
  }, [orderContext.order.notes]);
  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: 70 }}>
        <Grid item xs={12} md={6} lg={6}>
          {orderContext.order.isDraft ? (
            <Chip
              label={Vocabulary.draft}
              size="small"
              style={{
                color: "white",
                backgroundColor: "#5bc0de",
                borderRadius: 5,
                padding: 0,
                marginRight: 10,
                fontWeight: "bold",
              }}
            ></Chip>
          ) : null}
          <ShowImportantNotesForInformation
            note={issueNotes}
            backgroundColor="#333"
            doesNotExistsMessage={Vocabulary.noIssue}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 23, marginRight: 10 }}>
              {orderContext.order.totalRemaining !== 0 ? (
                <span style={{ color: "#ca3434" }}>
                  {Vocabulary.stillToBePaid} {orderContext.order.totalRemaining}
                </span>
              ) : (
                <span style={{ color: "#8a6d3b" }}>
                  {Vocabulary.nothingToBill}
                </span>
              )}
            </p>
            <Button
              onClick={() => setShowMore(!showMore)}
              size="small"
              style={{
                backgroundColor: "#333",
                color: "white",
                borderRadius: "50%",
                minWidth: "auto",
                height: 31,
                verticalAlign: "middle",
              }}
            >
              <ExpendButtons
                showMore={showMore}
                handleChangeShowMore={() => null}
                style={null}
              />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Collapse in={showMore}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <ShowImportantNotesForInformation
                  note={diagnosisNotes}
                  backgroundColor="#5bc0de"
                  doesNotExistsMessage={Vocabulary.noDiagnosis}
                />
                <ShowImportantNotesForInformation
                  note={resolutionNotes}
                  backgroundColor="#3f903f"
                  doesNotExistsMessage={Vocabulary.noResolution}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ textAlign: "right" }}>
                <p>
                  {Vocabulary.billed} {orderContext.order.totalCharged}
                </p>
                <p>
                  {Vocabulary.orderTotal} {orderContext.order.total}
                </p>
                <br />
                <p>
                  {Vocabulary.orderCreated}{" "}
                  {moment(orderContext.order.createdAt).format(euFormat)}
                </p>
                <p>
                  {orderContext.order.orderedBy ? (
                    <>
                      {Vocabulary.orderedBy}{" "}
                      {orderContext.order.orderedBy?.firstName}{" "}
                      {orderContext.order.orderedBy?.lastName}
                    </>
                  ) : null}
                </p>
                <p>
                  {orderContext.order.handledBy ? (
                    <>
                      {Vocabulary.handledBy}{" "}
                      {orderContext.order.handledBy?.firstName}{" "}
                      {orderContext.order.handledBy?.lastName}
                    </>
                  ) : null}
                </p>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
}
