/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MainTable from "../../../GenericComponents/MainTable";
import { urlEnum } from "../../../../Utils/UrlEnum";
import styles from "../../../../Styles/dashboard.module.css";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { styleForTableWithTabs } from "../../../../Pages/Users";
import { Chip } from "@mui/material";
import moment from "moment";
import { euFormatForDateTime } from "../../../../Utils/Config";
import { useState } from "react";
import { getData } from "../../../../Services/getData";
import ShowPurchaseOrder from "../PurchaseOrders/ShowPurchaseOrder";
import {
  ShowGoodsType,
  stocksManagementTypes,
} from "../../../../Utils/AutocompleteUtils";
import { ShowInventoryInfo } from "./ShowInventoryInfo";
import ShowProductInfo from "./ShowProductInfo";

export default function Goods({ type }: { type: string }) {
  const [open, setOpen] = useState({
    purchaseOrder: false,
    inventory: false,
    product: false,
  });
  const [purchaseOrder, setPurchaseOrder] = useState<any>(null);

  /**
   *
   */
  const purchaseOrdersHeader = [
    {
      label: "",
      name: "_id",
      options: {
        display: "excluded", // Exclude this column from the table display
      },
    },
    {
      label: Vocabulary.purchaseOrderNumber,
      name: "purchaseOrderNumber",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          const id = meta.rowData[1];
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                getPurchaseOrder(id, ShowGoodsType.purchaseOrder, null);
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      label: Vocabulary.referenceNumber,
      name: "referenceNumber",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.inventory} / ${Vocabulary.location}`,
      name: "inventory",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          const id = meta.rowData[1];
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                getPurchaseOrder(id, ShowGoodsType.inventory, null);
              }}
            >
              {value?.name} <br /> {value?.location?.name}
            </div>
          );
        },
      },
    },
    {
      label: Vocabulary.product,
      name: "product",
      options: {
        sort: false,
        customBodyRender: (product: any, meta: any) => {
          const id = meta.rowData[1];
          return (
            <>
              <Chip
                label={`${product.code} - ${
                  product.name.length > 7
                    ? `${product.name.substring(0, 7)}...`
                    : product.name
                }`}
                style={{
                  margin: 5,
                  backgroundColor: "#404a694d",
                  cursor: "pointer",
                }}
                onClick={() => {
                  getPurchaseOrder(id, ShowGoodsType.product, product._id);
                }}
              />
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.quantity,
      name: "product",
      options: {
        sort: false,
        customBodyRender: (product: any) => {
          return <>{product?.quantity}</>;
        },
      },
    },
    {
      label: Vocabulary.manufacturer,
      name: "product",
      options: {
        sort: false,
        customBodyRender: (product: any) => {
          return <>{product?.manufacturer}</>;
        },
      },
    },
    {
      label: Vocabulary.vendor,
      name: "vendor",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value?.company}</>;
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];

  /**
   *
   */
  function getPurchaseOrder(
    _id: number,
    type: string,
    productId: string | null
  ) {
    switch (type) {
      case ShowGoodsType.purchaseOrder:
        setOpen({ purchaseOrder: true, inventory: false, product: false });
        break;
      case ShowGoodsType.inventory:
        setOpen({ purchaseOrder: false, inventory: true, product: false });
        break;
      case ShowGoodsType.product:
        setOpen({ purchaseOrder: false, inventory: false, product: true });
        break;
      default:
        break;
    }
    getData(`${urlEnum.purchaseOrders}/${_id}`).then((res) => {
      if (res) {
        const purchaseOrder = res.data.result;
        if (productId)
          purchaseOrder.products = purchaseOrder.products.find(
            (product: any) => product.product._id === productId
          );
        setPurchaseOrder(purchaseOrder);
      }
    });
  }

  /**
   *
   */
  function onClose() {
    setOpen({ purchaseOrder: false, inventory: false, product: false });
    setPurchaseOrder(null);
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={`${urlEnum.purchaseOrders}/products`}
        titleDelete={""}
        textDelete={""}
        header={purchaseOrdersHeader}
        tableTitle={
          type === stocksManagementTypes.incomingGoods
            ? Vocabulary.incomingGoods
            : Vocabulary.receivedGoods
        }
        hideOptionButtons={true}
        hideAddButton={true}
        style={styleForTableWithTabs}
      />
      {open.purchaseOrder && purchaseOrder ? (
        <ShowPurchaseOrder
          editPurchaseOrder={purchaseOrder}
          open={open.purchaseOrder}
          onClose={onClose}
          viewOnly={true}
        />
      ) : null}
      {open.inventory && purchaseOrder ? (
        <ShowInventoryInfo
          inventory={purchaseOrder.inventory}
          open={open.inventory}
          onClose={onClose}
        />
      ) : null}
      {open.product && purchaseOrder ? (
        <ShowProductInfo
          product={purchaseOrder.products}
          open={open.product}
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}
