/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useState } from "react";
import { DeliveryModel, ImportExcelDescription } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { postData } from "../Services/postData";
import Delivery from "../Components/Deliveries/Delivery";
import { useMediaQuery } from "@mui/material";
import ImportExcelModal from "../Components/GenericComponents/ImportExcelModal";
import ImportExcelButton from "../Components/GenericComponents/ImportExcelButton";

export default function Deliveries() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [open, setOpen] = useState(false);
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [editItem, setEditItem] = useState<DeliveryModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  /**
   *
   */
  const description: Array<ImportExcelDescription> = [
    {
      name: "Name",
      description: Vocabulary.itemName,
      example: "Apple MacBook Pro 16-inch",
    },
    {
      name: "Short name",
      description: Vocabulary.itemShortName,
      example: "MacBook Pro 16",
    },
    {
      name: "Description",
      description: Vocabulary.itemDescription,
      example:
        "A high-performance laptop designed by Apple, featuring a 16-inch Retina display and advanced hardware for professionals.",
    },
  ];
  /**
   *
   */
  const deliveriesHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: { sort: false },
    },
    {
      label: Vocabulary.shortName,
      name: "shortName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.description,
      name: "description",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value && value.length > 40
                ? `${value.substring(0, 40)}...`
                : value}
            </>
          );
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: DeliveryModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateDelivery(delivery: DeliveryModel | null) {
    setShouldUpdate(true);
    if (delivery?._id) {
      updateData(`${urlEnum.deliveries}/${delivery._id}`, delivery)
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.deliveries, delivery)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  /**
   *
   * @param items
   */
  async function saveToDatabase(items: any) {
    const res = await postData(`${urlEnum.deliveries}/insertMany`, items);
    if (res && res.data && res.data.result) {
      setOpenImportExcel(false);
      setShouldUpdate(true);
    }
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.deliveries}
        titleDelete={Vocabulary.deleteDelivery}
        textDelete={Vocabulary.deleteConfirmationDelivery}
        header={deliveriesHeader}
        tableTitle={Vocabulary.deliveriesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        headerButtons={
          <ImportExcelButton
            onClickImportButton={() => {
              setOpenImportExcel(true);
              setShouldUpdate(false);
            }}
          />
        }
      />
      {open ? (
        <Delivery
          editDelivery={editItem}
          open={open}
          addOrUpdateDelivery={(delivery) => addOrUpdateDelivery(delivery)}
          onClose={() => setOpen(false)}
        />
      ) : null}
      {openImportExcel ? (
        <ImportExcelModal
          open={openImportExcel}
          onClose={() => setOpenImportExcel(false)}
          saveItems={(items: any) => saveToDatabase(items)}
          description={description}
        />
      ) : null}
    </div>
  );
}
