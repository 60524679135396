/* eslint-disable @typescript-eslint/no-explicit-any */
import { urlEnum } from "../../Utils/UrlEnum";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import styles from "../../Styles/dashboard.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { dayPilotFormat, euFormatForDateTime } from "../../Utils/Config";
import { updateData } from "../../Services/updateData";
import Filters from "../../Components/Reports/Filters";
import MainTable from "../../Components/GenericComponents/MainTable";
import GenericConfirmModal from "../../Components/GenericComponents/GenericConfirmModal";
import { FiltersModel } from "../../Utils/Models";

export default function TrashedOrders() {
  //   const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [restoreId, setRestoreId] = useState<number | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [filters, setFilters] = useState<FiltersModel>({
    startDate: moment().startOf("year"),
    endDate: moment().date(25),
    user: [],
    customer: [],
    orderStatus: null,
    device: [],
    workflow: null,
    workflowStatus: null,
  });

  const trashedOrdersHeaderMobile = [
    {
      label: Vocabulary.orderReference,
      name: "fixablyId",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.workflow,
      name: "workflow",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value?.name || ""}</>;
        },
      },
    },
  ];
  const trashedOrdersHeader = [
    {
      label: Vocabulary.orderReference,
      name: "fixablyId",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.priority,
      name: "priority",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.device,
      name: "device",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value?.name || ""}</>;
        },
      },
    },
    {
      label: Vocabulary.workflow,
      name: "workflow",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value?.name || ""}</>;
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: true,
        customBodyRender: (value: any) => {
          return <>{value?.name || ""}</>;
        },
      },
    },
    {
      label: Vocabulary.user,
      name: "handledBy",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value ? `${value.firstName} ${value.lastName}` : ""}</>;
        },
      },
    },
    {
      label: Vocabulary.total,
      name: "total",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: true,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];

  /**
   *
   */
  useEffect(() => {
    let search: any = decodeURIComponent(location.search);
    search = search.split(/[?&]+/);
    const newFilters: any = {
      startDate: moment().startOf("year"),
      endDate: moment().date(25),
      user: [],
      customer: [],
      orderStatus: null,
      device: [],
      workflow: null,
      workflowStatus: null,
    };
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[0] === "user") newFilters[values[0]] = values[1].split(",");
        if (values[0] === "customer")
          newFilters[values[0]] = values[1].split(",");
        if (values[0] === "device")
          newFilters[values[0]] = values[1].split(",");
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param id
   */
  function handleRestore(id: number) {
    setShouldUpdate(false);
    setOpenRestoreModal(true);
    setRestoreId(id);
  }

  /**
   *
   */
  function restoreOrder() {
    updateData(`${urlEnum.trashedOrders}/restore/${restoreId}`).then(() => {
      setShouldUpdate(true);
    });
  }

  return (
    <>
      <div
        className={
          phoneStyle ? styles.mainContainerMobile : styles.mainContainer
        }
      >
        <Filters
          filters={filters}
          recalculateReportsButton={false}
          orderStatus={{
            show: true,
            multiple: false,
            disableClearable: false,
          }}
          user={{
            show: true,
            multiple: true,
            disableClearable: false,
          }}
          customer={{
            show: true,
            multiple: true,
            disableClearable: false,
          }}
          device={{
            show: true,
            multiple: true,
            disableClearable: false,
          }}
          workflow={{
            show: true,
            multiple: false,
            disableClearable: false,
          }}
          workflowStatus={{
            show: true,
            multiple: false,
            disableClearable: false,
          }}
        />
        <MainTable
          urlEnumApi={urlEnum.trashedOrders}
          titleDelete={Vocabulary.deleteTrashedOrder}
          textDelete={Vocabulary.deleteConfirmationTrashedOrders}
          header={phoneStyle ? trashedOrdersHeaderMobile : trashedOrdersHeader}
          tableTitle={Vocabulary.trashedOrdersList}
          enableSort={true}
          shouldUpdate={shouldUpdate}
          hideAddButton={true}
          handleRestore={handleRestore}
        />
      </div>
      {openRestoreModal ? (
        <GenericConfirmModal
          title={Vocabulary.restoreOrder}
          text={Vocabulary.restoreConfirmationOrder}
          openDeleteModal={openRestoreModal}
          onClose={() => setOpenRestoreModal(false)}
          deleteItem={() => {
            restoreOrder();
            setOpenRestoreModal(false);
          }}
        />
      ) : null}
    </>
  );
}
