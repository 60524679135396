/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Tab, Tabs } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CategoryModel, ProductModel } from "../../Utils/Models";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import Product from "./Product";
import Price from "./Price";
import AdditionalInfo from "./AdditionalInfo";
import VariantsAndAttributes from "./VariantsAndAttributes";
import { urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import { Delete } from "@mui/icons-material";
import ProductStock from "./ProductStock";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type ProductMainPageProps = {
  editProduct: ProductModel | null;
  open: boolean;
  addOrUpdateProduct: (product: ProductModel) => void;
  postOnShopify?: (product: ProductModel) => void;
  onClose: () => void;
  deleteProductOnShopify?: (id: string | null) => void;
};
export default function ProductMainPage(props: ProductMainPageProps) {
  const {
    editProduct,
    open,
    addOrUpdateProduct,
    postOnShopify,
    onClose,
    deleteProductOnShopify,
  } = props;
  const ref: any = useRef();
  const cache = useCustomMemo();
  const taxRates = cache.taxRates || [];
  const [isProductDirty, setIsProductDirty] = useState(false);
  const [product, setProduct] = useState<ProductModel>(new ProductModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [value, setValue] = useState(0);
  const [arraysOfString, setArraysOfString] = useState<any>({
    tags: [],
  });
  const [categories, setCategories] = useState<Array<CategoryModel>>([]);

  /**
   *
   */
  useEffect(() => {
    if (editProduct) {
      setProduct(editProduct);
      setIsProductDirty(false);
    } else {
      const newProduct = new ProductModel();
      const taxRate = taxRates && taxRates.find((x: any) => x.defaultValue);
      newProduct.vat = taxRate ? taxRate.value : "0";
      setProduct(newProduct);
      setIsProductDirty(false);
    }
    getCategories();
    getArraysOfString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProduct]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  /**
   *
   * @param value
   */
  function handleChangeProduct(value: ProductModel) {
    setProduct(value);
    setIsProductDirty(true);
  }

  /**
   *
   */
  function getCategories() {
    getData(`${urlEnum.categories}`).then((res: any) => {
      if (res && res.data && res.data.result) {
        setCategories(res.data.result);
      }
    });
  }

  /**
   *
   */
  function getArraysOfString() {
    const url = `${urlEnum.products}/arraysOfString`;
    getData(url).then((res: any) => {
      if (res.data.status === 200) setArraysOfString(res.data.result);
    });
  }

  /**
   *
   * @param arraysOfString
   */
  function handleChangeArraysOfString(arraysOfString: any) {
    setArraysOfString(arraysOfString);
  }
  /**
   *
   */
  function showInformation() {
    switch (value) {
      case 0:
        return (
          <Product
            product={product}
            arraysOfString={arraysOfString}
            categories={categories}
            handleChangeProduct={handleChangeProduct}
            handleChangeArraysOfString={handleChangeArraysOfString}
          />
        );
      case 1:
        return (
          <VariantsAndAttributes
            product={product}
            handleChangeProduct={handleChangeProduct}
          />
        );
      case 2:
        return (
          <ProductStock
            product={product}
            handleChangeProduct={handleChangeProduct}
          />
        );
      case 3:
        return (
          <Price product={product} handleChangeProduct={handleChangeProduct} />
        );
      case 4:
        return (
          <AdditionalInfo
            product={product}
            handleChangeProduct={handleChangeProduct}
          />
        );
      default:
        break;
    }
  }

  /**
   * Show shopify information
   */
  function showShopifyInformation() {
    if (editProduct && editProduct.shopifyId) {
      return (
        <>
          <p style={{ fontWeight: 200 }}>
            {Vocabulary.shopifyId}: &nbsp;
            <span style={{ fontWeight: 400 }}>{editProduct.shopifyId}</span>
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 10,
            }}
          >
            <Button
              variant="outlined"
              style={{ flex: 1 }}
              color="primary"
              disabled={isProductDirty}
              onClick={() => {
                if (postOnShopify) postOnShopify(product);
              }}
            >
              {Vocabulary.updateProductOnShopify}
            </Button>
            <Button
              variant="outlined"
              style={{ flex: 1, marginLeft: 10 }}
              onClick={() => {
                if (deleteProductOnShopify) deleteProductOnShopify(product._id);
              }}
            >
              <Delete style={{ marginRight: 10 }} />
              {Vocabulary.deleteFromShopify}
            </Button>
          </div>
        </>
      );
    } else if (editProduct && editProduct._id && postOnShopify)
      return (
        <Button
          variant="outlined"
          style={{ marginBottom: 10, marginTop: 20 }}
          fullWidth
          color="primary"
          disabled={isProductDirty}
          onClick={() => {
            if (postOnShopify) postOnShopify(product);
          }}
        >
          {Vocabulary.postOnShopify}
        </Button>
      );
    return null;
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateProduct(product)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.product}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
        submitButtonDisabled={
          product.options.find(
            (option) =>
              option.name === "" &&
              option.values.length === 1 &&
              option.values[0] === ""
          )
            ? true
            : false
        }
      >
        <Tabs
          value={value}
          onChange={handleChangeValue}
          textColor="inherit"
          sx={{ width: "100%" }}
        >
          <Tab label={Vocabulary.product} />
          <Tab label={Vocabulary.variantsAndAttributes} />
          <Tab label={Vocabulary.stock} />
          <Tab label={Vocabulary.price} />
          <Tab label={Vocabulary.additionalData} />
        </Tabs>
        {showInformation()}
        {showShopifyInformation()}
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
