/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BuildIcon from "@mui/icons-material/Build";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FeedIcon from "@mui/icons-material/Feed";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  handleChangeAutocomplete,
  onChangeTextField,
  setStatuses,
} from "../../Utils/Utils";
import { internalLocationArray } from "../../Utils/AutocompleteUtils";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import { useContext, useEffect, useState } from "react";
import { urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import { OrderContext } from "../../Contexts/OrderContext";
import styles from "../../Styles/order.module.css";
import { styled } from "@mui/system";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: "black",
  fontWeight: "bold",
  backgroundColor: "white",
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function GeneralInformation() {
  const orderContext = useContext(OrderContext);
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [tags, setTags] = useState<string[]>([]);
  const cache = useCustomMemo();
  const statuses =
    setStatuses(
      cache.statuses || [],
      cache.workflows || [],
      (orderContext.order.workflow && orderContext.order.workflow?._id) || null
    ) || [];
  const users = cache.users || [];
  const locations = cache.locations || [];

  useEffect(() => {
    getData(`${urlEnum.orders}/getTags`).then((res: any) => {
      if (res.data.status === 200) setTags(res.data.result);
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <div
          className={
            phoneStyle
              ? styles.mainDivGeneralInfoMobile
              : styles.mainDivGeneralInfo
          }
        >
          <Typography style={phoneStyle ? { marginBottom: 5 } : {}}>
            <BookmarkAddedIcon
              fontSize="small"
              style={{
                marginRight: 10,
                verticalAlign: "middle",
              }}
            />
            {Vocabulary.status}
          </Typography>
          {orderContext.order.workflow ? (
            <Autocomplete
              size="small"
              id="status"
              freeSolo={false}
              disablePortal
              options={statuses}
              groupBy={(option) =>
                option.type ? Vocabulary[option.type] : "General"
              }
              value={orderContext.order.status || null}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              onChange={(event: any, value: any) => {
                orderContext.setOrder(
                  handleChangeAutocomplete(
                    event,
                    value,
                    orderContext.order,
                    "status"
                  )
                );
              }}
              renderInput={(params: any) => (
                <TextField {...params} size="small" variant="standard" />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              style={{ minWidth: 250 }}
            />
          ) : (
            <Typography style={{ minWidth: 250 }}>
              {Vocabulary.chooseWorkflowFirst}
            </Typography>
          )}
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div
          className={
            phoneStyle
              ? styles.mainDivGeneralInfoMobile
              : styles.mainDivGeneralInfo
          }
        >
          <Typography style={phoneStyle ? { marginBottom: 5 } : {}}>
            <EngineeringIcon
              fontSize="small"
              style={{ marginRight: 10, verticalAlign: "middle" }}
            />
            {Vocabulary.technician}
          </Typography>
          <Autocomplete
            size="small"
            id="handledBy"
            freeSolo={false}
            disablePortal
            options={users}
            value={orderContext.order.handledBy || null}
            getOptionLabel={(option: any) =>
              `${option.firstName} ${option.lastName}`
            }
            isOptionEqualToValue={(option, value) =>
              `${option.firstName} ${option.lastName}` ===
              `${value.firstName} ${value.lastName}`
            }
            onChange={(event: any, value: any) => {
              orderContext.setOrder(
                handleChangeAutocomplete(
                  event,
                  value,
                  orderContext.order,
                  "handledBy"
                )
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} size="small" variant="standard" />
            )}
            style={{ minWidth: 250 }}
          />
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div
          className={
            phoneStyle
              ? styles.mainDivGeneralInfoMobile
              : styles.mainDivGeneralInfo
          }
        >
          <Typography>
            <PersonIcon
              fontSize="small"
              style={{ marginRight: 10, verticalAlign: "middle" }}
            />
            {Vocabulary.reference}
          </Typography>
          <TextField
            id="reference"
            name="reference"
            size="small"
            variant="standard"
            fullWidth={phoneStyle ? true : false}
            value={orderContext.order.reference}
            onChange={(event: any) => {
              orderContext.setOrder(
                onChangeTextField(event, orderContext.order)
              );
            }}
            style={{ minWidth: 250 }}
          />
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div
          className={
            phoneStyle
              ? styles.mainDivGeneralInfoMobile
              : styles.mainDivGeneralInfo
          }
        >
          <Typography>
            <EditIcon
              fontSize="small"
              style={{ marginRight: 10, verticalAlign: "middle" }}
            />
            {Vocabulary.internalReference}
          </Typography>
          <TextField
            id="internalReference"
            name="internalReference"
            size="small"
            variant="standard"
            fullWidth={phoneStyle ? true : false}
            value={orderContext.order.internalReference}
            onChange={(event: any) => {
              orderContext.setOrder(
                onChangeTextField(event, orderContext.order)
              );
            }}
            style={{ minWidth: 250 }}
          />
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div
          className={
            phoneStyle
              ? styles.mainDivGeneralInfoMobile
              : styles.mainDivGeneralInfo
          }
        >
          <Typography style={phoneStyle ? { marginBottom: 5 } : {}}>
            <BuildIcon
              fontSize="small"
              style={{ marginRight: 10, verticalAlign: "middle" }}
            />
            {Vocabulary.serviceLocation}
          </Typography>
          <Autocomplete
            size="small"
            id="location"
            freeSolo={false}
            disablePortal
            options={locations}
            value={orderContext.order.location || null}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option: any, value: any) =>
              option.name === value.name
            }
            onChange={(event: any, value: any) => {
              orderContext.setOrder(
                handleChangeAutocomplete(
                  event,
                  value,
                  orderContext.order,
                  "location"
                )
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} size="small" variant="standard" />
            )}
            style={{ minWidth: 250 }}
          />
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div
          className={
            phoneStyle
              ? styles.mainDivGeneralInfoMobile
              : styles.mainDivGeneralInfo
          }
        >
          <Typography style={phoneStyle ? { marginBottom: 5 } : {}}>
            <LocationOnIcon
              fontSize="small"
              style={{ marginRight: 10, verticalAlign: "middle" }}
            />
            {Vocabulary.internalLocation}
          </Typography>
          <Autocomplete
            size="small"
            id="internalLocation"
            freeSolo={false}
            disablePortal
            options={internalLocationArray}
            value={orderContext.order.internalLocation || ""}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(event: any, value: any) => {
              orderContext.setOrder(
                handleChangeAutocomplete(
                  event,
                  value,
                  orderContext.order,
                  "internalLocation"
                )
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} size="small" variant="standard" />
            )}
            style={{ minWidth: 250 }}
          />
        </div>
        <Divider />
      </Grid>
      {/* Shipping method delivery ?? Shipment information ?? */}
      <Grid item xs={12} md={12} lg={12}>
        <div
          className={
            phoneStyle
              ? styles.mainDivGeneralInfoMobile
              : styles.mainDivGeneralInfo
          }
        >
          <Typography>
            <FeedIcon
              fontSize="small"
              style={{ marginRight: 10, verticalAlign: "middle" }}
            />
            {Vocabulary.tags}
          </Typography>
          <Autocomplete
            size="small"
            id="tags"
            multiple
            freeSolo={true}
            value={orderContext.order.tags || []}
            options={tags || []}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionDisabled={(option) =>
              !!orderContext.order.tags.find(
                (element: any) => element === option
              )
            }
            onChange={(event, value) => {
              orderContext.setOrder(
                handleChangeAutocomplete(
                  event,
                  value,
                  orderContext.order,
                  "tags"
                )
              );
              const newTags = new Set([...tags, ...value]);
              setTags(Array.from(newTags));
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="standard" />
            )}
            style={{ minWidth: 250, maxWidth: 400 }}
          />
        </div>
        <Divider />
      </Grid>
    </Grid>
  );
}
