/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const socket = io(
  process.env.REACT_APP_WEBSOCKET_URL || "wss://fix.upvolt.ch:3002", 
  {
    transports: ["websocket"],
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    secure: true,
  }
);

export const WebsocketContext = createContext<any>({
  shouldUpdate: false,
  setShouldUpdate: (value: boolean) => {},
});

export const WebsocketProvider = (props: any) => {
  const setShouldUpdate = (shouldUpdate: boolean) => {
    setState({ ...state, shouldUpdate: shouldUpdate });
  };
  const initState = {
    shouldUpdate: false,
    setShouldUpdate: setShouldUpdate,
  };
  const [state, setState] = useState(initState);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token && token !== "") {
      socket.on("connect", () => {
        console.log("connected");
      });
      socket.on("error", (error) => {
        console.log("error", error);
      });
      socket.emit("cacheData");
      socket.on("cache", (data) => {
        localStorage.setItem("cache", JSON.stringify(data));
        const event = new Event("storage");
        window.dispatchEvent(event);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldUpdate]);

  return (
    <WebsocketContext.Provider value={state}>
      {props.children}
    </WebsocketContext.Provider>
  );
};
