/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Vocabulary } from "../../../Utils/Vocabulary";
import DividerWithLabel from "../../GenericComponents/DividerWithLabel";
import { TimeTracking } from "../../../Utils/Models";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styles from "../../../Styles/dashboard.module.css";
import { toast } from "react-toastify";
import moment from "moment";
import { Autocomplete, Button, Divider, Grid, TextField } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import {
  calculateDurationForInterval,
  handleChangeAutocomplete,
  onChangeDatePicker,
  showFormattedDuration,
} from "../../../Utils/Utils";
import { OrderContext } from "../../../Contexts/OrderContext";
import EditDeleteButtons from "../../GenericComponents/EditDeleteButtons";
import useCustomMemo from "../../GenericComponents/useCustomMemo";
import {
  dayPilotFormatWithTime,
  euFormatForDateTime,
  euFormatForDateTimeWithSeconds,
} from "../../../Utils/Config";

type StepIntervalProps = {
  timeTracking: TimeTracking[];
  changeInterval: (newTimeTracking: TimeTracking[]) => void;
  addNewInterval: (newInterval: TimeTracking) => void;
};
export default function StepInterval(props: StepIntervalProps) {
  const { timeTracking, addNewInterval, changeInterval } = props;
  const orderContext = useContext(OrderContext);
  const [newInterval, setNewInterval] = useState<TimeTracking | null>(null);
  const [inputValue, setInputValue] = useState(moment());
  const notifyError = (message: string) => toast.error(message);
  const cache = useCustomMemo();
  const users = cache.users || [];
  const user = useMemo(() => {
    const userId = localStorage.getItem("userId");
    const localStorageCache = localStorage.getItem("cache");
    if (!localStorageCache) return null;
    const cache = JSON.parse(localStorageCache);
    if (cache) {
      return cache.users.find((user: any) => user._id === userId);
    }
    return null;
  }, []);

  /**
   *
   * @param e
   * @param name
   */
  function onChangeNewInterval(e: any, name: string) {
    const date = onChangeDatePicker(e, newInterval, name);
    const copyInterval: any = Object.assign({}, newInterval);
    copyInterval[name] = moment(date[name], dayPilotFormatWithTime).unix();
    setNewInterval(copyInterval);
  }

  /**
   *
   * @returns
   */
  function saveInterval() {
    if (newInterval) {
      if (
        parseInt(
          (newInterval.endTime && newInterval.endTime.toString()) || "0"
        ) -
          parseInt(
            (newInterval.startTime && newInterval.startTime.toString()) || "0"
          ) <
        0
      ) {
        notifyError(Vocabulary.endTimeError);
        return;
      }
      if (newInterval.index || newInterval.index === 0) {
        const newTimeTracking: any = [...timeTracking];
        newTimeTracking[newInterval.index] = newInterval;
        changeInterval(newTimeTracking);
      } else {
        addNewInterval(newInterval);
      }
      setNewInterval(null);
    }
  }

  /**
   *
   * @returns
   */
  function calcDuration(interval: any) {
    if (!interval.endTime) return null;
    const duration = calculateDurationForInterval(interval);
    const formattedDuration = showFormattedDuration(duration);
    if (newInterval)
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Autocomplete
            size="small"
            id="userId"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) =>
              `${option.firstName} ${option.lastName}`
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            options={users}
            value={interval.userId}
            onChange={(event: any, value: any) => {
              setNewInterval(
                handleChangeAutocomplete(event, value, interval, "userId")
              );
            }}
            style={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="userId"
                label={Vocabulary.user}
                variant="outlined"
              />
            )}
          />
          <p style={{ fontSize: 17, padding: 0, margin: "0px 5px 5px 10px" }}>
            {formattedDuration}
          </p>
        </div>
      );
    if (interval.userId?.firstName || interval.userId?.lastName)
      return (
        <p style={{ fontSize: 17, padding: 0, margin: "0px 5px 5px 10px" }}>
          {interval.userId.firstName} {interval.userId.lastName}:<br />
          {formattedDuration}
        </p>
      );
    return (
      <p style={{ fontSize: 17, padding: 0, margin: "0px 5px 5px 10px" }}>
        {Vocabulary.noUserFound}:<br />
        {formattedDuration}
      </p>
    );
  }

  /**
   *
   */
  function calcTotalDuration() {
    let totalDuration: any = 0;
    for (const interval of timeTracking) {
      if (!interval.endTime) continue;
      const duration = calculateDurationForInterval(interval);
      totalDuration += duration;
    }
    totalDuration = moment.duration(totalDuration);
    const formattedDuration = showFormattedDuration(totalDuration);
    return formattedDuration;
  }
  /**
   *
   * @param newInterval
   * @returns
   */
  function getIntervals(newInterval?: TimeTracking) {
    let newTimeTracking: any = [...timeTracking];
    if (newInterval) {
      newTimeTracking = [newInterval];
    }
    return newTimeTracking.map((interval: any, index: number) => (
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        key={`${interval.startTime}`}
      >
        <Grid
          key={index}
          container
          spacing={2}
          style={{
            marginTop: 20,
          }}
        >
          <Grid
            item
            xs={12}
            md={newInterval ? 3.5 : 4}
            lg={newInterval ? 3.5 : 4}
          >
            <DateTimePicker
              ampm={false}
              name="startTime"
              label={Vocabulary.startTime}
              slotProps={{
                textField: {
                  style: { width: "100%" },
                  size: "small",
                  onChange: (newValue: any) => {
                    setInputValue(newValue);
                  },
                  onBlur: () => {
                    const momentDate = moment(
                      inputValue,
                      euFormatForDateTime,
                      true
                    );
                    if (momentDate.isValid()) {
                      onChangeNewInterval(inputValue, "startTime");
                    }
                  },
                },
              }}
              minDate={moment(orderContext.order?.createdAt)}
              maxDate={moment.unix(interval.endTime)}
              value={moment.unix(interval.startTime)}
              onChange={(e: any) => onChangeNewInterval(e, "startTime")}
              format={euFormatForDateTimeWithSeconds}
              disabled={newInterval ? false : true}
              className={styles.datePaper}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={newInterval ? 3.5 : 4}
            lg={newInterval ? 3.5 : 4}
          >
            {interval.endTime ? (
              <DateTimePicker
                ampm={false}
                name="stopTime"
                label={Vocabulary.endTime}
                slotProps={{
                  textField: {
                    style: { width: "100%" },
                    size: "small",
                    onChange: (newValue: any) => {
                      setInputValue(newValue);
                    },
                    onBlur: () => {
                      const momentDate = moment(
                        inputValue,
                        euFormatForDateTime,
                        true
                      );
                      if (momentDate.isValid()) {
                        onChangeNewInterval(inputValue, "endTime");
                      }
                    },
                  },
                }}
                minDate={moment.unix(interval.startTime)}
                value={moment.unix(interval.endTime)}
                onChange={(e: any) => onChangeNewInterval(e, "endTime")}
                format={euFormatForDateTimeWithSeconds}
                disabled={newInterval ? false : true}
              />
            ) : (
              <p>{Vocabulary.noEndTime}</p>
            )}
          </Grid>
          <Grid
            item
            xs={10}
            md={newInterval ? 5 : 2.5}
            lg={newInterval ? 5 : 2.5}
          >
            {calcDuration(interval)}
          </Grid>
          {newInterval ? null : (
            <Grid item xs={2} md={1.5} lg={1.5}>
              <EditDeleteButtons
                onEdit={() => setNewInterval({ ...interval, index: index })}
                onDelete={() => {
                  const newTimeTracking: any = [...timeTracking];
                  newTimeTracking.splice(index, 1);
                  changeInterval(newTimeTracking);
                }}
              />
            </Grid>
          )}
        </Grid>
      </LocalizationProvider>
    ));
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DividerWithLabel label={Vocabulary.intervals} />
        <AddCircleIcon
          style={{ fontSize: 24, cursor: "pointer" }}
          onClick={() =>
            setNewInterval({
              startTime: moment().unix(),
              endTime: moment().unix(),
              userId: user,
              isManual: true,
            })
          }
        />
      </div>
      {newInterval ? (
        <div
          style={{
            border: "1px solid #eeeeee",
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            margin: "10px 0px",
            padding: "0px 10px 20px 10px",
          }}
        >
          {getIntervals(newInterval)}
          <div style={{ marginTop: 10, textAlign: "end" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={saveInterval}
              style={{ marginRight: 10 }}
            >
              {Vocabulary.save}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setNewInterval(null)}
              style={{ color: "white", marginLeft: 10 }}
            >
              {Vocabulary.cancel}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {timeTracking && timeTracking.length !== 0 ? (
            <>{getIntervals()}</>
          ) : null}
        </>
      )}
      <div>
        <Divider style={{ marginTop: 30 }} />
        <p style={{ fontSize: 19, marginTop: 5, marginBottom: 0 }}>
          {Vocabulary.totalDuration}: {calcTotalDuration()}
        </p>
      </div>
    </>
  );
}
