/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../../../Styles/dashboard.module.css";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { urlEnum } from "../../../Utils/UrlEnum";
import MainTable from "../../GenericComponents/MainTable";
import { useContext, useState } from "react";
import { CategoryModel, ImportExcelDescription } from "../../../Utils/Models";
import { updateData } from "../../../Services/updateData";
import { postData } from "../../../Services/postData";
import { WebsocketContext } from "../../../Contexts/WebsocketContext";
import { styleForTableWithTabs } from "../../../Pages/Users";
import Category from "./Category";
import ImportExcelButton from "../../GenericComponents/ImportExcelButton";
import ImportExcelModal from "../../GenericComponents/ImportExcelModal";

export default function Categories() {
  const websocketContext = useContext(WebsocketContext);
  const [open, setOpen] = useState(false);
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [editItem, setEditItem] = useState<CategoryModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  /**
   *
   */
  const description: Array<ImportExcelDescription> = [
    {
      name: "Name",
      description: Vocabulary.itemName,
      example: Vocabulary.tweezersPliers,
    },
    {
      name: "Description",
      description: Vocabulary.itemDescription,
      example: Vocabulary.tweezersPliersDescription,
    },
  ];
  /**
   *
   */
  const categoryHeader = [
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.description,
      name: "description",
      options: { sort: false },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: CategoryModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param category
   */
  function addOrUpdateCategory(category: CategoryModel | null) {
    if (category?._id) {
      updateData(`${urlEnum.categories}/${category._id}`, category)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setShouldUpdate(true);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.categories, category)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setShouldUpdate(true);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }
  /**
   *
   * @param items
   */
  async function saveToDatabase(items: any) {
    const res = await postData(`${urlEnum.categories}/insertMany`, items);
    if (res && res.data && res.data.result) {
      setOpenImportExcel(false);
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      setShouldUpdate(true);
    }
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.categories}
        titleDelete={Vocabulary.deleteCategory}
        textDelete={Vocabulary.deleteConfirmationCategory}
        header={categoryHeader}
        tableTitle={Vocabulary.categoriesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
        headerButtons={
          <ImportExcelButton
            onClickImportButton={() => {
              setOpenImportExcel(true);
              setShouldUpdate(false);
            }}
          />
        }
      />
      {open ? (
        <Category
          editCategory={editItem}
          open={open}
          addOrUpdateCategory={(category: CategoryModel | null) =>
            addOrUpdateCategory(category)
          }
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {openImportExcel ? (
        <ImportExcelModal
          open={openImportExcel}
          onClose={() => setOpenImportExcel(false)}
          saveItems={(items: any) => saveToDatabase(items)}
          description={description}
        />
      ) : null}
    </div>
  );
}
