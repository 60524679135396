/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useContext, useState } from "react";
import LocationMainPage from "../Components/Locations/LocationMainPage";
import { postData } from "../Services/postData";
import { updateData } from "../Services/updateData";
import { ImportExcelDescription, LocationModel } from "../Utils/Models";
import { Chip, useMediaQuery } from "@mui/material";
import { WebsocketContext } from "../Contexts/WebsocketContext";
import ImportExcelButton from "../Components/GenericComponents/ImportExcelButton";
import ImportExcelModal from "../Components/GenericComponents/ImportExcelModal";

export default function Locations() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const websocketContext = useContext(WebsocketContext);
  const [open, setOpen] = useState(false);
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editItem, setEditItem] = useState<LocationModel | null>(null);

  /**
   *
   */
  const description: Array<ImportExcelDescription> = [
    {
      name: "Name",
      description: Vocabulary.itemName,
      example: "EuroTech Solutions",
    },
    {
      name: "Email",
      description: Vocabulary.itemEmail,
      example: "info@eurotech.com",
    },
    {
      name: "Phone",
      description: Vocabulary.itemPhone,
      example: "+44 20 7946 0958",
    },
    {
      name: "Country",
      description: Vocabulary.itemCountry,
      example: "United Kingdom",
    },
    {
      name: "Address",
      description: Vocabulary.itemAddress,
      example: "221B Baker Street",
    },
    {
      name: "Zip",
      description: Vocabulary.itemZip,
      example: "NW1 6XE",
    },
    {
      name: "State",
      description: Vocabulary.itemState,
      example: "",
    },
    {
      name: "City",
      description: Vocabulary.itemCity,
      example: "London",
    },
  ];
  /**
   *
   */
  const locationHeader = [
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.email,
      name: "email",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.phone,
      name: "phone",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.address,
      name: "address",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.zip,
      name: "zip",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.city,
      name: "city",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.country,
      name: "country",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.deliveries,
      name: "deliveries",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value.map((item: any, key: number) => (
                <span key={`${item.id}_${item.name}`}>
                  {key < 4 ? (
                    <Chip
                      key={value.id}
                      label={item.name}
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : key === 4 ? (
                    <Chip
                      key={value.id}
                      label="..."
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : null}
                </span>
              ))}
            </>
          );
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: LocationModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param location
   */
  function addOrUpdateLocation(location: LocationModel | null) {
    setShouldUpdate(true);
    if (location?._id) {
      updateData(`${urlEnum.locations}/${location._id}`, location)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.locations, location)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  /**
   *
   * @param items
   */
  async function saveToDatabase(items: any) {
    const res = await postData(`${urlEnum.locations}/insertMany`, items);
    if (res && res.data && res.data.result) {
      setOpenImportExcel(false);
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      setShouldUpdate(true);
    }
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.locations}
        titleDelete={Vocabulary.deleteLocation}
        textDelete={Vocabulary.deleteConfirmationLocation}
        header={locationHeader}
        tableTitle={Vocabulary.locationsList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        headerButtons={
          <ImportExcelButton
            onClickImportButton={() => {
              setOpenImportExcel(true);
              setShouldUpdate(false);
            }}
          />
        }
      />
      {open ? (
        <LocationMainPage
          editLocation={editItem}
          open={open}
          addOrUpdateLocation={(location) => addOrUpdateLocation(location)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {openImportExcel ? (
        <ImportExcelModal
          open={openImportExcel}
          onClose={() => setOpenImportExcel(false)}
          saveItems={(items: any) => saveToDatabase(items)}
          description={description}
        />
      ) : null}
    </div>
  );
}
