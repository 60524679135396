/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { DeviceModel } from "../../Utils/Models";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { handleChangeAutocomplete } from "../../Utils/Utils";
import { Add } from "@mui/icons-material";
import Device from "../Devices/Device";
import moment from "moment";
import Config from "../../Utils/Config";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { CheckInPagesProps } from "./CheckInCustomer";

export default function CheckInDevice(props: CheckInPagesProps) {
  const { checkInOrder, handleChangeCheckInOrder } = props;
  const [devices, setDevices] = useState([]);
  const [open, setOpen] = useState(false);

  /**
   *
   */
  useEffect(() => {
    getDevices();
  }, []);

  /**
   *
   */
  function getDevices() {
    getData(urlEnum.devices).then((res: any) => {
      if (res.data.status === 200) setDevices(res.data.result);
    });
  }

  /**
   *
   * @param step
   */
  function addOrUpdateDevice(device: DeviceModel) {
    device.purchaseDate = moment(device.purchaseDate).format(
      Config.momentUSDateFormat
    );
    if (device?._id) {
      updateData(`${urlEnum.devices}/${device._id}`, device)
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.devices, device)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }
  return (
    <div style={{ margin: "10px 0px" }}>
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 400,
          marginBottom: 15,
          textAlign: "center",
        }}
      >
        {Vocabulary.chooseOrAddDevice}
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Autocomplete
          size="small"
          id="device"
          freeSolo={false}
          disablePortal
          getOptionLabel={(option: any) => option.name}
          isOptionEqualToValue={(option: any, value: any) =>
            option._id === value._id
          }
          options={devices}
          value={checkInOrder.device}
          onChange={(event: any, value: any) => {
            handleChangeCheckInOrder(
              handleChangeAutocomplete(event, value, checkInOrder, "device")
            );
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              name="device"
              label={Vocabulary.device}
              variant="outlined"
            />
          )}
        />
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            setOpen(true);
          }}
          style={{ marginLeft: 15 }}
        >
          {Vocabulary.add}
        </Button>
      </div>
      {open ? (
        <Device
          editDevice={null}
          open={open}
          addOrUpdateDevice={(device) => addOrUpdateDevice(device)}
          onClose={() => setOpen(false)}
        />
      ) : null}
    </div>
  );
}
