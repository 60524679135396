/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { useEffect, useState } from "react";
import { roles } from "../../Utils/Constants";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import { useSearchParams } from "react-router-dom";
import {
  CategoryModel,
  CustomerModel,
  DeviceModel,
  FiltersModel,
  InventoryModel,
  StatusModel,
  UserModel,
  WorkflowModel,
  WorkflowStepModel,
} from "../../Utils/Models";
import { onChangeTextField, setStatuses } from "../../Utils/Utils";
import CircleIcon from "@mui/icons-material/Circle";
import { dayPilotFormat, euFormat } from "../../Utils/Config";
import GenericFilters from "../GenericComponents/GenericFilters";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import {
  StockTransferStatusesAutocomplete,
  productTypesArray,
  usersTypes,
} from "../../Utils/AutocompleteUtils";
import ProductsAutocomplete from "../Products/ProductsAutocomplete";
import moment from "moment";

export type ShowItemProps = {
  show: boolean;
  multiple: boolean;
  disableClearable: boolean;
};

type ReportsAndOrdersFiltersProps = {
  filters: FiltersModel;
  recalculateReportsButton: boolean;
  recalculateReportsFunction?: () => void;
  user?: ShowItemProps;
  customer?: ShowItemProps;
  vendor?: ShowItemProps;
  order?: ShowItemProps;
  workflow?: ShowItemProps;
  device?: ShowItemProps;
  product?: ShowItemProps;
  orderStatus?: ShowItemProps;
  workflowStatus?: ShowItemProps;
  stockStatus?: ShowItemProps;
  step?: ShowItemProps;
  category?: ShowItemProps;
  type?: ShowItemProps;
  inventory?: ShowItemProps;
  showWorkflowLegend?: boolean;
};

const orderStatusOptions = [Vocabulary.closed, Vocabulary.open];
export default function Filters(props: ReportsAndOrdersFiltersProps) {
  const {
    filters,
    recalculateReportsButton,
    recalculateReportsFunction,
    user,
    customer,
    vendor,
    order,
    workflow,
    device,
    product,
    orderStatus,
    workflowStatus,
    stockStatus,
    step,
    category,
    type,
    inventory,
    showWorkflowLegend,
  } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const role = localStorage.getItem("role");
  const cache = useCustomMemo();
  const users = cache.users || [];
  const workflows = cache.workflows || [];
  const inventories = cache.inventories || [];
  const statuses =
    setStatuses(
      cache.statuses || [],
      cache.workflows || [],
      filters.workflow
    ) || [];
  const [inputValue, setInputValue] = useState(moment());
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderNumber, setOrderNumber] = useState<string | number | null>(null);
  const [productAutoComplete, setProductAutoComplete] = useState<any>(null);
  const [customers, setCustomers] = useState<CustomerModel[]>([]);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [steps, setSteps] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    getCustomers();
    getDevices();
    getSteps();
    getCategories();
    getVendors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   *
   */
  useEffect(() => {
    if (filters.order && filters.order !== "null")
      setOrderNumber(filters.order);
    if (filters.product) {
      getProductById();
    } else {
      setProductAutoComplete(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  /**
   *
   */
  async function getProductById() {
    await getData(`${urlEnum.products}/${filters.product}`).then((res: any) => {
      if (res) setProductAutoComplete(res.data.result);
    });
  }

  /**
   *
   */
  function getCustomers() {
    if (customer && customer.show) {
      getData(`${urlEnum.customers}/type/${usersTypes.customers}`).then(
        (response: any) => {
          if (response && response.data && response.data.result)
            setCustomers(response.data.result);
        }
      );
    }
  }

  function getDevices() {
    if (device && device.show) {
      getData(urlEnum.devices).then((response: any) => {
        if (response && response.data && response.data.result)
          setDevices(response.data.result);
      });
    }
  }
  /**
   *
   */
  function getSteps() {
    if (step && step.show) {
      getData(urlEnum.steps).then((res: any) => {
        if (res.data.status === 200) setSteps(res.data.result);
      });
    }
  }

  /**
   *
   */
  function getCategories() {
    if (category && category.show) {
      getData(urlEnum.categories).then((res: any) => {
        if (res.data.status === 200) setCategories(res.data.result);
      });
    }
  }
  /**
   *
   */
  function getVendors() {
    if (vendor && vendor.show) {
      getData(`${urlEnum.customers}/type/${usersTypes.vendors}`).then(
        (res: any) => {
          if (res.data.status === 200) setVendors(res.data.result);
        }
      );
    }
  }
  /**
   *
   * @param name
   * @param value
   */
  const addParamToQueryString = (name: any, value: any) => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };

  /**
   *
   * @param name
   * @param value
   */
  const removeParamFromQueryString = (name: any) => {
    name.map((param: any) => searchParams.delete(param));
    setSearchParams(searchParams);
  };

  /**
   *
   * @returns
   */
  function displayOrder() {
    if (order && order.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            size="small"
            name="order"
            label={Vocabulary.orderNumber}
            value={orderNumber || ""}
            onBlur={() => addParamToQueryString("order", orderNumber)}
            onKeyUp={(e: any) => {
              if (e.key === "Enter") {
                addParamToQueryString("order", orderNumber);
              }
            }}
            onChange={(e) => {
              const newOrderNumber = onChangeTextField(e, filters);
              if (newOrderNumber.order === "") newOrderNumber.order = null;
              setOrderNumber(newOrderNumber.order);
            }}
          ></TextField>
        </Grid>
      );
    return null;
  }
  /**
   *
   * @returns
   */
  function displayUser() {
    if (user && user.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={users}
            multiple={user.multiple}
            getOptionLabel={(option: any) =>
              `${option.firstName} ${option.lastName}`
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionDisabled={(option) =>
              user.multiple
                ? !!(
                    filters.user &&
                    filters.user.find(
                      (element: any) => option._id === element._id
                    )
                  )
                : false
            }
            value={
              user.multiple
                ? users.filter((user: UserModel) =>
                    filters.user.includes(user._id)
                  ) || []
                : users.filter(
                    (user: UserModel) => user._id === filters.user
                  )[0] || null
            }
            freeSolo={false}
            disableClearable={user.disableClearable}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField {...params} label={Vocabulary.user} size="small" />
            )}
            disabled={
              role === roles.admin || role === roles.reporting ? false : true
            }
            onChange={(event, newValue: any) => {
              let valueString: any = null;
              if (user.multiple) {
                const value = newValue.map((user: UserModel) => user._id);
                valueString = value.join(",");
              } else {
                valueString = newValue ? newValue._id : null;
              }
              if (valueString) {
                addParamToQueryString("user", valueString);
              } else {
                removeParamFromQueryString(["user"]);
              }
            }}
          />
        </Grid>
      );
    return null;
  }

  /**
   *
   */
  function displayOrderStatus() {
    if (orderStatus && orderStatus.show) {
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={orderStatusOptions}
            multiple={orderStatus.multiple}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionDisabled={(option) =>
              orderStatus.multiple
                ? !!(
                    filters.orderStatus &&
                    filters.orderStatus.find(
                      (element: any) => option === element
                    )
                  )
                : false
            }
            value={
              orderStatus.multiple
                ? orderStatusOptions.filter((status: string) =>
                    filters.orderStatus.includes(status)
                  ) || []
                : orderStatusOptions.filter(
                    (status: string) => status === filters.orderStatus
                  )[0] || null
            }
            freeSolo={false}
            disableClearable={orderStatus.disableClearable}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.orderStatus}
                size="small"
              />
            )}
            onChange={(event, newValue: any) => {
              let valueString: any = null;
              if (orderStatus.multiple) {
                valueString = newValue.join(",");
              } else {
                valueString = newValue && newValue !== "" ? newValue : null;
              }
              if (valueString)
                addParamToQueryString("orderStatus", valueString);
              else removeParamFromQueryString(["orderStatus"]);
            }}
          />
        </Grid>
      );
    }
    return null;
  }
  /**
   *
   * @returns
   */
  function displayCustomer() {
    if (customer && customer.show)
      return (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={customers}
              multiple={customer.multiple}
              getOptionLabel={(option: any) =>
                option.company || `${option.firstName} ${option.lastName}`
              }
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionDisabled={(option) =>
                customer.multiple
                  ? !!(
                      filters.customer &&
                      filters.customer.find(
                        (element: any) => option._id === element._id
                      )
                    )
                  : false
              }
              value={
                customer.multiple
                  ? customers.filter((customer: CustomerModel) =>
                      filters.customer.includes(customer._id)
                    ) || []
                  : customers.filter(
                      (customer: CustomerModel) =>
                        customer._id === filters.customer
                    )[0] || null
              }
              freeSolo={false}
              disableClearable={customer.disableClearable}
              selectOnFocus
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.customer}
                  size="small"
                />
              )}
              onChange={(event, newValue: any) => {
                let valueString: any = null;
                if (customer.multiple) {
                  const value = newValue.map(
                    (customer: CustomerModel) => customer._id
                  );
                  valueString = value.join(",");
                } else {
                  valueString = newValue ? newValue._id : null;
                }
                if (valueString) {
                  addParamToQueryString("customer", valueString);
                } else {
                  removeParamFromQueryString(["customer"]);
                }
              }}
            />
          </Grid>
        </>
      );
    return null;
  }
  /**
   *
   * @returns
   */
  function displayVendor() {
    if (vendor && vendor.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={vendors}
            multiple={vendor.multiple}
            getOptionLabel={(option: any) => option.company}
            isOptionEqualToValue={(option: any, value: any) =>
              option._id === value._id
            }
            getOptionDisabled={(option: any) =>
              vendor.multiple
                ? !!(
                    filters.vendor &&
                    filters.vendor.find(
                      (element: any) => option._id === element._id
                    )
                  )
                : false
            }
            value={
              vendor.multiple
                ? vendors.filter((vendor: CustomerModel) =>
                    filters.vendor.includes(vendor._id)
                  ) || []
                : vendors.filter(
                    (vendor: CustomerModel) => vendor._id === filters.vendor
                  )[0] || null
            }
            freeSolo={false}
            disableClearable={vendor.disableClearable}
            selectOnFocus
            handleHomeEndKeys
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                name="vendor"
                size="small"
                label={Vocabulary.vendor}
                variant="outlined"
              />
            )}
            onChange={(event: any, newValue: any) => {
              let valueString: any = null;
              if (vendor.multiple) {
                const value = newValue.map(
                  (vendor: CustomerModel) => vendor._id
                );
                valueString = value.join(",");
              } else {
                valueString = newValue ? newValue._id : null;
              }
              if (valueString) {
                addParamToQueryString("vendor", valueString);
              } else {
                removeParamFromQueryString(["vendor"]);
              }
            }}
          />
        </Grid>
      );
  }
  /**
   *
   * @returns
   */
  function displayDevice() {
    if (device && device.show)
      return (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={devices}
              multiple={device.multiple}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionDisabled={(option) =>
                device.multiple
                  ? !!(
                      filters.device &&
                      filters.device.find(
                        (element: any) => option._id === element._id
                      )
                    )
                  : false
              }
              value={
                device.multiple
                  ? devices.filter((device: DeviceModel) =>
                      filters.device.includes(device._id)
                    ) || []
                  : devices.filter(
                      (device: DeviceModel) => device._id === filters.device
                    )[0] || null
              }
              freeSolo={false}
              disableClearable={device.disableClearable}
              selectOnFocus
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField {...params} label={Vocabulary.device} size="small" />
              )}
              onChange={(event, newValue: any) => {
                let valueString: any = null;
                if (device.multiple) {
                  const value = newValue.map(
                    (device: DeviceModel) => device._id
                  );
                  valueString = value.join(",");
                } else {
                  valueString = newValue ? newValue._id : null;
                }
                if (valueString) {
                  addParamToQueryString("device", valueString);
                } else {
                  removeParamFromQueryString(["device"]);
                }
              }}
            />
          </Grid>
        </>
      );
    return null;
  }
  /**
   *
   * @returns
   */
  function displayProduct() {
    if (product && product.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <ProductsAutocomplete
            parentProduct={productAutoComplete}
            handleChangeProductInParent={(event: any, newValue: any) => {
              const valueString = newValue ? newValue._id : null;
              if (valueString) {
                addParamToQueryString("product", valueString);
              } else {
                removeParamFromQueryString(["product"]);
              }
            }}
            variant="outlined"
          />
        </Grid>
      );
    return null;
  }
  /**
   *
   */
  function displayStep() {
    if (step && step.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={steps}
            multiple={step.multiple}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionDisabled={(option) =>
              step.multiple
                ? !!(
                    filters.step &&
                    filters.step.find(
                      (element: any) => option._id === element._id
                    )
                  )
                : false
            }
            value={
              step.multiple
                ? steps.filter((step: WorkflowStepModel) =>
                    filters.step.includes(step._id)
                  ) || []
                : steps.filter(
                    (step: WorkflowStepModel) => step._id === filters.step
                  )[0] || null
            }
            freeSolo={false}
            disableClearable={step.disableClearable}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField {...params} label={Vocabulary.step} size="small" />
            )}
            onChange={(event, newValue: any) => {
              let valueString: any = null;
              if (step.multiple) {
                const value = newValue.map(
                  (step: WorkflowStepModel) => step._id
                );
                valueString = value.join(",");
              } else {
                valueString = newValue ? newValue._id : null;
              }
              if (valueString) {
                addParamToQueryString("step", valueString);
              } else {
                removeParamFromQueryString(["step"]);
              }
            }}
          />
        </Grid>
      );
  }
  /**
   *
   * @returns
   */
  function displayWorkflow() {
    if (workflow && workflow.show)
      return (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={workflows}
              multiple={workflow.multiple}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionDisabled={(option) =>
                workflow.multiple
                  ? !!(
                      filters.workflow &&
                      filters.workflow.find(
                        (element: any) => option._id === element._id
                      )
                    )
                  : false
              }
              value={
                workflow.multiple
                  ? workflows.filter((workflow: WorkflowModel) =>
                      filters.workflow.includes(workflow._id)
                    ) || []
                  : workflows.filter(
                      (workflow: WorkflowModel) =>
                        workflow._id === filters.workflow
                    )[0] || null
              }
              freeSolo={false}
              disableClearable={workflow.disableClearable}
              selectOnFocus
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.workflow}
                  size="small"
                />
              )}
              onChange={(event, newValue: any) => {
                let valueString: any = null;
                if (workflow.multiple) {
                  const value = newValue.map(
                    (workflow: WorkflowModel) => workflow._id
                  );
                  valueString = value.join(",");
                } else {
                  valueString = newValue ? newValue._id : null;
                }
                if (valueString) {
                  addParamToQueryString("workflow", valueString);
                } else {
                  removeParamFromQueryString(["workflow", "workflowStatus"]);
                }
              }}
            />
          </Grid>
        </>
      );
    return null;
  }
  /**
   *
   * @returns
   */
  function displayWorkflowStatus() {
    if (workflowStatus && workflowStatus.show)
      return (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={statuses}
              multiple={workflowStatus.multiple}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionDisabled={(option) =>
                workflowStatus.multiple
                  ? !!(
                      filters.workflowStatus &&
                      filters.workflowStatus.find(
                        (element: any) => option._id === element._id
                      )
                    )
                  : false
              }
              value={
                workflowStatus.multiple
                  ? statuses.filter((workflowStatus: StatusModel) =>
                      filters.workflowStatus.includes(workflowStatus._id)
                    ) || []
                  : statuses.filter(
                      (status: StatusModel) =>
                        status._id === filters.workflowStatus
                    )[0] || null
              }
              freeSolo={false}
              disableClearable={workflowStatus.disableClearable}
              selectOnFocus
              handleHomeEndKeys
              disabled={
                workflow && workflow.show && filters.workflow ? false : true
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.workflowStatus}
                  size="small"
                  helperText={Vocabulary.selectWorkflowMessage}
                />
              )}
              onChange={(event, newValue: any) => {
                let valueString: any = null;
                if (workflowStatus.multiple) {
                  const value = newValue.map(
                    (workflowStatus: StatusModel) => workflowStatus._id
                  );
                  valueString = value.join(",");
                } else {
                  valueString = newValue ? newValue._id : null;
                }
                if (valueString) {
                  addParamToQueryString("workflowStatus", valueString);
                } else {
                  removeParamFromQueryString(["workflowStatus"]);
                }
              }}
            />
          </Grid>
        </>
      );
    return null;
  }
  /**
   *
   * @returns
   */
  function displayWorkflowLegend() {
    if (showWorkflowLegend)
      return (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={
              phoneStyle
                ? { flexGrow: 1 }
                : {
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                  }
            }
          >
            <CircleIcon
              style={{
                color: "#ADD8E6",
                verticalAlign: "middle",
              }}
            ></CircleIcon>
            <div style={{ fontSize: 14 }}>
              &nbsp; = &nbsp;{Vocabulary.predTimeShort}
            </div>
          </div>
          <div
            style={
              phoneStyle
                ? { flexGrow: 1 }
                : {
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                  }
            }
          >
            <CircleIcon
              style={{
                color: "#FF7F7F",
                verticalAlign: "middle",
              }}
            ></CircleIcon>{" "}
            <div style={{ fontSize: 14 }}>
              &nbsp; &gt; &nbsp;{Vocabulary.predTimeShort}
            </div>
          </div>
          <div
            style={
              phoneStyle
                ? { flexGrow: 1 }
                : { display: "flex", alignItems: "center", flexGrow: 1 }
            }
          >
            <CircleIcon
              style={{
                color: "#CFFDBC",
                verticalAlign: "middle",
              }}
            ></CircleIcon>{" "}
            <div style={{ fontSize: 14 }}>
              &nbsp; &lt;&nbsp; {Vocabulary.predTimeShort}
            </div>
          </div>
        </Grid>
      );
    return null;
  }

  /**
   *
   * @returns
   */
  function displayRecalculateReportsButton() {
    if (recalculateReportsButton && role === roles.admin)
      return (
        <div style={{ float: "right", marginBottom: 20 }}>
          <Button
            onClick={recalculateReportsFunction}
            variant="contained"
            color="primary"
          >
            {Vocabulary.recalculateReports}
          </Button>
        </div>
      );
    return null;
  }

  /**
   *
   * @returns
   */
  function displayType() {
    if (type && type.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={productTypesArray}
            multiple={type.multiple}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionDisabled={(option) =>
              type.multiple
                ? !!(
                    filters.type &&
                    filters.type.find((element: string) => option === element)
                  )
                : false
            }
            value={
              type.multiple
                ? productTypesArray.filter((t: string) =>
                    filters.type.includes(t)
                  ) || []
                : productTypesArray.filter(
                    (t: string) => t === filters.type
                  )[0] || null
            }
            freeSolo={false}
            disableClearable={type.disableClearable}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField {...params} label={Vocabulary.type} size="small" />
            )}
            onChange={(event, newValue: any) => {
              let valueString: any = null;
              if (type.multiple) {
                const value = newValue.map((t: string) => t);
                valueString = value.join(",");
              } else {
                valueString = newValue ? newValue : null;
              }
              if (valueString) {
                addParamToQueryString("type", valueString);
              } else {
                removeParamFromQueryString(["type"]);
              }
            }}
          />
        </Grid>
      );
    return null;
  }

  /**
   *
   */
  function displayCategory() {
    if (category && category.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={categories}
            multiple={category.multiple}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionDisabled={(option) =>
              category.multiple
                ? !!(
                    filters.category &&
                    filters.category.find(
                      (element: any) => option._id === element._id
                    )
                  )
                : false
            }
            value={
              category.multiple
                ? categories.filter((category: CategoryModel) =>
                    filters.workflow.includes(category.name)
                  ) || []
                : categories.filter(
                    (category: CategoryModel) =>
                      category.name === filters.category
                  )[0] || null
            }
            freeSolo={false}
            disableClearable={category.disableClearable}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField {...params} label={Vocabulary.category} size="small" />
            )}
            onChange={(event, newValue: any) => {
              let valueString: any = null;
              if (category.multiple) {
                const value = newValue.map(
                  (category: CategoryModel) => category.name
                );
                valueString = value.join(",");
              } else {
                valueString = newValue ? newValue.name : null;
              }
              if (valueString) {
                addParamToQueryString("category", valueString);
              } else {
                removeParamFromQueryString(["category"]);
              }
            }}
          />
        </Grid>
      );
    return null;
  }

  /**
   *
   * @returns
   */
  function displayInventory() {
    if (inventory && inventory.show)
      return (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={inventories}
              multiple={inventory.multiple}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionDisabled={(option) =>
                inventory.multiple
                  ? !!(
                      filters.inventory &&
                      filters.inventory.find(
                        (element: any) => option._id === element._id
                      )
                    )
                  : false
              }
              value={
                inventory.multiple
                  ? inventories.filter((inventory: InventoryModel) =>
                      filters.inventory.includes(inventory._id)
                    ) || []
                  : inventories.filter(
                      (inventory: InventoryModel) =>
                        inventory._id === filters.inventory
                    )[0] || null
              }
              freeSolo={false}
              disableClearable={inventory.disableClearable}
              selectOnFocus
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.inventory}
                  size="small"
                />
              )}
              onChange={(event, newValue: any) => {
                let valueString: any = null;
                if (inventory.multiple) {
                  const value = newValue.map(
                    (inventory: InventoryModel) => inventory._id
                  );
                  valueString = value.join(",");
                } else {
                  valueString = newValue ? newValue._id : null;
                }
                if (valueString) {
                  addParamToQueryString("inventory", valueString);
                } else {
                  removeParamFromQueryString(["inventory"]);
                }
              }}
            />
          </Grid>
        </>
      );
    return null;
  }

  /**
   *
   * @returns
   */
  function displayStatusStock() {
    if (stockStatus && stockStatus.show)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={StockTransferStatusesAutocomplete}
            multiple={stockStatus.multiple}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionDisabled={(option) =>
              stockStatus.multiple
                ? !!(
                    filters.stockStatus &&
                    filters.stockStatus.find(
                      (element: any) => option === element
                    )
                  )
                : false
            }
            value={
              stockStatus.multiple
                ? StockTransferStatusesAutocomplete.filter((stockStatus: any) =>
                    filters.stockStatus.includes(stockStatus)
                  ) || []
                : StockTransferStatusesAutocomplete.filter(
                    (stockStatus: any) => stockStatus === filters.stockStatus
                  )[0] || null
            }
            freeSolo={false}
            disablePortal
            disableClearable={stockStatus.disableClearable}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.stockStatus}
                size="small"
              />
            )}
            onChange={(event, newValue: any) => {
              let valueString: any = null;
              if (stockStatus.multiple) {
                const value = newValue.map((stockStatus: any) => stockStatus);
                valueString = value.join(",");
              } else {
                valueString = newValue ? newValue : null;
              }
              if (valueString) {
                addParamToQueryString("stockStatus", valueString);
              } else {
                removeParamFromQueryString(["stockStatus"]);
              }
            }}
          />
        </Grid>
      );
    return null;
  }

  return (
    <GenericFilters>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6} lg={4} style={{ maxWidth: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              name="startTime"
              label={Vocabulary.startDate}
              slotProps={{
                textField: {
                  style: { width: "100%" },
                  size: "small",
                  onChange: (newValue: any) => {
                    setInputValue(newValue);
                  },
                  onBlur: () => {
                    const momentDate = moment(inputValue, dayPilotFormat, true);
                    if (momentDate.isValid()) {
                      addParamToQueryString(
                        "startDate",
                        inputValue.format(dayPilotFormat)
                      );
                    }
                  },
                },
              }}
              maxDate={filters.endDate}
              value={filters.startDate}
              onChange={(e: any) =>
                addParamToQueryString("startDate", e.format(dayPilotFormat))
              }
              format={euFormat}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              name="startTime"
              minDate={filters.startDate}
              label={Vocabulary.endDate}
              slotProps={{
                textField: {
                  style: { width: "100%" },
                  size: "small",
                  onChange: (newValue: any) => {
                    setInputValue(newValue);
                  },
                  onBlur: () => {
                    const momentDate = moment(inputValue, dayPilotFormat, true);
                    if (momentDate.isValid()) {
                      addParamToQueryString(
                        "endDate",
                        inputValue.format(dayPilotFormat)
                      );
                    }
                  },
                },
              }}
              value={filters.endDate}
              onChange={(e: any) =>
                addParamToQueryString("endDate", e.format(dayPilotFormat))
              }
              format={euFormat}
            />
          </LocalizationProvider>
        </Grid>
        {displayOrderStatus()}
        {displayOrder()}
        {displayUser()}
        {displayVendor()}
        {displayCustomer()}
        {displayDevice()}
        {displayStep()}
        {displayWorkflow()}
        {displayWorkflowStatus()}
        {displayWorkflowLegend()}
        {displayType()}
        {displayCategory()}
        {displayInventory()}
        {displayProduct()}
        {displayStatusStock()}
      </Grid>
      {displayRecalculateReportsButton()}
    </GenericFilters>
  );
}
