/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import theme from "../../Themes/Theme";
import styles from "../../Styles/dashboard.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PurchaseOrderStatuses } from "../../Utils/AutocompleteUtils";
import { localUrlEnum } from "../../Utils/UrlEnum";
import PurchaseOrdersMainTable from "../../Components/Products/Stocks/PurchaseOrders/PurchaseOrdersMainTable";
import Filters from "../../Components/Reports/Filters";

export default function PurchaseOrders() {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [value, setValue] = useState(0);
  const [filters, setFilters] = useState<any>({
    inventory: null,
    product: null,
    vendor: null,
  });

  useEffect(() => {
    const search: any = location.search.split(/[?&]+/);
    const newFilters: any = {
      inventory: null,
      product: null,
      status: null,
      vendor: null,
    };

    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    setFilters(newFilters);
    setValueFromLocation(newFilters.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param newValue
   */
  function setValueFromLocation(newValue: string) {
    switch (newValue) {
      case PurchaseOrderStatuses.open:
        setValue(0);
        break;
      case PurchaseOrderStatuses.closed:
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    switch (newValue) {
      case 0:
        navigate(
          `${localUrlEnum.purchaseOrders}?type=${PurchaseOrderStatuses.open}`
        );
        break;
      case 1:
        navigate(
          `${localUrlEnum.purchaseOrders}?type=${PurchaseOrderStatuses.closed}`
        );
        break;
      default:
        navigate(
          `${localUrlEnum.purchaseOrders}?type=${PurchaseOrderStatuses.open}`
        );
        break;
    }
  }

  /**
   *
   */
  function showInformationForTabs() {
    switch (value) {
      case 0:
        return <PurchaseOrdersMainTable type={PurchaseOrderStatuses.open} />;
      case 1:
        return <PurchaseOrdersMainTable type={PurchaseOrderStatuses.closed} />;
      default:
        return <PurchaseOrdersMainTable type={PurchaseOrderStatuses.open} />;
    }
  }

  /**
   *
   * @param tabValue
   * @returns
   */
  function getTabStyle(tabValue: number) {
    return {
      backgroundColor:
        value === tabValue
          ? theme.palette.secondary.main
          : theme.palette.cancel?.main,
      color: value === tabValue ? "white" : "#9c9b9b",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      marginRight: "1px",
    };
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <Filters
        filters={filters}
        recalculateReportsButton={false}
        inventory={{
          show: true,
          multiple: false,
          disableClearable: false,
        }}
        product={{
          show: true,
          multiple: false,
          disableClearable: false,
        }}
        vendor={{
          show: true,
          multiple: false,
          disableClearable: false,
        }}
      />
      <Tabs
        value={value}
        onChange={handleChangeValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <Tab label={Vocabulary.open} style={getTabStyle(0)} />
        <Tab label={Vocabulary.closed} style={getTabStyle(1)} />
      </Tabs>
      <> {showInformationForTabs()}</>
    </div>
  );
}
