/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, InputLabel, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericModal from "../GenericComponents/GenericModal";
import SyncIcon from "@mui/icons-material/Sync";
import { useState } from "react";
import { toast } from "react-toastify";

type SyncOrdersModalProps = {
  openSync: boolean;
  handleChangeOpenSync: () => void;
  syncOrders: (orderNumber: number | null) => void;
};
export default function SyncOrdersModal(props: SyncOrdersModalProps) {
  const { openSync, handleChangeOpenSync, syncOrders } = props;
  const [orderNumber, setOrderNumber] = useState<string>("");

  /**
   * Sync order
   */
  function syncOrder() {
    if (orderNumber === "") {
      toast.error(Vocabulary.orderNumberIsRequired);
      return;
    }
    syncOrders(parseInt(orderNumber));
  }
  return (
    <GenericModal
      open={openSync}
      onClose={handleChangeOpenSync}
      title={Vocabulary.syncOrders}
    >
      <InputLabel>{Vocabulary.syncLastTenOrdersFromFixably}</InputLabel>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        style={{ marginBottom: 20, marginTop: 5 }}
        onClick={() => syncOrders(null)}
      >
        <SyncIcon style={{ marginRight: 10 }} /> {Vocabulary.sync}
      </Button>
      <Divider />
      <InputLabel style={{ marginTop: 20 }}>
        {Vocabulary.syncByOrderNumber}
      </InputLabel>

      <TextField
        label={Vocabulary.orderNumber}
        id="orderId"
        name="orderId"
        size="small"
        variant="outlined"
        type="number"
        value={orderNumber}
        onChange={(event: any) => {
          setOrderNumber(event.target.value);
        }}
        fullWidth
        style={{ marginTop: 5, marginBottom: 10 }}
      />
      <Button variant="contained" color="primary" fullWidth onClick={syncOrder}>
        <SyncIcon style={{ marginRight: 10 }} /> {Vocabulary.sync}
      </Button>
    </GenericModal>
  );
}
