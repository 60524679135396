/* eslint-disable camelcase */
import {
  Assignment,
  Dashboard,
  Description,
  People,
} from "@mui/icons-material";
// import ScheduleIcon from "@mui/icons-material/Schedule";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import InventoryIcon from "@mui/icons-material/Inventory";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import PieChartIcon from "@mui/icons-material/PieChart";
import ArticleIcon from "@mui/icons-material/Article";
import InboxIcon from "@mui/icons-material/Inbox";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TaskIcon from "@mui/icons-material/Task";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import BarChartIcon from "@mui/icons-material/BarChart";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { localUrlEnum } from "./UrlEnum";
import { Vocabulary } from "./Vocabulary";
import OrdersReports from "../Pages/Reports/OrdersReports";
import WorkflowsReports from "../Pages/Reports/WorkflowsReports";
import UsersMainPage from "../Components/Users/UsersMainPage";
import MyAccount from "../Pages/MyAccount";
import Products from "../Pages/Stocks/Products";
import Steps from "../Pages/Steps";
import Orders from "../Pages/Orders/Orders";
import Order from "../Components/Orders/Order";
import Devices from "../Pages/Devices";
import Workflows from "../Pages/Workflows";
import Deliveries from "../Pages/Deliveries";
import Locations from "../Pages/Locations";
import Statuses from "../Pages/Statuses";
import Roles from "../Pages/Roles";
import TrashedOrders from "../Pages/Orders/TrashedOrders";
import Login from "../Pages/LoginPage";
import PageNotFound from "../Components/GenericComponents/PageNotFound";
import DashboardContainer from "../Components/DashboardContainer";
import LocalDashboard from "../Pages/Dashboard";
import TemplatesMainPage from "../Components/Templates/TemplatesMainPage";
import Reports from "../Pages/Reports/Reports";
import PurchaseOrders from "../Pages/Stocks/PurchaseOrders";
import StocksManagement from "../Pages/Stocks/StocksManagement";
import { PurchaseOrderStatuses, otherReports } from "./AutocompleteUtils";
import Settings from "../Pages/Settings";
import Shipments from "../Pages/Shipments";
// import TimeTrackingTable from "../Pages/TimeTrackingTable";
import ProductSettings from "../Pages/ProductSettings";
import OtherReportsMainPage from "../Pages/OtherReports/OtherReportsMainPage";

export const HttpStatusCode = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  RequestTimeout: 408,
  InternalServerError: 500,
  NotImplemented: 501,
  UnprocessableEntity: 422,
};

export const HttpVerbs = {
  post: "post",
  get: "get",
  put: "put",
  patch: "patch",
  delete: "delete",
};

/**
 *
 */
const StockSubMenuSliderData = () => {
  return [
    {
      name: Vocabulary.products,
      path: localUrlEnum.products,
      icon: <Assignment />,
    },
    {
      name: Vocabulary.stocksManagement,
      path: localUrlEnum.stocksManagement,
      icon: <InventoryIcon />,
    },
    {
      name: Vocabulary.purchaseOrders,
      path: `${localUrlEnum.purchaseOrders}?type=${PurchaseOrderStatuses.open}`,
      icon: <ShoppingBasketIcon />,
    },
  ];
};
/**
 *
 * @returns
 */
const AllOrdersSubMenu = () => {
  return [
    {
      name: Vocabulary.orders,
      path: localUrlEnum.orders,
      icon: <Description />,
    },
    {
      name: Vocabulary.trashedOrders,
      path: localUrlEnum.trashedOrders,
      icon: <RestoreFromTrashIcon />,
    },
  ];
};
/**
 *
 */
const AdvancedReportSubMenuSliderData = () => {
  return [
    {
      name: Vocabulary.ordersReports,
      path: localUrlEnum.ordersReports,
      icon: <AutoGraphIcon />,
    },
    {
      name: Vocabulary.workflowsReports,
      path: localUrlEnum.workflowsReports,
      icon: <EqualizerIcon />,
    },
    {
      name: Vocabulary.otherReports,
      path: `${localUrlEnum.otherReports}?type=${otherReports.technicianEfficiency}`,
      icon: <PieChartIcon />,
    },
  ];
};

/**
 *
 */
const OthersSubMenuSliderData = () => {
  return [
    {
      name: Vocabulary.users,
      path: localUrlEnum.users,
      icon: <People />,
    },
    {
      name: Vocabulary.devices,
      path: localUrlEnum.devices,
      icon: <PersonalVideoIcon />,
    },
    {
      name: Vocabulary.workflows,
      path: localUrlEnum.workflows,
      icon: <InboxIcon />,
    },
    {
      name: Vocabulary.steps,
      path: localUrlEnum.steps,
      icon: <TaskIcon />,
    },

    {
      name: Vocabulary.deliveries,
      path: localUrlEnum.deliveries,
      icon: <DeliveryDiningIcon />,
    },
    {
      name: Vocabulary.locations,
      path: localUrlEnum.locations,
      icon: <LocationOnIcon />,
    },
    {
      name: Vocabulary.shipments,
      path: localUrlEnum.shipments,
      icon: <LocalShippingIcon />,
    },
    {
      name: Vocabulary.statuses,
      path: localUrlEnum.statuses,
      icon: <BookmarkBorderIcon />,
    },
    {
      name: Vocabulary.roles,
      path: localUrlEnum.roles,
      icon: <SupportAgentIcon />,
    },
    {
      name: Vocabulary.productSettings,
      path: localUrlEnum.productSettings,
      icon: <DisplaySettingsIcon />,
    },
    {
      name: Vocabulary.templates,
      path: localUrlEnum.emailTemplates,
      icon: <ArticleIcon />,
    },
    // {
    //   name: Vocabulary.timeTracking,
    //   path: localUrlEnum.timeTracking,
    //   icon: <ScheduleIcon />,
    // },
  ];
};

/**
 *
 */
const DefaultMainSliderData = () => {
  return [
    {
      name: Vocabulary.dashboard,
      path: "/",
      icon: <Dashboard />,
    },
    {
      name: Vocabulary.reports,
      path: localUrlEnum.reports,
      icon: <AssessmentIcon />,
    },
    {
      name: Vocabulary.allOrders,
      icon: <Description />,
      subMenu: AllOrdersSubMenu(),
    },
  ];
};

/**
 *
 */
const ReportingMainSliderData = () => {
  return [
    ...DefaultMainSliderData(),
    {
      name: Vocabulary.statistics,
      icon: <BarChartIcon />,
      subMenu: AdvancedReportSubMenuSliderData(),
    },
  ];
};

/**
 *
 */
const AdminMainSliderData = () => {
  return [
    ...ReportingMainSliderData(),
    {
      name: Vocabulary.stocks,
      icon: <InventoryIcon />,
      subMenu: StockSubMenuSliderData(),
    },
    {
      name: Vocabulary.others,
      icon: <SettingsSuggestIcon />,
      subMenu: OthersSubMenuSliderData(),
    },
  ];
};

/**
 *
 */
export const routes = [
  {
    path: "",
    element: <DashboardContainer />,
    children: [
      { path: "/", element: <LocalDashboard /> },
      { path: localUrlEnum.reports, element: <Reports /> },
      {
        path: localUrlEnum.ordersReports,
        element: <OrdersReports />,
      },
      {
        path: localUrlEnum.workflowsReports,
        element: <WorkflowsReports />,
      },
      {
        path: `${localUrlEnum.users}`,
        element: <UsersMainPage />,
      },
      {
        path: localUrlEnum.myAccount,
        element: <MyAccount />,
      },
      {
        path: localUrlEnum.settings,
        element: <Settings />,
      },
      {
        path: localUrlEnum.products,
        element: <Products />,
      },
      {
        path: localUrlEnum.steps,
        element: <Steps />,
      },
      {
        path: localUrlEnum.orders,
        element: <Orders />,
      },
      {
        path: `${localUrlEnum.orders}/:id?`,
        element: <Order />,
      },
      {
        path: localUrlEnum.devices,
        element: <Devices />,
      },
      { path: localUrlEnum.workflows, element: <Workflows /> },
      {
        path: localUrlEnum.deliveries,
        element: <Deliveries />,
      },
      {
        path: localUrlEnum.locations,
        element: <Locations />,
      },
      {
        path: localUrlEnum.shipments,
        element: <Shipments />,
      },
      {
        path: localUrlEnum.statuses,
        element: <Statuses />,
      },
      {
        path: localUrlEnum.roles,
        element: <Roles />,
      },
      {
        path: localUrlEnum.productSettings,
        element: <ProductSettings />,
      },
      {
        path: localUrlEnum.trashedOrders,
        element: <TrashedOrders />,
      },
      {
        path: localUrlEnum.emailTemplates,
        element: <TemplatesMainPage />,
      },
      {
        path: localUrlEnum.stocksManagement,
        element: <StocksManagement />,
      },
      {
        path: localUrlEnum.purchaseOrders,
        element: <PurchaseOrders />,
      },
      {
        path: localUrlEnum.otherReports,
        element: <OtherReportsMainPage />,
      },
      // {
      //   path: localUrlEnum.timeTracking,
      //   element: <TimeTrackingTable />,
      // },
    ],
  },
  {
    path: localUrlEnum.login,
    element: <Login />,
  },
  {
    path: "*",
    element: (
      <PageNotFound
        text={Vocabulary.youDoNotHavePermission}
        icon={<DoDisturbIcon style={{ fontSize: 65, color: "red" }} />}
      />
    ),
  },
];

/**
 *
 * @param role
 * @returns
 */
export function getRoutesForRole(role: string | null) {
  switch (role) {
    case roles.admin:
      return AdminMainSliderData();
    case roles.user:
      return DefaultMainSliderData();
    case roles.reporting:
      return ReportingMainSliderData();
    default:
      return DefaultMainSliderData();
  }
}

/**
 *
 */
export const roles = {
  admin: "admin",
  user: "user",
  reporting: "reporting",
  technician: "technician",
};
