import { Button, Divider, Menu, MenuItem, useMediaQuery } from "@mui/material";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import { Vocabulary } from "../../Utils/Vocabulary";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext } from "react";
import styles from "../../Styles/dashboard.module.css";

export const priorityArray = [
  {
    name: "NORMAL",
    label: Vocabulary.normal,
    icon: <KeyboardArrowRightIcon />,
  },
  {
    name: "HIGHER",
    label: Vocabulary.higher,
    icon: <KeyboardArrowUpIcon />,
  },
  {
    name: "HIGHEST",
    label: Vocabulary.highest,
    icon: <KeyboardDoubleArrowUpIcon />,
  },
];

type OrderHeaderProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  addOrUpdateOrder: () => void;
  handleCloseMenu: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleChangePriority: (name: string) => void;
  handleDuplicateOrder: () => void;
  handleCloseOrder: () => void;
};
export default function OrderHeader(props: OrderHeaderProps) {
  const {
    anchorEl,
    open,
    addOrUpdateOrder,
    handleChangePriority,
    handleClick,
    handleCloseMenu,
    handleDuplicateOrder,
    handleCloseOrder,
  } = props;
  const phoneStyle = useMediaQuery("(max-width:800px)");
  const orderContext = useContext(OrderContext);

  return (
    <div
      id="orderTopMenu"
      className={phoneStyle ? styles.orderHeaderMobile : styles.orderHeader}
    >
      <div>
        <p
          style={{
            color: "#008470",
            fontWeight: 600,
            margin: "5px 0px 0px 0px",
          }}
        >
          {orderContext.order.fixablyId
            ? `${Vocabulary.orderNumber}: ${orderContext.order.fixablyId}`
            : ""}
        </p>
        <div
          style={{
            display: "block",
            margin: "10px 0px 0px 0px",
            fontWeight: 300,
          }}
        >
          {orderContext.order.isClosed ? (
            <p
              style={{
                color: "red",
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <LockIcon style={{ marginRight: 10 }} />
              <span style={{ verticalAlign: "middle" }}>
                {Vocabulary.orderIsClosed}
              </span>
            </p>
          ) : (
            <p
              style={{
                color: "green",
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <LockOpenIcon style={{ marginRight: 10 }} />
              <span style={{ verticalAlign: "middle" }}>
                {Vocabulary.orderIsOpen}
              </span>
            </p>
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <Button
          size="medium"
          variant="contained"
          style={{
            marginRight: 15,
          }}
          onClick={addOrUpdateOrder}
        >
          {Vocabulary.saveOrder}
        </Button>
        <Button
          size="medium"
          variant="outlined"
          style={{ color: "#333", borderColor: "#333", padding: "5px 0px" }}
          onClick={handleClick}
        >
          <SettingsRoundedIcon fontSize="medium" />
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <DividerWithLabel label={Vocabulary.priority} />
          {priorityArray.map((item, index) => (
            <MenuItem
              key={item.name}
              onClick={() => handleChangePriority(item.name)}
              style={
                item.name === orderContext.order.priority
                  ? { backgroundColor: "#f7f8fa" }
                  : {}
              }
            >
              {item.icon} {item.label}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            onClick={handleDuplicateOrder}
            disabled={orderContext.order?._id ? false : true}
          >
            {Vocabulary.duplicateOrder}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={handleCloseOrder}
            disabled={orderContext.order?._id ? false : true}
          >
            {Vocabulary.closeOrder}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
