/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../../Styles/dashboard.module.css";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LineModel, NotesModel, OrderModel } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import OrderDataComponents from "./OrderDataComponents";
import { postData } from "../../Services/postData";
import OrderHeader from "./OrderHeader";
import { updateData } from "../../Services/updateData";
import { OrderContext } from "../../Contexts/OrderContext";
import { useMediaQuery } from "@mui/material";

export type EditNoteType = {
  index: number;
  note: NotesModel | null;
  filter: Array<string>;
};
export default function Order() {
  const location = useLocation();
  const navigate = useNavigate();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [notesBackup, setNotesBackup] = useState<Array<NotesModel>>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const orderContext = useContext(OrderContext);
  const open = Boolean(anchorEl);

  /**
   *
   */
  useEffect(() => {
    const id: string = location.pathname.split("/").pop() || "";
    if (id === "newOrder") orderContext.setOrder(new OrderModel());
    else {
      getOrder(id);
      getEmailsAndSaveThem(id);
    }

    // // event listener for scroll
    // const div: any = document.getElementById("parentOrder");
    // div.addEventListener("scroll", handleScroll);
    // return () => {
    //   div.removeEventListener("scroll", handleScroll);
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /////////////////////////// GET SCROLL TOP ///////////////////////////
  /**
   *
   */
  // const handleScroll = (event: any) => {
  //   const { scrollTop } = event.target;
  //   const orderTopMenu: any = document.getElementById("orderTopMenu");
  //   orderTopMenu.style.top = `${scrollTop}px`;
  // };
  /////////////////////////// SETTINGS MENU ///////////////////////////

  /**
   *
   * @param event
   */
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  /**
   *
   */
  function handleCloseMenu() {
    setAnchorEl(null);
  }
  /////////////////////////// END SETTINGS MENU ///////////////////////////

  /////////////////////////// CHANGE ORDER PRIORITY ///////////////////////////

  /**
   *
   * @param priority
   */
  function handleChangePriority(priority: string) {
    const newOrder = Object.assign({}, orderContext.order);
    newOrder.priority = priority;
    orderContext.setOrder(newOrder);
    setAnchorEl(null);
  }
  /////////////////////////// END CHANGE ORDER PRIORITY ///////////////////////////

  /////////////////////////// DUPLICATE ORDER ///////////////////////////

  /**
   *
   */
  async function handleDuplicateOrder() {
    //duplicateOrder
    if (orderContext.order && orderContext.order._id)
      await postData(
        `${urlEnum.orders}/duplicateOrder/${orderContext.order._id}`
      ).then((res) => {
        if (
          res &&
          res.data &&
          (res.data.status === 200 || res.data.status === 201)
        ) {
          window.open(
            `${localUrlEnum.orders}/${res.data.result._id}`,
            "_blank"
          );
        }
      });
  }
  /////////////////////////// END DUPLICATE ORDER ///////////////////////////

  /////////////////////////// CLOSE ORDER //////////////////////////
  /**
   *
   */
  async function handleCloseOrder() {
    const res = await postData(
      `${urlEnum.orders}/close/${orderContext.order._id}`
    );
    if (res && res.data && res.data.result) {
      orderContext.setOrder(res.data.result);
    }
  }
  /////////////////////////// END CLOSE ORDER //////////////////////////

  /////////////////////////// GET ORDER ///////////////////////////
  /**
   *
   * @param id
   */
  function getOrder(id: string) {
    getData(`${urlEnum.orders}/${id}`)
      .then((res) => {
        if (res) {
          const resOrder = res.data.result;
          if (resOrder.lines && resOrder.lines.length === 0)
            resOrder.lines = [new LineModel()];
          if (resOrder.notes && resOrder.notes.length !== 0)
            resOrder.notes = resOrder.notes.reverse();
          setNotesBackup(resOrder.notes);
          orderContext.setOrder(res.data.result);
        }
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @param id
   */
  function getEmailsAndSaveThem(id: string) {
    getData(`${urlEnum.orders}/getEmails/${id}`);
  }

  /////////////////////////// END GET ORDER ///////////////////////////

  /////////////////////////// UPDATE ORDER ///////////////////////////
  /**
   *
   * @param step
   */
  function addOrUpdateOrder() {
    const newOrder: any = Object.assign({}, orderContext.order);
    newOrder.notes = orderContext.order.notes.map((note) => {
      return {
        _id: note._id,
        title: note.title || "",
        text: note.text || "",
        visibility: note.visibility ? note.visibility.name : "",
        type: note.type || "",
        status: note.status || 0,
        report: note.report,
        createdBy: note.createdBy,
        createdAt: note.createdAt,
      };
    });
    // newOrder.lines = orderContext.order.lines.filter(
    //   (line) => line._id && line._id !== "" && line._id !== null
    // );
    newOrder.total = orderContext.order.lines
      .reduce((accumulator, line) => {
        return +accumulator + +line.total;
      }, 0)
      .toFixed(2);
    newOrder.total0 = orderContext.order.lines
      .reduce((accumulator, line) => {
        return +accumulator + +line.total0;
      }, 0)
      .toFixed(2);
    //
    if (orderContext.order?._id) {
      updateData(`${urlEnum.orders}/${orderContext.order._id}`, newOrder)
        .then((res) => {
          if (res) {
            //
          }
        })
        .catch((err) => {
          //
        });
    } else {
      const userId = localStorage.getItem("userId");
      newOrder.handledBy = userId ? userId : null;
      newOrder.totalRemaining = newOrder.total;
      postData(urlEnum.orders, newOrder)
        .then((res: any) => {
          if (res) {
            navigate(`${localUrlEnum.orders}/${res.data.result._id}`);
          }
        })
        .catch((err) => {
          //
        });
    }
  }
  /**
   *
   */

  /////////////////////////// END UPDATE ORDER ///////////////////////////

  return (
    <div
      id="parentOrder"
      className={
        phoneStyle ? styles.mainContainerOrderMobile : styles.mainContainerOrder
      }
    >
      <OrderHeader
        anchorEl={anchorEl}
        open={open}
        addOrUpdateOrder={addOrUpdateOrder}
        handleCloseMenu={handleCloseMenu}
        handleClick={handleClick}
        handleChangePriority={handleChangePriority}
        handleDuplicateOrder={handleDuplicateOrder}
        handleCloseOrder={handleCloseOrder}
      />
      <OrderDataComponents notesBackup={notesBackup} />
    </div>
  );
}
