import { AttributeModel, ProductModel } from "../../Utils/Models";
import ProductOptions from "./ProductOptions";
import ProductVariants, { VariantProps } from "./ProductVariants";

type VariantsAndAttributesProps = {
  product: ProductModel;
  handleChangeProduct: (product: ProductModel) => void;
};
export default function VariantsAndAttributes(
  props: VariantsAndAttributesProps
) {
  const { product, handleChangeProduct } = props;

  /**
   *
   * @param options
   */
  function handleChangeOptions(
    options: Array<AttributeModel>,
    variants?: Array<VariantProps>
  ) {
    const newProduct = Object.assign({}, product);
    newProduct.options = options;
    if (variants && variants.length !== 0) newProduct.variants = variants;
    handleChangeProduct(newProduct);
  }
  /**
   *
   */
  function handleChangeVariants(variants: Array<VariantProps>) {
    const newProduct = Object.assign({}, product);
    newProduct.variants = variants;
    handleChangeProduct(newProduct);
  }

  return (
    <>
      <ProductOptions
        options={product.options}
        price={product.stockPrice}
        handleChangeOptions={handleChangeOptions}
      />
      <ProductVariants
        product={product}
        variants={product.variants ? product.variants : []}
        handleChangeVariants={handleChangeVariants}
        handleChangeProduct={handleChangeProduct}
      />
    </>
  );
}
