/* eslint-disable @typescript-eslint/no-explicit-any */
import { CategoryModel } from "../../../Utils/Models";
import AddOrUpdateModel from "../../GenericComponents/AddOrUpdateModel";
import { useEffect, useRef, useState } from "react";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { onChangeTextField } from "../../../Utils/Utils";

type CategoryProps = {
  editCategory: CategoryModel | null;
  open: boolean;
  addOrUpdateCategory: (category: CategoryModel | null) => void;
  onClose: () => void;
};

export default function Category(props: CategoryProps) {
  const { editCategory, open, addOrUpdateCategory, onClose } = props;
  const ref: any = useRef();
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [category, setCategory] = useState<CategoryModel>(new CategoryModel());

  /**
   *
   */
  useEffect(() => {
    if (editCategory) {
      setCategory(editCategory);
    } else {
      setCategory(new CategoryModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCategory]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateCategory(category)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.category}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <TextValidator
          size="small"
          label={Vocabulary.name}
          name="name"
          value={category.name}
          onChange={(e: any) => setCategory(onChangeTextField(e, category))}
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          fullWidth
          style={{ marginBottom: 20 }}
        />
        <TextValidator
          size="small"
          label={Vocabulary.description}
          name="description"
          value={category.description}
          onChange={(e: any) => setCategory(onChangeTextField(e, category))}
          fullWidth
        />
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
