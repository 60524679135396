/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState } from "react";
import CheckInCustomer from "./CheckInCustomer";
import CheckInAdditionalInformation from "./CheckInAdditionalInformation";
import CheckInDevice from "./CheckInDevice";
import CheckInWorkflow from "./CheckInWorkflow";
import CheckInIssueDescription from "./CheckInIssueDescription";
import CheckInSaveOrder from "./CheckInSaveOrder";
import { postData } from "../../Services/postData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { useNavigate } from "react-router-dom";
import {
  CustomerModel,
  DeviceModel,
  NotesModel,
  OrderModel,
  Step as StepModel,
  WorkflowModel,
} from "../../Utils/Models";
import { toast } from "react-toastify";
import { checkInSteps } from "../../Utils/AutocompleteUtils";

type CheckInMainPageProps = {
  onClose: () => void;
};

export type CheckInOrderProps = {
  customer: CustomerModel | null;
  additionalInformation: Array<StepModel>;
  device: DeviceModel | null;
  workflow: WorkflowModel | null;
  issueDescription: NotesModel | null;
};

export default function CheckInMainPage(props: CheckInMainPageProps) {
  const { onClose } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [checkInOrder, setCheckInOrder] = useState<CheckInOrderProps>({
    customer: null,
    additionalInformation: [],
    device: null,
    workflow: null,
    issueDescription: null,
  });
  /**
   *
   * @param step
   * @returns
   */
  function isStepSkipped(step: number) {
    return skipped.has(step);
  }

  /**
   *
   */
  function handleNext() {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    switch (activeStep) {
      case 0:
        if (!checkInOrder.customer) {
          toast.error(Vocabulary.customerIsRequired);
          return;
        }
        break;
      case 1:
        break;
      case 2:
        if (!checkInOrder.device) {
          toast.error(Vocabulary.deviceIsRequired);
          return;
        }
        break;
      case 3:
        if (!checkInOrder.workflow) {
          toast.error(Vocabulary.workflowIsRequired);
          return;
        }
        break;
      case 4:
        if (!checkInOrder.issueDescription) {
          toast.error(Vocabulary.issueDescriptionIsRequired);
          return;
        }
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  /**
   *
   */
  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  /**
   *
   */
  function handleSkip() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    switch (activeStep) {
      case 0:
        setCheckInOrder({ ...checkInOrder, customer: null });
        break;
      case 1:
        setCheckInOrder({ ...checkInOrder, additionalInformation: [] });
        break;
      case 2:
        setCheckInOrder({ ...checkInOrder, device: null });
        break;
      case 3:
        setCheckInOrder({ ...checkInOrder, workflow: null });
        break;
      case 4:
        setCheckInOrder({ ...checkInOrder, issueDescription: null });
        break;
      default:
        break;
    }

    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }

  /**
   *
   * @param newOrder
   */
  function handleChangeCheckInOrder(newOrder: CheckInOrderProps) {
    setCheckInOrder(newOrder);
  }

  /**
   *
   */
  function saveOrder() {
    const newOrder: any = new OrderModel();
    newOrder.customer = checkInOrder.customer;
    newOrder.workflowSteps = checkInOrder.additionalInformation.map(
      (step: any) => {
        return {
          stepId: step.stepId,
          description: step.description,
          images: step.images,
          timeTracking: step.timeTracking,
          date: step.date,
          customStepValue: step.customStepValue.text
            ? step.customStepValue.text
            : step.customStepValue.value
            ? step.customStepValue.value
            : step.customStepValue,
          createdBy: step.createdBy,
        };
      }
    );
    newOrder.device = checkInOrder.device;
    newOrder.workflow = checkInOrder.workflow;
    newOrder.notes = checkInOrder.issueDescription
      ? [checkInOrder.issueDescription]
      : [];
    newOrder.total = newOrder.lines
      .reduce((accumulator: any, line: any) => {
        return +accumulator + +line.total;
      }, 0)
      .toFixed(2);
    newOrder.total0 = newOrder.lines
      .reduce((accumulator: any, line: any) => {
        return +accumulator + +line.total0;
      }, 0)
      .toFixed(2);
    const userId = localStorage.getItem("userId");
    newOrder.handledBy = userId ? userId : null;
    newOrder.orderedBy = checkInOrder.customer?._id || null;
    newOrder.totalRemaining = newOrder.total;
    postData(urlEnum.orders, newOrder)
      .then((res: any) => {
        if (res) {
          onClose();
          navigate(`${localUrlEnum.orders}/${res.data.result._id}`);
        }
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @returns
   */
  function showInfoForEachStep() {
    switch (activeStep) {
      case 0:
        return (
          <CheckInCustomer
            checkInOrder={checkInOrder}
            handleChangeCheckInOrder={handleChangeCheckInOrder}
          />
        );
      case 1:
        return (
          <CheckInAdditionalInformation
            checkInOrder={checkInOrder}
            handleChangeCheckInOrder={handleChangeCheckInOrder}
          />
        );
      case 2:
        return (
          <CheckInDevice
            checkInOrder={checkInOrder}
            handleChangeCheckInOrder={handleChangeCheckInOrder}
          />
        );
      case 3:
        return (
          <CheckInWorkflow
            checkInOrder={checkInOrder}
            handleChangeCheckInOrder={handleChangeCheckInOrder}
          />
        );
      case 4:
        return (
          <CheckInIssueDescription
            checkInOrder={checkInOrder}
            handleChangeCheckInOrder={handleChangeCheckInOrder}
          />
        );
      case 5:
        return <CheckInSaveOrder />;
      default:
        return (
          <CheckInCustomer
            checkInOrder={checkInOrder}
            handleChangeCheckInOrder={handleChangeCheckInOrder}
          />
        );
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {checkInSteps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                sx={{
                  display: "block",
                  "& .MuiStepLabel-iconContainer": {
                    marginBottom: 0.6,
                  },
                  "& .MuiStepLabel-label": {
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div
        style={{
          width: "100%",
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {showInfoForEachStep()}
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "auto" }}>
          <Button
            color="inherit"
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            {Vocabulary.back}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === checkInSteps.length ? null : (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSkip}
              style={{ color: "white" }}
              sx={{ mr: 1 }}
            >
              {Vocabulary.skip}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={
              activeStep === checkInSteps.length ? saveOrder : handleNext
            }
          >
            {activeStep === checkInSteps.length
              ? Vocabulary.create
              : Vocabulary.next}
          </Button>
        </Box>
      </div>
    </Box>
  );
}
