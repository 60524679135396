/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { handleFileUpload } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddOrUpdateModel from "./AddOrUpdateModel";
import { Button, Divider } from "@mui/material";
import { ImportExcelDescription } from "../../Utils/Models";

type ImportExcelModalProps = {
  open: boolean;
  onClose: () => void;
  saveItems: (items: any) => void;
  description: Array<ImportExcelDescription>;
};
export default function ImportExcelModal(props: ImportExcelModalProps) {
  const { open, onClose, saveItems, description } = props;
  const [items, setItems] = useState<any>([]);

  return (
    <AddOrUpdateModel
      open={open}
      title={Vocabulary.importExcel}
      onClose={onClose}
      addOrUpdateItem={() => null}
      hideButtons={true}
    >
      <input
        type="file"
        onChange={(e: any) => {
          handleFileUpload(e, (newData) => {
            setItems(newData);
          });
        }}
      />
      <br />
      <p style={{ margin: "30px 0px 8px 0px", fontSize: 17, fontWeight: 300 }}>
        {Vocabulary.acceptedFields}
      </p>
      <div
        style={{
          display: "flex",
          fontSize: 15,
          backgroundColor: "#ccc",
          paddingLeft: 10,
        }}
      >
        <p style={{ flex: 1 }}>{Vocabulary.fieldName}</p>
        <p style={{ flex: 1 }}>{Vocabulary.description}</p>
        <p style={{ flex: 1 }}>{Vocabulary.example}</p>
      </div>
      <Divider />
      {description.map((item: any, key: number) => (
        <>
          <div
            style={{
              display: "flex",
              fontSize: 15,
              paddingLeft: 10,
            }}
          >
            <p style={{ flex: 1 }}>{item.name}</p>
            <p style={{ flex: 1, paddingRight: 10 }}>{item.description}</p>
            <p style={{ flex: 1, fontStyle: "italic" }}>{item.example}</p>
          </div>
          <Divider />
        </>
      ))}
      <p style={{ fontSize: 13, color: "#ff6969" }}>
        *{Vocabulary.observationImportFirstLine}
      </p>
      <br />
      <Button
        variant="contained"
        onClick={() => saveItems(items)}
        disabled={items.length === 0 ? true : false}
        style={{ marginTop: 20 }}
      >
        {Vocabulary.saveData}
      </Button>
    </AddOrUpdateModel>
  );
}
