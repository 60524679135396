import { Button } from "@mui/material";
import excel from "../../assets/microsoft-excel.svg";
import { Vocabulary } from "../../Utils/Vocabulary";

type ImportExcelButtonProps = {
  onClickImportButton: () => void;
};
export default function ImportExcelButton(props: ImportExcelButtonProps) {
  const { onClickImportButton } = props;
  return (
    <Button onClick={onClickImportButton}>
      <img src={excel} alt="Excel Icon" style={{ width: 25, marginRight: 5 }} />
      <p style={{ fontSize: "12px", color: "#008936" }}>
        {Vocabulary.importExcel}
      </p>
    </Button>
  );
}
