/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid } from "@mui/material";
import { TimeTracking } from "../../../Utils/Models";
import { Vocabulary } from "../../../Utils/Vocabulary";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import theme from "../../../Themes/Theme";
import StepInterval from "./StepInterval";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  calculateDurationForInterval,
  saveTimeTrackingToDatabase,
  showFormattedDuration,
} from "../../../Utils/Utils";
import { OrderContext } from "../../../Contexts/OrderContext";
import { HttpStatusCode } from "../../../Utils/Constants";

type OrderStepProps = {
  modifyStep: any;
};

export default function OrderStep(props: OrderStepProps) {
  const { modifyStep } = props;
  const orderContext = useContext(OrderContext);
  const [time, setTime] = useState<string>("00:00:00");
  const userId = useMemo(() => localStorage.getItem("userId"), []);

  const user = useMemo(() => {
    const localStorageCache = localStorage.getItem("cache");
    if (!localStorageCache) return null;
    const cache = JSON.parse(localStorageCache);
    if (cache) {
      return cache.users.find((user: any) => user._id === userId);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalWithoutEndTime = modifyStep.step.timeTracking.filter(
      (interval: TimeTracking) =>
        !interval.endTime && interval.userId?._id === userId
    );
    if (intervalWithoutEndTime.length > 0) {
      const intervalId = setInterval(
        () => calculateTime(intervalWithoutEndTime),
        1000
      );
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyStep.step.timeTracking]);

  /////////////////////////// TIME TRACKING ///////////////////////////
  /**
   *
   * @param intervalWithoutEndTime
   */
  function calculateTime(intervalWithoutEndTime: any) {
    const interval = {
      startTime: intervalWithoutEndTime[0].startTime,
      endTime: moment().unix(),
    };
    const duration = calculateDurationForInterval(interval);
    const formattedDuration = showFormattedDuration(duration);
    setTime(formattedDuration);
  }

  /**
   *
   */
  async function saveTimeTracking(newTimeTracking: any) {
    const response = await saveTimeTrackingToDatabase(
      orderContext.order._id || "",
      modifyStep.index,
      newTimeTracking
    );
    if (
      response &&
      (response.status === HttpStatusCode.OK ||
        response.status === HttpStatusCode.Created)
    ) {
      modifyStep.step.timeTracking = newTimeTracking;
      orderContext.setOrder({
        ...orderContext.order,
        workflowSteps: response.result,
      });
    }
  }
  /**
   * Add new interval
   */
  async function addNewInterval(newInterval: TimeTracking) {
    const newTimeTracking = [...modifyStep.step.timeTracking];
    newTimeTracking.push(newInterval);
    await saveTimeTracking(newTimeTracking);
  }

  /**
   *
   * @param newTimeTracking
   */
  async function changeInterval(newTimeTracking: TimeTracking[]) {
    setTime("00:00:00");
    await saveTimeTracking(newTimeTracking);
  }

  /**
   *
   * @param endTime
   * @returns
   */
  async function stopInterval(endTime: number) {
    const newTimeTracking = [...modifyStep.step.timeTracking];
    const indexIntervalWithoutEndTime = modifyStep.step.timeTracking.findIndex(
      (interval: TimeTracking) =>
        !interval.endTime && interval.userId?._id === userId
    );
    if (indexIntervalWithoutEndTime === -1) return;
    newTimeTracking[indexIntervalWithoutEndTime].endTime = endTime;
    await saveTimeTracking(newTimeTracking);
    setTime("00:00:00");
  }
  /////////////////////////// END TIME TRACKING ///////////////////////////

  return (
    <Grid container spacing={2}>
      {modifyStep.step.createdBy ? (
        <Grid item xs={12} md={12} lg={12}>
          <p
            style={{
              fontSize: 20,
              marginTop: 10,
              textAlign: "center",
            }}
          >
            {Vocabulary.pleaseFirstDeallocateTheUserForThisStep}
          </p>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={6} lg={6}>
            {modifyStep.step.timeTracking.filter(
              (interval: TimeTracking) =>
                !interval.endTime && interval.userId?._id === userId
            ).length > 0 ? (
              <Button
                variant="contained"
                size="medium"
                fullWidth
                style={{
                  fontSize: 19,
                  backgroundColor: theme.palette.errorColor?.main,
                }}
                onClick={() => stopInterval(moment().unix())}
              >
                <StopCircleIcon
                  fontSize="large"
                  style={{
                    marginRight: 10,
                  }}
                />
                {Vocabulary.stopTimer}
              </Button>
            ) : (
              <Button
                variant="contained"
                size="medium"
                fullWidth
                style={{
                  fontSize: 19,
                  backgroundColor: theme.palette.successColor?.main,
                }}
                onClick={() =>
                  addNewInterval({
                    startTime: moment().unix(),
                    endTime: null,
                    userId: user,
                    isManual: false,
                  })
                }
              >
                <PlayCircleFilledWhiteIcon
                  fontSize="large"
                  style={{ marginRight: 10 }}
                />
                {Vocabulary.startTimer}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <p
              style={{
                fontSize: 25,
                marginTop: 10,
                textAlign: "center",
              }}
            >
              {time}
            </p>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <StepInterval
              timeTracking={modifyStep.step.timeTracking}
              changeInterval={changeInterval}
              addNewInterval={addNewInterval}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
