/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect } from "react";
import { NotesModel } from "../../Utils/Models";
import { notesType, notesVisibility } from "../../Utils/AutocompleteUtils";
import { CheckInPagesProps } from "./CheckInCustomer";

export default function CheckInIssueDescription(props: CheckInPagesProps) {
  const { checkInOrder, handleChangeCheckInOrder } = props;
  const userId: any = localStorage.getItem("userId");

  useEffect(() => {
    if (!checkInOrder.issueDescription) {
      handleChangeCheckInOrder({
        ...checkInOrder,
        issueDescription: {
          ...new NotesModel(),
          type: notesType.issue,
          visibility: notesVisibility.visible,
          createdBy: userId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ margin: "10px 0px" }}>
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 400,
          marginBottom: 15,
          textAlign: "center",
        }}
      >
        {Vocabulary.issueDescription}
      </Typography>
      <TextField
        size="small"
        placeholder={Vocabulary.issueDescription}
        multiline
        rows={4}
        maxRows={10}
        fullWidth
        value={checkInOrder.issueDescription?.text}
        onChange={(event) => {
          const newIssueDescription = Object.assign(
            {},
            checkInOrder.issueDescription
          );
          newIssueDescription.text = event.target.value;
          handleChangeCheckInOrder({
            ...checkInOrder,
            issueDescription: newIssueDescription,
          });
        }}
      />
    </div>
  );
}
