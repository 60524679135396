/* eslint-disable @typescript-eslint/no-explicit-any */
import MenuIcon from "@mui/icons-material/Menu";
import { Fab, Typography, useMediaQuery } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useContext, useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import styles from "../Styles/mainHeader.module.css";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import StopIcon from "@mui/icons-material/Stop";
import {
  calculateDurationForInterval,
  logout,
  showFormattedDuration,
} from "../Utils/Utils";
import { OrderContext } from "../Contexts/OrderContext";
import { Info } from "@mui/icons-material";
import { getData } from "../Services/getData";
import { TimeTracking } from "../Utils/Models";
import moment from "moment";

export default function MainHeader() {
  const phoneStyle = useMediaQuery("(max-width:800px)");
  const orderContext = useContext(OrderContext);
  const userId = useMemo(() => localStorage.getItem("userId"), []);
  const userName = localStorage.getItem("userName");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [time, setTime] = useState<any>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [timeTracking, setTimeTracking] = useState<any>(null);
  const [updateUseEffect, setUpdateUseEffect] = useState<any>(null);
  const open = Boolean(anchorEl);

  /**
   *
   */
  useEffect(() => {
    getTimeTracking();
    if (timeTracking) {
      const intervalId = setInterval(
        () =>
          calculateTime(
            timeTracking.order,
            timeTracking.intervalWithoutEndTime
          ),
        1000
      );
      return () => clearInterval(intervalId);
    }
    setTime(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderContext.order, updateUseEffect]);

  /**
   *
   */
  async function getTimeTracking() {
    const response = await getData(
      `${urlEnum.orders}/getRunningTimeTrackingForOneUser/${userId}`
    );
    if (response && response.data.result) {
      for (const step of response.data.result.workflowSteps) {
        const intervalWithoutEndTime = step.timeTracking.filter(
          (interval: TimeTracking) =>
            !interval.endTime &&
            interval.userId &&
            interval.userId._id === userId
        );
        if (intervalWithoutEndTime.length > 0) {
          const timeTracking = {
            order: response.data.result,
            stepName: step.stepId ? step.stepId.name : "",
            intervalWithoutEndTime: intervalWithoutEndTime,
          };
          setTimeTracking(timeTracking);
          if (
            !updateUseEffect ||
            (updateUseEffect &&
              timeTracking.order.fixablyId !== updateUseEffect.order.fixablyId)
          )
            setUpdateUseEffect(timeTracking);
          return;
        }
      }
    }
    setUpdateUseEffect(null);
    setTimeTracking(null);
  }

  /**
   *
   * @param order
   * @param intervalWithoutEndTime
   */
  function calculateTime(order: any, intervalWithoutEndTime: any) {
    const interval = {
      startTime: intervalWithoutEndTime[0].startTime,
      endTime: moment().unix(),
    };
    const duration = calculateDurationForInterval(interval);
    const formattedDuration = showFormattedDuration(duration);
    setTime({
      orderText: `Order: ${order.fixablyId} `,
      orderId: order._id,
      stepName: `Step: ${timeTracking.stepName}`,
      time: `${formattedDuration}`,
    });
  }
  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   */
  const handleLogout = async () => {
    setAnchorEl(null);
    // const response: any = await getData(urlEnum.logout);
    // if (response && !response.errors) {
    logout();
    navigate(localUrlEnum.login);
    //}
  };

  /**
   *
   */
  const redirectToMyAccount = () => {
    navigate(localUrlEnum.myAccount);
    setAnchorEl(null);
  };

  /**
   *
   */
  const redirectToSettings = () => {
    navigate(localUrlEnum.settings);
    setAnchorEl(null);
  };
  /**
   * Start loading
   */
  function largeMenu() {
    const container = document.getElementById("container");
    container?.classList.remove("containerLarge");
    container?.classList.add("containerSmall");

    const mainSlider = document.getElementById("mainSlider");
    mainSlider?.classList.remove("mainSliderLarge");
    mainSlider?.classList.add("mainSliderSmall");

    document.documentElement.style.setProperty("--isSmall", "1");
  }

  /**
   * Stop loading
   */
  function smallMenu() {
    const container = document.getElementById("container");
    container?.classList.remove("containerSmall");
    container?.classList.add("containerLarge");

    const mainSlider = document.getElementById("mainSlider");
    mainSlider?.classList.remove("mainSliderSmall");
    mainSlider?.classList.add("mainSliderLarge");

    document.documentElement.style.setProperty("--isSmall", "0");
  }

  /**
   *
   */
  function openOrCloseMenu() {
    if (!openMenu) {
      largeMenu();
    } else {
      smallMenu();
    }
    setOpenMenu(!openMenu);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.secondContainer}>
          {phoneStyle ? null : (
            <div>
              <IconButton
                style={{ backgroundColor: "#fff" }}
                aria-label="add"
                onClick={openOrCloseMenu}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <div style={{ marginLeft: 10 }}>
            <a href="/">
              <img src={"/upvolt_logo.png"} alt="UPVOLT" width={"50%"} />
            </a>
          </div>
        </div>
        <div className={styles.secondContainer}>
          {time && !phoneStyle ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 10,
                backgroundColor: "#fff3",
                marginTop: -10,
                marginBottom: -10,
                padding: "0px 10px",
              }}
            >
              <StopIcon
                style={{
                  color: "rgb(200 0 12)",
                  boxShadow: "0px 0px 5px #7482b9",
                  borderRadius: 5,
                  marginRight: 12,
                  cursor: "pointer",
                  fontSize: 32,
                }}
              />
              <p
                style={{
                  color: "#0000005e",
                  padding: 0,
                  margin: 2,
                  marginRight: 10,
                }}
              >
                {time.time}
              </p>
              <p
                style={{
                  color: "#0000005e",
                  padding: 0,
                  margin: 2,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`${localUrlEnum.orders}/${time.orderId}`);
                }}
              >
                {time.orderText}
              </p>
              <p
                style={{
                  color: "#0000005e",
                  padding: 0,
                  margin: 2,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`${localUrlEnum.orders}/${time.orderId}`);
                }}
              >
                {time.stepName}
              </p>
            </div>
          ) : null}
          <Typography className={styles.username}>{userName}</Typography>
          <Fab
            onClick={handleClick}
            size="small"
            className={styles.fab}
            aria-label="add"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AccountCircleIcon />
          </Fab>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={redirectToMyAccount}>
              <Avatar /> {Vocabulary.myAccount}
            </MenuItem>
            <Divider />
            <MenuItem onClick={redirectToSettings}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              {Vocabulary.settings}
            </MenuItem>
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <Info fontSize="small" />
              </ListItemIcon>
              <a
                style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.80" }}
                href="/intro.html"
                target="_blank"
              >
                {Vocabulary.userManual}
              </a>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {Vocabulary.logout}
            </MenuItem>
          </Menu>
        </div>
      </div>
      {time && phoneStyle ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff3",
            margin: "5px -10px -10px -10px",
            padding: "7px 10px",
          }}
        >
          <StopIcon
            style={{
              color: "rgb(200 0 12)",
              boxShadow: "0px 0px 5px #7482b9",
              borderRadius: 5,
              marginRight: 12,
              cursor: "pointer",
              fontSize: 32,
            }}
          />
          <p
            style={{
              color: "#0000005e",
              padding: 0,
              margin: 2,
              marginRight: 10,
            }}
          >
            {time.time}
          </p>
          <p
            style={{
              color: "#0000005e",
              padding: 0,
              margin: 2,
              marginRight: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`${localUrlEnum.orders}/${time.orderId}`);
            }}
          >
            {time.orderText}
          </p>
          <p
            style={{
              color: "#0000005e",
              padding: 0,
              margin: 2,
              marginRight: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`${localUrlEnum.orders}/${time.orderId}`);
            }}
          >
            {time.stepName}
          </p>
        </div>
      ) : null}
    </>
  );
}
