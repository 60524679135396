/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Chip } from "@mui/material";
import styles from "../../../../Styles/dashboard.module.css";
import { useState } from "react";
import { StockTransferModel } from "../../../../Utils/Models";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { updateData } from "../../../../Services/updateData";
import { urlEnum } from "../../../../Utils/UrlEnum";
import { postData } from "../../../../Services/postData";
import MainTable from "../../../GenericComponents/MainTable";
import { styleForTableWithTabs } from "../../../../Pages/Users";
import StockTransfer from "./StockTransfer";
import moment from "moment";
import { euFormatForDateTime } from "../../../../Utils/Config";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import ChangeStatusForStockTransfer from "./ChangeStatusForStockTransfer";

export const RenderAddComponent = {
  outtake: "outtake",
  transfer: "transfer",
};
export default function StocksTransfer() {
  const [open, setOpen] = useState({
    value: false,
    component: RenderAddComponent.transfer,
  });
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editItem, setEditItem] = useState<StockTransferModel | null>(null);
  const [openChangeStatus, setOpenChangeStatus] = useState<any>(null);

  const stockTransfersHeader = [
    {
      label: `${Vocabulary.from} / ${Vocabulary.location}`,
      name: "from",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value.name} <br /> {value.location.name}
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.to} / ${Vocabulary.location}`,
      name: "to",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value ? (
                <>
                  {value.name} <br /> {value.location.name}
                </>
              ) : null}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.products,
      name: "products",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value.map((product: any, key: number) => (
                <span key={`${product._id}_${product.name}`}>
                  {key < 2 ? (
                    <Chip
                      label={`${product.product.code} - ${
                        product.product.name.length > 7
                          ? `${product.product.name.substring(0, 7)}...`
                          : product.product.name
                      }`}
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : key === 2 ? (
                    <Chip
                      key={value.id}
                      label="..."
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : null}
                </span>
              ))}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Chip
              label={value}
              style={{
                margin: 5,
                backgroundColor: "#404a694d",
              }}
            />
          );
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: StockTransferModel | null, showItem?: any) {
    setShouldUpdate(false);
    setOpen({
      value: !open.value,
      component: showItem ? showItem : RenderAddComponent.transfer,
    });
    setEditItem(item);
  }
  /**
   *
   * @param stockTransfer
   */
  function addOrUpdateStockTransfer(stockTransfer: StockTransferModel | null) {
    setShouldUpdate(true);
    if (stockTransfer?._id) {
      updateData(
        `${urlEnum.stockTransfers}/${stockTransfer._id}`,
        stockTransfer
      )
        .then((res) => {
          if (res) {
            setOpen({
              value: false,
              component: RenderAddComponent.transfer,
            });
          }
        })
        .catch((err) => {
          setOpen({
            value: false,
            component: RenderAddComponent.transfer,
          });
        });
    } else {
      postData(urlEnum.stockTransfers, stockTransfer)
        .then((res: any) => {
          if (res) {
            setOpen({
              value: false,
              component: RenderAddComponent.transfer,
            });
          }
        })
        .catch((err) => {
          setOpen({
            value: false,
            component: RenderAddComponent.transfer,
          });
        });
    }
  }

  /**
   *
   * @param status
   * @returns
   */
  function changeStatusForStockTransfer(status: string | null) {
    if (!status) {
      toast.error(Vocabulary.selectStatus);
      return;
    }
    if (editItem) {
      updateData(`${urlEnum.stockTransfers}/changeStatus/${editItem._id}`, {
        status: status,
      }).then((res) => {
        if (res) {
          setOpenChangeStatus(false);
          setEditItem(null);
          setShouldUpdate(true);
        }
      });
    }
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.stockTransfers}
        titleDelete={Vocabulary.deleteStockTransfer}
        textDelete={Vocabulary.deleteConfirmationStockTransfer}
        header={stockTransfersHeader}
        tableTitle={Vocabulary.stockTransfersList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
        showSearch={false}
        anotherButtonsOptions={{
          icon: <RepeatIcon />,
          title: Vocabulary.changeStockTransferStatus,
          action: (item: any) => {
            setShouldUpdate(false);
            setOpenChangeStatus(open);
            setEditItem(item);
          },
        }}
        headerButtons={
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              handleEdit(null, RenderAddComponent.outtake);
            }}
            className={styles.addButtonTableHeader}
            style={{ marginLeft: 15 }}
          >
            {Vocabulary.addOuttake}
          </Button>
        }
      />
      {open.value ? (
        <StockTransfer
          editStockTransfer={editItem}
          open={open.value}
          component={open.component}
          addOrUpdateStockTransfer={(stockTransfer) =>
            addOrUpdateStockTransfer(stockTransfer)
          }
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {openChangeStatus ? (
        <ChangeStatusForStockTransfer
          openChangeStatus={openChangeStatus}
          onClose={() => {
            setOpenChangeStatus(false);
            setEditItem(null);
          }}
          changeStatusForStockTransfer={changeStatusForStockTransfer}
        />
      ) : null}
    </div>
  );
}
