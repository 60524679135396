/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../../../Styles/dashboard.module.css";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { urlEnum } from "../../../Utils/UrlEnum";
import MainTable from "../../GenericComponents/MainTable";
import { useContext, useState } from "react";
import { AttributeModel, ImportExcelDescription } from "../../../Utils/Models";
import { updateData } from "../../../Services/updateData";
import { postData } from "../../../Services/postData";
import { Button, Chip } from "@mui/material";
import Attribute from "./Attribute";
import { WebsocketContext } from "../../../Contexts/WebsocketContext";
import { styleForTableWithTabs } from "../../../Pages/Users";
import ImportExcelModal from "../../GenericComponents/ImportExcelModal";
import ImportExcelButton from "../../GenericComponents/ImportExcelButton";

export default function Attributes() {
  const websocketContext = useContext(WebsocketContext);
  const [open, setOpen] = useState(false);
  const [openImportExcel, setOpenImportExcel] = useState(false);
  const [editItem, setEditItem] = useState<AttributeModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  /**
   *
   */
  const description: Array<ImportExcelDescription> = [
    {
      name: "Name",
      description: Vocabulary.itemName,
      example: Vocabulary.color,
    },
    {
      name: "Values",
      description: Vocabulary.itemValues,
      example: Vocabulary.redBlueGreen,
    },
  ];

  /**
   *
   */
  const attributeHeader = [
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.values,
      name: "values",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value.map((item: any, key: number) => (
                <span key={`${key}_${item}`}>
                  {key < 6 ? (
                    <Chip
                      label={item}
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : key === 6 ? (
                    <Chip
                      label="..."
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : null}
                </span>
              ))}
            </>
          );
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: AttributeModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param attribute
   */
  function addOrUpdateAttribute(attribute: AttributeModel | null) {
    if (attribute && attribute.name === "") {
      return;
    }
    if (
      attribute &&
      attribute.values.length === 1 &&
      attribute.values[0] === ""
    ) {
      return;
    }
    if (attribute)
      attribute.values = attribute?.values.filter((item) => item !== "");
    setShouldUpdate(true);
    if (attribute?._id) {
      updateData(`${urlEnum.attributes}/${attribute._id}`, attribute)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.attributes, attribute)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  /**
   *
   * @param items
   */
  async function saveToDatabase(items: any) {
    const res = await postData(`${urlEnum.attributes}/insertMany`, items);
    if (res && res.data && res.data.result) {
      setOpenImportExcel(false);
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      setShouldUpdate(true);
    }
  }
  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.attributes}
        titleDelete={Vocabulary.deleteAttribute}
        textDelete={Vocabulary.deleteConfirmationAttribute}
        header={attributeHeader}
        tableTitle={Vocabulary.attributesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
        headerButtons={
          <ImportExcelButton
            onClickImportButton={() => {
              setOpenImportExcel(true);
              setShouldUpdate(false);
            }}
          />
        }
      />
      {open ? (
        <Attribute
          editAttribute={editItem}
          open={open}
          addOrUpdateAttribute={(attribute: AttributeModel | null) =>
            addOrUpdateAttribute(attribute)
          }
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {openImportExcel ? (
        <ImportExcelModal
          open={openImportExcel}
          onClose={() => setOpenImportExcel(false)}
          saveItems={(items: any) => saveToDatabase(items)}
          description={description}
        />
      ) : null}
    </div>
  );
}
