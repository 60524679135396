/* eslint-disable @typescript-eslint/no-explicit-any */
import MainTable from "../../Components/GenericComponents/MainTable";
import styles from "../../Styles/dashboard.module.css";
import { urlEnum } from "../../Utils/UrlEnum";
import { styleForTableWithTabs } from "../Users";

type OtherReportProps = {
  header: any[];
};
export function OtherReport(props: OtherReportProps) {
  const { header } = props;
  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.otherReports}
        titleDelete={""}
        textDelete={""}
        header={header}
        tableTitle={""}
        style={styleForTableWithTabs}
        hideOptionButtons={true}
        showSearch={false}
        hideAddButton={true}
      />
    </div>
  );
}
